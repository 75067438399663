/**
 * @module viewtype
 * @category widgets
 * @subcategory toolbox
 * @description Represents viewtype component with next features:
 * 1. Allow getting current view type
 * 2. Allow handling Window change event and view type change event
 * 3. Allow checking if touch device
 * 4. Allow checking view type (e.g isSmallView, isMediumView, isLargeView etc.)
 *
 * @example <caption>Example of viewtype module usage</caption>
 * import { isDesktopView } from 'widgets/toolbox/viewtype';
 *
 *  checkAriaAttributes() {
 *      this.panelItems
 *          .map(menuItem => menuItem.ref('itemLink'))
 *          .filter(itemLink => itemLink.data('isCustomMenuHtml'))
 *          .forEach(itemLink => {
 *              itemLink.attr(ARIA_EXPANDED, isDesktopView());
 *              itemLink.attr('aria-haspopup', isDesktopView());
 *          });
 *  }
 */

import eventBus from './eventBus';
import { debounce } from './debounce';
import viewTypeConfig from 'widgets/viewTypeConfig';
import breakpoints from 'config/breakpoints';
import { setCookie } from './cookie';

const smallID = 'small';
const mediumID = 'medium';
const largeID = 'large';
const extraLargeID = 'extraLarge';

export type TViewType = typeof smallID|typeof mediumID| typeof largeID|typeof extraLargeID;

export type TQueryMap = Array<[v: TViewType, q: string]>;

const breakMd = breakpoints.md;
const breakLg = breakpoints.lg;
const breakXl = breakpoints.xl;

const smallView = `screen and (max-width: ${breakMd - 1}px)`;
const mediumView = `screen and (min-width: ${breakMd}px) and (max-width: ${breakLg - 1}px)`;
const largeView = `screen and (min-width: ${breakLg}px) and (max-width: ${breakXl - 1}px)`;
const exLargeView = `screen and (min-width: ${breakXl - 1}px)`;
let queriesMap: TQueryMap = [
    [smallID, smallView],
    [mediumID, mediumView],
    [largeID, largeView],
    [extraLargeID, exLargeView]
];

let currentWindowWidth = window.innerWidth;
let currentWindowHeight = window.innerHeight;

/**
 * @description Get current view type
 * @returns Current view type
 */
function getCurrentViewType(): TViewType {
    const matchQuery: [v: TViewType, q: string] | undefined = queriesMap.find(([, query]) => window.matchMedia(query).matches);

    return (matchQuery && matchQuery[0]) || smallID;
}

/**
 * @description Set cookie "screenSize" that used on backend for detecting device type
 */
function setScreenSizeToCookie() {
    setCookie('screenSize', `${window.innerWidth}x${window.innerHeight}`);
}

/**
 * @description WindowChange event handler
 * @emits "viewtype.windowChange"
 */
const onWindowChange = (): void => {
    if (currentWindowWidth !== window.innerWidth || currentWindowHeight !== window.innerHeight) {
        currentWindowWidth = window.innerWidth;
        currentWindowHeight = window.innerHeight;

        setScreenSizeToCookie();

        /**
         * @description Event to window change
         * @event "viewtype.windowChange"
         */
        eventBus.emit('viewtype.windowChange', {
            currentWindowWidth: currentWindowWidth,
            currentWindowHeight: currentWindowHeight
        });
    }
};

/**
 * @type {viewtype} Current view type
 */
let currentViewType: TViewType = smallID;

/**
 * @description Small view type ID
 */
export const SMALL = smallID;

/**
 * @description Medium view type ID
 */
export const MEDIUM = mediumID;

/**
 * @description Large view type ID
 */
export const LARGE = largeID;

/**
 * @description Extra large view type ID
 */
export const EXTRA_LARGE = extraLargeID;

/**
 * @description Get current view type
 * @returns Current view type
 */
export const getViewType = (): string => currentViewType;

/**
 * @description Check if touch device
 * @returns If touch device
 */
export const isTouchDevice = (): boolean => ('ontouchstart' in window)

    || !!(window.DocumentTouch && document instanceof window.DocumentTouch);

/**
 * @description Check if the current view type is equal to the provided one
 * @param type name of the type to check
 * @returns Return true if a provided type is equal to the current view type
 */
function isViewTypeMatch(type: typeof currentViewType): boolean {
    return getViewType() === type;
}

/**
 * @description Check if view type is small
 * @returns result
 */
export const isSmallView = (): boolean => isViewTypeMatch(smallID);

/**
 * @description Check if view type is medium
 * @returns result
 */
export const isMediumView = (): boolean => isViewTypeMatch(mediumID);

/**
 * @description Check if view type is medium and up
 * @returns result
 */
export const isMediumViewAndUp = (): boolean => !isSmallView();

/**
 * @description Check if view type is medium and down
 * @returns result
 */
export const isMediumViewAndDown = (): boolean => isSmallView() || isMediumView();

/**
 * @description Check if view type is extra large
 * @returns result
 */
export const isExtraLargeView = (): boolean => isViewTypeMatch(extraLargeID);

/**
 * @description Check if view type is large
 * @returns result
 */
export const isLargeView = (): boolean => isViewTypeMatch(largeID);

/**
 * @description Check if view type is large and up
 * @returns result
 */
export const isLargeViewAndUp = (): boolean => isLargeView() || isExtraLargeView();

/**
 * @description Check if view type is large and down
 * @returns result
 */
export const isLargeViewAndDown = (): boolean => !isExtraLargeView();

/**
 * @description Check if view type is desktop
 */
export const isDesktopView = isLargeViewAndUp;

const modifiersMapping = {
    sm: isSmallView,
    md: isMediumView,
    lg: isLargeView,
    xl: isExtraLargeView
};

export type TShortViewtypes = keyof typeof modifiersMapping;

/**
 * @description Define all view types
 * @type {}
 */
export const ALL_VIEW_TYPES: Array<TShortViewtypes> = ['sm', 'md', 'lg', 'xl'];

/**
 * @description Get active view type name
 * @returns Active view type name
 */
export const getActiveViewtypeName = (): string => ALL_VIEW_TYPES.find(m => modifiersMapping[m]()) || 'xl';

/**
 * @description Match viewport by device name
 * @emits "viewtype.change"
 * @param newDevice device type
 */
function matchViewport(newDevice: typeof smallID | typeof mediumID | typeof largeID | typeof extraLargeID) {
    const previousDevice = currentViewType;

    currentViewType = newDevice;

    if (previousDevice !== currentViewType) {
        /**
         * @description Event to change view type
         * @event "viewtype.change"
         */
        eventBus.emit('viewtype.change', currentViewType);
    }
}

/**
 * @description To init view types, you shouldn't use methods of modules before this executing
 * @emits "viewtype.change"
 * @param [config] object
 * @param config.useWindowListeners use listeners or MediaQueryListener
 * @param config.queriesMap size of mobile in pixels
 */
export function init(config: { useWindowListeners: boolean; queriesMap?: TQueryMap } = { useWindowListeners: false }) {
    const currentConfig = { ...config, ...viewTypeConfig };

    if (currentConfig.queriesMap) {
        queriesMap = currentConfig.queriesMap;
    }

    if (currentConfig.useWindowListeners) {
        const windowResize = debounce(() => {
            onWindowChange();
            matchViewport(getCurrentViewType());
        }, 50);

        window.addEventListener('resize', windowResize, { passive: true });
        window.addEventListener('orientationchange', windowResize, { passive: true });
    } else {
        const applyCurrentDeviceType = debounce(() => matchViewport(getCurrentViewType()), 50);

        queriesMap.forEach(([, query]) => window.matchMedia(query).addListener(applyCurrentDeviceType));
    }

    setScreenSizeToCookie();

    currentViewType = getCurrentViewType();
    /**
     * @description Event to change view type
     * @event "viewtype.change"
     */
    eventBus.emit('viewtype.change', currentViewType);
}
