import { TWidget } from 'widgets/Widget';

/**
 * @param Widget Base widget for extending
 * @returns CtaExt class
 */
function CtaExtClassCreator(Widget: TWidget) {
    /**
     * @category widgets
     * @subcategory plugin_page_designer_ext
     * @class CtaExt
     * @augments Widget
     * @classdesc Represents CtaExt component with next features:
     * 1. Add GTM event when CtaExt clicked
     * 2. Transfer styles from CtaExt to wrapper, when CTA is inside the BannerExt or TextBlock components
     *
     * <div class="b-button-ext ${pdict.mods}">
     *     <a href="${pdict.componentUrl}" data-widget="ctaExt" data-event-click="onCtaExtClick" class="b-button" style="${pdict.buttonWidth}">
     *         <span class="b-button-text">${pdict.componentLabel}</span>
     *     </a>
     * </div>
     */

    class CtaExt extends Widget {
        init() {
            super.init();

            this.transferAttributes();
        }

        /**
         * @description Executed when widget is re-rendered
         */
        onRefresh() {
            super.onRefresh();
            this.transferAttributes();
        }

        /**
         * @description Emit eventBus for send data to data layer
         * @param elem - Target element
         */
        onCtaExtClick(elem) {
            this.eventBus().emit('ctaExt.click', elem);
        }

        /**
         * @description Transfer attributes from inner CTAs to outer wrapper
         */
        transferAttributes() {
            const ctaWrapper = this.ref('self').get()?.closest('[data-ref="ctaExtWrapper"]');

            if (ctaWrapper) {
                ctaWrapper.setAttribute('style', this.ref('self').attr('style'));
            }
        }
    }

    return CtaExt;
}

export type TCtaExt = ReturnType<typeof CtaExtClassCreator>;

export type TCtaExtInstance = InstanceType<TCtaExt>;

export default CtaExtClassCreator;
