import { TWidget } from 'widgets/Widget';
import { getContentByUrl } from 'widgets/toolbox/ajax';

/**
 * @param Widget Base widget for extending
 */
function ContentLoaderClassCreator(Widget: TWidget) {
    /**
     * @category widgets
     * @subcategory global
     * @class ContentLoader
     * @augments Widget
     * @classdesc ContentLoader Widget - used to dynamically load content using ajax
     * @property {string} data-widget - Widget name `contentLoader`
     * @property {string} data-url - URL to obtain content from server
     * @example <caption>Example of ContentLoader widget usage</caption>
     * <div
     *     data-widget="contentLoader"
     *     data-url="${URLUtils.url('Page-IncludeHeaderMenu')}"
     * >
     * </div>
     */
    class ContentLoader extends Widget {
        prefs() {
            return {
                url: '',
                ...super.prefs()
            };
        }

        init() {
            getContentByUrl(this.prefs().url)
                .then(response => {
                    const container = this.ref('self').get(0);

                    if (container) {
                        container.innerHTML = response;
                    }
                });
        }
    }

    return ContentLoader;
}

export type TContentLoader = ReturnType<typeof ContentLoaderClassCreator>;

export type TContentLoaderInstance = InstanceType<TContentLoader>;

export default ContentLoaderClassCreator;
