const DATA_FIELD = '[data-ref="field"]';

/**
 * @description Base InputRadio implementation
 * @param BasicInput Base widget for extending
 * @returns Input Radio class
 */
function InputRadioClassCreator(BasicInput: import('widgets/forms/BasicInput').TBasicInput) {
    /**
     * @category widgets
     * @subcategory forms
     * @class InputRadio
     * @augments BasicInput
     * @classdesc Radio input implementation. Represents input `radio` element together with widget-related HTML markup.
     * @property {string} data-widget - Widget name `inputRadio`
     * @example <caption>InputRadio HTML markup example</caption>
     * <fieldset class="b-form_set">
     *     <legend class="b-form_set-label">Shipping method</legend>
     *     <div class="b-form_field" data-widget="inputRadio" data-id="shippingMethodList"
     *         data-select-shipping-method-url="https://..."
     *         data-action-url="https://..."
     *         data-widget-event-change="selectShippingMethod" data-shipment-uuid="3fbc2a6f7e32e2b1755e9099bd"
     *     >
     *         <div class="b-option_switch">
     *             <div class="b-option_switch-inner">
     *                 <input id="shippingMethod-001" class="b-option_switch-input"
     *                     name="dwfrm_shipping_shippingAddress_shippingMethodID
     *                     type="radio"
     *                     value="001"
     *                     data-value="001"
     *                     data-ref="field"
     *                     data-event-change="update"
     *                     checked=""
     *                 >
     *                 <div class="b-option_switch-icon"></div>
     *                 <label class="b-option_switch-label" for="shippingMethod-001">
     *                     ... label content
     *                 </label>
     *             </div>
     *         </div>
     *         <div class="b-option_switch">
     *             <div class="b-option_switch-inner">
     *                 <input
     *                     id="shippingMethod-002"
     *                     class="b-option_switch-input"
     *                     name="dwfrm_shipping_shippingAddress_shippingMethodID"
     *                     type="radio"
     *                     value="001"
     *                     data-value="001"
     *                     data-ref="field"
     *                     data-event-change="update"
     *                     checked=""
     *                 >
     *                 <div class="b-option_switch-icon"></div>
     *                 <label class="b-option_switch-label" for="shippingMethod-001">
     *                     ... label content
     *                 </label>
     *             </div>
     *         </div>
     *         <div role="alert" class="b-message m-error" data-ref="errorFeedback" hidden=""></div>
     *     </div>
     * </fieldset>
     */
    class InputRadio extends BasicInput {
        /**
         * @description method to get `value` of the radio
         * @returns value of selected radio input
         */
        getValue(): string {
            const el = (this.ref('self').get());

            if (el) {
                return Array.from(el.querySelectorAll(DATA_FIELD))
                    .reduce((val, elField) => {
                        const tmpEl = <HTMLInputElement>elField;

                        return val || ((tmpEl.checked || '') && tmpEl.value);
                    }, '');
            }

            return '';
        }

        /**
         * @description method to get `data-value` value of the radio
         * @returns `data-value` value
         */
        getDataValue(): string {
            const el = (this.ref('self').get());

            if (el) {
                return Array.from(el.querySelectorAll(DATA_FIELD))
                    .reduce((val, elInner) => {
                        const tmpEl = <HTMLInputElement>elInner;

                        return val || ((tmpEl.checked || '') && (tmpEl.getAttribute('data-value') || ''));
                    }, '');
            }

            return '';
        }

        /**
         * @description Method to select radio input
         * @param value - value to be set to radio button
         */
        setValue(value: string) {
            const el = (this.ref('self').get());

            if (el) {
                Array.from(el.querySelectorAll(DATA_FIELD))
                    .forEach((elField) => {
                        const tmpEl = <HTMLInputElement>elField;

                        if (tmpEl.value === value) {
                            tmpEl.checked = true;
                            tmpEl.setAttribute('checked', 'checked');
                        } else {
                            tmpEl.checked = false;
                            tmpEl.removeAttribute('checked');
                        }
                    });
            }
        }

        /**
         * @description Method to get dataset of checked or first radio input
         * @returns dataset object of first or checked radio input, or null if no 'self' exist
         */
        getDataSet(): null|object {
            const checked = this.getChecked();

            return checked ? checked.dataset : checked;
        }

        /**
         * @description Method to get checked (or first) radio input
         * @returns first or checked inputs ref element if self exists, null if not
         */
        getChecked(): null | HTMLElement {
            const el = this.ref('self').get();

            if (el) {
                const currentEl = (Array.from(el.querySelectorAll(DATA_FIELD))
                    .filter(field => {
                        const tmpField = <HTMLInputElement>field;

                        return tmpField.checked;
                    })[0] || el.querySelectorAll(DATA_FIELD)[0]) as HTMLElement;

                return currentEl;
            }

            return null;
        }
    }

    return InputRadio;
}

export type TInputRadio = ReturnType<typeof InputRadioClassCreator>;

export type TInputRadioInstance = InstanceType<TInputRadio>;

export default InputRadioClassCreator;
