type TModalInstance = InstanceType<ReturnType <typeof import('widgets/global/Modal').default>>;

/**
 * @category widgets
 * @subcategory toolbox
 * @class DialogMgr
 * @classdesc Represents ajax component with next features:
 * 1. Allow opening the second dialog above the first one.
 * 2. Allow closing opened dialog. If any other opened dialog exists, moves it to the top.
 *
 * @example <caption>Usage of dialogMgr instance. In this case `dialogMgr` used in class {@link TModalInstance} to show it's content above any other opened dialog</caption>
 * import { dialogMgr } from 'widgets/toolbox/dialogMgr';
 * class Modal extends AccessibilityFocusTrapMixin {
 *     ...
        showModal(templateData, cb) {
            ...
            renderedPromise.then(() => {
                ...
                dialogMgr.openDialog(this);
            });
        }
 * }
 */
class DialogMgr {
    dialogsStack: Array<TModalInstance>;

    /**
     * @description Define empty dialogs stack
     */
    constructor() {
        this.dialogsStack = [];
    }

    /**
     * @description Open Modal. If any other opened dialog exists - move it behind newly opened modal.
     * @param dialog - Dialog object
     */
    openDialog(dialog: TModalInstance) {
        const topDialog = this.dialogsStack.length ? this.dialogsStack[this.dialogsStack.length - 1] : null;

        dialog.toggleSpinner(false);

        if (topDialog !== dialog) {
            if (topDialog) {
                topDialog.moveBehind();
            }

            dialog.open();

            this.dialogsStack.push(dialog);
        }
    }

    /**
     * @description Close Modal. If any other opened dialog exists, moves it to top.
     */
    closeDialog() {
        const dialog = this.dialogsStack.pop();

        if (dialog) {
            dialog.close();

            if (this.dialogsStack.length) {
                const previousDialog = this.dialogsStack[this.dialogsStack.length - 1];

                previousDialog.moveToTop();
            } else {
                dialog.removeGlobalDialogClass();
            }
        }
    }
}

export const dialogMgr = new DialogMgr();
