import { TWidget } from 'widgets/Widget';

/**
 * @description Base Disclosure implementation
 * @param Widget Base widget for extending
 * @returns Disclosure class
 */
function DisclosureClassCreator(Widget: TWidget) {
    /**
     * @category widgets
     * @subcategory global
     * @class Disclosure
     * @augments Widget
     * @classdesc Accessibility [Disclosure component](https://www.w3.org/TR/wai-aria-practices/#disclosure).
     * @property {string} data-widget - Widget name `disclosure`
     * @property {boolean} data-show-on-init - If we need to show disclosure content on init
     * @property {boolean} data-show-on-refresh - If we need to show disclosure on refresh (for ex. after re-render parent widget)
     * @example <caption>Typical Disclosure widget markup</caption>
     * <div
     *    class="b-disclosure"
     *     data-widget="disclosure"
     *     data-show-on-init="false"
     *     data-show-on-refresh="false"
     * >
     *     <button
     *         class="b-disclosure-button"
     *         type="button"
     *         data-event-click="toggleContent"
     *         aria-expanded="false"
     *         aria-controls="coupon-form"
     *         data-ref="disclosureButton"
     *     >
     *         Show more
     *     </button>
     *     <div
     *         class="b-disclosure-content"
     *         data-ref="disclosureContent"
     *         id="coupon-form"
     *         hidden="hidden"
     *     >
     *         <div class="b-disclosure-content_inner">
     *             ... content
     *         </div>
     *     </div>
     * </div>
     */
    class Disclosure extends Widget {
        prefs() {
            return {
                disclosureButton: 'disclosureButton',
                disclosureContent: 'disclosureContent',
                showOnInit: false,
                showOnRefresh: false,
                ...super.prefs()
            };
        }

        init() {
            super.init();

            this.hideContent();

            if (this.prefs().showOnInit) {
                this.showContent();
            }
        }

        /**
         * @description Happens on widget refresh and is needed to handle updated state
         */
        onRefresh() {
            this[this.prefs().showOnRefresh ? 'showContent' : 'hideContent']();
        }

        /**
         * @description Toggles disclosure content
         * @listens dom#click
         */
        toggleContent() {
            const isContentHidden = this.ref('disclosureContent').hasAttr('hidden');

            this[isContentHidden ? 'showContent' : 'hideContent']();

            if (isContentHidden) {
                this.ref('disclosureButton').focus();
            } else {
                this.focusContent();
            }
        }

        /**
         * @description Show disclosure content and update related attributes
         */
        showContent() {
            this.ref('disclosureContent').show().attr('aria-hidden', 'false');
            this.ref('disclosureButton').attr('aria-expanded', 'true');
        }

        /**
         * @description Hide disclosure content and update related attributes
         */
        hideContent() {
            this.ref('disclosureContent').hide().attr('aria-hidden', 'true');
            this.ref('disclosureButton').attr('aria-expanded', 'false');
        }

        /**
         * @description Navigate focus to button or input when content is opened / closed.
         * This handler called only when user click trigger button.
         */
        focusContent() {
            if (!this.ref('disclosureContent').hasAttr('hidden')) {
                // class AccessibilityFocusMixin, method focusFirstInput
                this.callIfExists('focusFirstInput', 'disclosureContent');
            }
        }
    }

    return Disclosure;
}

export type TDisclosure = ReturnType<typeof DisclosureClassCreator>;

export type TDisclosureInstance = InstanceType<TDisclosure>;

export default DisclosureClassCreator;
