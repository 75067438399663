import { TWidget } from 'widgets/Widget';
import { submitFormJson } from '../toolbox/ajax';
import { setCookie, getCookie } from '../toolbox/cookie';

function ConsentTrackingClassCreator(Widget: TWidget) {
    /**
     * @category widgets
     * @subcategory global
     * @class ConsentTracking
     * @augments Widget
     * @classdesc ConsentTracking Widget - used to expose Consent dialog, where customer can either accept
     * <br>or reject Consent matter. Relevant methods will be triggered pressing related buttons
     * @property {string} data-widget - Widget name `consentTracking`
     * @property {string} data-reject-url - Customer chooses `reject` - request will be sent to Reject URL
     * @property {string} data-accept-url - Customer chooses `accept` - request will be sent to Accept URL
     * @example <caption>Example of ConsentTracking widget usage</caption>
     * <div
     *     data-widget="consentTracking"
     *     data-reject-url="${URLUtils.url('ConsentTracking-SetSession', 'consent', 'false')}"
     *     data-accept-url="${URLUtils.url('ConsentTracking-SetSession', 'consent', 'true')}"
     *     data-tracking-selected="${pdict.tracking_consent != null}"
     *     data-consent-api="${pdict.caOnline}"
     * >
     *     <div
     *         id="consent-dialog"
     *         class="b-notification_dialog"
     *         data-tau="consent-dialog"
     *     >
     *         <div class="b-notification_dialog-inner">
     *             <div class="b-notification_dialog-content">
     *                 <iscontentasset aid="tracking_hint" />
     *             </div>
     *             <button
     *                 class="b-notification_dialog-button b-button m-outline"
     *                 aria-controls="consent-dialog"
     *                 type="button"
     *                 data-event-click="accept"
     *                 data-tau="consent-dialog_accept"
     *             >
     *                 ${Resource.msg('tracking.accept', 'global', '')}
     *             </button>
     *             <button
     *                 class="b-notification_dialog-button b-button m-outline"
     *                 aria-controls="consent-dialog"
     *                 type="button"
     *                 data-event-click="reject"
     *                 data-tau="consent-dialog_reject"
     *             >
     *                 <isprint value="${Resource.msg('tracking.decline', 'global', '')}" encoding="off" />
     *             </button>
     *         </div>
     *     </div>
     * </div>
     */
    class ConsentTracking extends Widget {
        prefs() {
            return {
                acceptUrl: '',
                rejectUrl: '',
                ...super.prefs()
            };
        }

        init() {
            if (!window.sfccUnCachedData?.consentTrackingHint) {
                return;
            }

            if (getCookie('dw_accept_cookie') === '1') {
                return;
            }

            this.ref('self')
                .show()
                .attr('role', 'alert');
        }

        /**
         * @description Toggle Panel visibility
         * @param isVisible - `true` if Consent's dialog should be shown, `false` - otherwise
         */
        togglePanel(isVisible: boolean) {
            this.ref('self').toggle(isVisible);
        }

        /**
         * @description Accept Consent handler
         * @listens dom#click
         * @returns Promise object represents server response for consent tracking accepting
         */
        accept(): Promise<Record<string, unknown>> {
            return submitFormJson(this.prefs().acceptUrl, undefined, 'POST').finally(() => {
                this.togglePanel(false);
                setCookie('dw_accept_cookie', '1', 365, true);
                setCookie('dw_consent_cookie', '1', 365, true);
            });
        }

        /**
         * @description Reject Consent handler
         * @listens dom#click
         * @returns Promise object represents server response for consent tracking rejection
         */
        reject(): Promise<Record<string, unknown>> {
            return submitFormJson(this.prefs().rejectUrl, undefined, 'POST').finally(() => {
                this.togglePanel(false);
                setCookie('dw_accept_cookie', '1', 365, true);
            });
        }
    }

    return ConsentTracking;
}

export type TConsentTracking = ReturnType<typeof ConsentTrackingClassCreator>;

export type TConsentTrackingInstance = InstanceType<TConsentTracking>;

export default ConsentTrackingClassCreator;
