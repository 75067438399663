import { RefElement } from 'widgets/toolbox/RefElement';

type TOptionData = {
    name: string;
    value: string;
    id?: string;
};

/**
 * @description Base InputSelect implementation
 * @param BasicInput Base widget for extending
 * @returns Input Select class
 */
function InputSelectClassCreator(BasicInput: import('widgets/forms/BasicInput').TBasicInput) {
    /**
     * @category widgets
     * @subcategory forms
     * @class InputSelect
     * @augments BasicInput
     * @classdesc Select input implementation. Represents input `password` element together with widget-related HTML markup.
     * HTML structure assembled on backend and injected into resulted html markup by using `formElement` component
     * and dynamic forms configuration JSON.
     * @property {string} data-widget - Widget name `inputSelect`
     * @property {boolean} [first-default] - Select first option even if other option marked as selected
     * @example <caption>InputSelect definition in dynamicForms.js</caption>
     * ...
     * // fields -> select
     * select: {
     *     'element.type': 'select',
     *     validation: {
     *         'patterns.security': 'validation.patterns.security',
     *         'errors.security': 'validation.errors.parse'
     *     }
     * },
     * ...
     * // fields -> generic -> country
     * country: {
     *     widget: {
     *         attributes: {
     *             'data-widget-event-change': 'onCountryChange'
     *         },
     *         classes: 'm-small'
     *     },
     *     extends: 'fields.select',
     *     'label.text': 'form.address.country',
     *     element: {
     *         required: true,
     *             attributes: {
     *                 'data-event-change': 'onChange'
     *             }
     *         }
     *     },
     * }
     * ...
     * @example <caption>Insertion of InputSelect inside ISML templates</caption>
     * <isset name="formElement" value="${require('forms/formElement')}" scope="page"/>
     * ...
     * <form>
     *     ...
     *     <isprint value="${
     *         formElement(pdict.addressForm.country, pdict.addressFormOptions).render()
     *     }" encoding="off"/>
     *     ...
     * </form>
     * @example <caption>Resulted HTML structure for InputSelect</caption>
     * <div data-widget="inputSelect" data-first-default="true"
     *     data-widget-event-change="updateShippingState" class="b-form_field m-required m-invalid"
     *     data-id="dwfrm_shipping_shippingAddress_addressFields_states_stateCode"
     *     data-validation-config="... validation config"
     * >
     *     <label class="b-form_field-label" for="dwfrm_shipping_shippingAddress_addressFields_states_stateCode">
     *         <span class="b-form_field-required" aria-hidden="true">*</span>
     *         State
     *     </label>
     *     <div class="b-select">
     *         <select data-ref="field" class="b-select-input m-invalid"
     *             id="dwfrm_shipping_shippingAddress_addressFields_states_stateCode"
     *             name="dwfrm_shipping_shippingAddress_addressFields_states_stateCode"
     *             required="" aria-required="true" data-event-change="onChange"
     *             aria-describedby="dwfrm_shipping_shippingAddress_addressFields_states_stateCode-error"
     *             data-event-blur="validate"
     *         >
     *             <option value="" data-id="0">Please select</option>
     *             ...
     *         </select>
     *         <svg aria-hidden="true" class="b-select-icon" width="10" height="6" focusable="false">
     *             <use href="#arrow-down-small"></use>
     *         </svg>
     *     </div>
     *     <div role="alert" class="b-form_field-message" data-ref="errorFeedback" id="dwfrm_shipping_shippingAddress_addressFields_states_stateCode-error">This field is required.</div>
     * </div>
     */
    class InputSelect extends BasicInput {
        prefs() {
            return {
                firstDefault: false,
                staticOptionEnabled: false,
                staticOptionClass: 'b-select-input_as_text',
                selectableOptionsClass: 'b-select-input',
                ...super.prefs()
            };
        }

        init() {
            const field = this.ref('field').get<HTMLSelectElement>();

            if (field) {
                const options = this.getOptions();

                let optionToSelect: HTMLOptionElement;

                if (this.prefs().firstDefault) {
                    optionToSelect = options[0];
                } else {
                    optionToSelect = options.find(elem => elem.hasAttribute('selected')) || options[0];
                }

                if (optionToSelect && field.selectedIndex !== optionToSelect.index) {
                    field.selectedIndex = optionToSelect.index;
                }
            }

            super.init();
        }

        /**
         * @description InputSelect on change handler
         * @listens dom#change
         * @param el source of event
         * @param event event instance if DOM event
         */
        onChange(el: RefElement, event: Event | undefined) {
            if (!this.config.dontPrevent && event && event instanceof Event) {
                event.preventDefault();
            }

            this.update();
        }

        /**
         * @description Sets new value on select from selected option
         * @param newVal new value
         * @param silently validate after change
         */
        setValue(newVal: string = this.initValue, silently?: boolean) {
            let val = newVal || '';

            const field = this.ref('field').get<HTMLSelectElement>();

            if (field) {
                const option = Array.from(field.options).find(elem => elem.value === val);

                if (!option) {
                    val = this.initValue || '';
                }
            }

            super.setValue(val, silently);
        }

        /**
         * @description Get value from selected option
         * @returns selected option value
         */
        getValue(): string {
            const selectedOption = this.getSelectedOptions();

            if (selectedOption) {
                if (selectedOption.length) {
                    return selectedOption.val().toString();
                }

                const field = this.ref('field').get<HTMLSelectElement>();

                if (field && field.options.length) {
                    const item = field.options.item(0);

                    if (item) {
                        return item.value;
                    }
                }
            }

            return '';
        }

        /**
         * @description Get selected options for select
         * @returns selected options
         */
        getSelectedOptions(): RefElement | undefined {
            const field = this.ref('field').get<HTMLSelectElement>();

            if (field && field.selectedOptions) {
                return new RefElement(Array.from(field.selectedOptions));
            }

            return undefined;
        }

        /**
         * @description Get options for select
         * @returns select options
         */
        getOptions(): Array<HTMLOptionElement> {
            const field = this.ref('field').get<HTMLSelectElement>();

            if (field) {
                return Array.from(field.options);
            }

            return [];
        }

        /**
         * @description Update options list
         * @param optionsData Options list
         */
        updateOptions(optionsData: Array<TOptionData>) {
            const field = this.ref('field').empty().get();

            if (!field) {
                return;
            }

            optionsData.forEach(optionData => {
                const option = document.createElement('option');

                option.value = optionData.value;
                option.innerHTML = optionData.name;

                if (optionData.id) {
                    option.dataset.id = optionData.id;
                }

                field.appendChild(option);
            });

            if (this.prefs().staticOptionEnabled) {
                const singleOption = optionsData.length === 1;

                this.ref('icon').toggle(!singleOption);

                if (singleOption) {
                    this.ref('field').addClass(this.prefs().staticOptionClass);
                    this.ref('field').removeClass(this.prefs().selectableOptionsClass);
                    this.ref('field').attr('tabindex', '-1');
                } else {
                    this.ref('field').addClass(this.prefs().selectableOptionsClass);
                    this.ref('field').removeClass(this.prefs().staticOptionClass);
                    this.ref('field').attr('tabindex', null);
                }
            }
        }

        /**
         * @description Get selected option text
         * @returns selected option text
         */
        getText(): string {
            const field = this.ref('field').get<HTMLSelectElement>();

            if (field) {
                const selectedNode = Object.values(field.childNodes)
                    .find(node => (<HTMLOptionElement> node).selected);

                if (selectedNode) {
                    return selectedNode.textContent || '';
                }

                return '';
            }

            return '';
        }

        /**
         * @description Locks select input (add locked classes, set read-only, disabled)
         */
        lock() {
            if (!this.locked) {
                super.lock();
                this.ref('field').attr('disabled', true);
            }
        }

        /**
         * @description Unlocks select input (remove locked classes, remove read-only, disabled)
         */
        unlock() {
            if (this.locked) {
                super.unlock();
                this.ref('field').attr('disabled', false);
            }
        }

        changeAttribute() {} // eslint-disable-line

        /**
         * @description Method to stop propagation in case if input events should not be interrupted.
         * For example, when there is a listener on a parent widget that is triggered by the same keydown event and it should not fire, etc.
         * @param _ - source of keydown event
         * @param event - keydown event object
         */
        stopImmediatePropagation(_: HTMLElement, event: Event) {
            if (event) { event.stopImmediatePropagation(); }
        }
    }

    return InputSelect;
}

export type TInputSelect = ReturnType<typeof InputSelectClassCreator>;

export type TInputSelectInstance = InstanceType<TInputSelect>;

export default InputSelectClassCreator;
