/**
 * @module scroll
 * @category widgets
 * @subcategory toolbox
 * @description Represents scroll component with next features:
 * 1. Allow to scroll element into view
 * 2. Allow to scroll element to provided coordinate
 * 3. Allow to scroll window to provided element
 * 4. Allow to scroll window to top
 * 5. Allow to get scroll position
 * 6. Allow to add callback on scroll event
 *
 * @example <caption>Example of scroll module usage</caption>
 * import { scrollWindowTo, scrollToTop } from 'widgets/toolbox/scroll';
 * scrollWindowTo(errorElement, true);
 */

import { debounce } from 'widgets/toolbox/debounce';
import { getViewType } from 'widgets/toolbox/viewtype';
import { stickyHeaderHeightMap } from 'config/stickyHeaderHeightMap';

/**
 * @description Scroll element into view
 * @param element - HTML Element
 */
export function scrollIntoView(element?: HTMLElement): void {
    if (!element) {
        return;
    }

    if ('scrollBehavior' in document.documentElement.style) {
        element.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
    } else {
        element.scrollIntoView(false);
    }
}

/**
 * @description Scroll element to provided coordinate
 * @param element - HTML Element
 * @param top - top coordinate
 * @param left - left coordinate
 */
export function scrollElementTo(element: HTMLElement, top = 0, left = 0): void {
    if (element && typeof element.scrollTo === 'function') {
        element.scrollTo({ top: top, left: left, behavior: 'smooth' });
    }
}

/**
 * @description Scroll window to provided element
 * @param element - HTML Element
 * @param isCountHeaderHeight - add to top header height
 * @param isCentered - centers the element inside the window
 */
export function scrollWindowTo(element?: HTMLElement, isCountHeaderHeight = false, isCentered = false): void {
    if (!element) {
        return;
    }

    const headerHeight = stickyHeaderHeightMap[getViewType()];
    let top = element.getBoundingClientRect().top + window.pageYOffset;

    if (isCentered) {
        top -= (window.innerHeight - element.offsetHeight) / 2;
    }

    const left = 0;

    if (isCountHeaderHeight) {
        window.scrollTo({ top: top - headerHeight, left: left, behavior: 'smooth' });
    } else {
        window.scrollTo({ top: top, left: left, behavior: 'smooth' });
    }
}

/**
 * @description Scroll window to top
 */
export function scrollToTop(): void {
    window.scrollTo(0, 0);
}

const DEBOUNCE_INTERVAL = 20;

type TScrollHandler = (arg0: { currentPosition: number; lastScrollPosition: number; diff: number }) => void;

const scrollHandlers: Array<TScrollHandler> = [];

/**
 * @description Get scroll position
 * @returns Scroll position
 */
export function getScrollPosition(): number {
    if (document.documentElement.scrollTop !== undefined) {
        return document.documentElement.scrollTop;
    }

    if (document.scrollingElement && document.scrollingElement.scrollTop !== undefined) {
        return document.scrollingElement.scrollTop;
    }

    return -1;
}

let lastScrollPosition = 0;

const debounceScroll = debounce(() => {
    const currentPosition = getScrollPosition();

    scrollHandlers.forEach(handler => handler({
        currentPosition,
        lastScrollPosition,
        diff: currentPosition - lastScrollPosition
    }));
    lastScrollPosition = currentPosition;
}, DEBOUNCE_INTERVAL, true, true);

/**
 * @description Add callback on scroll event
 * @param callback - on scroll callback
 * @returns result
 */
export function onScroll(callback: TScrollHandler): () => void {
    if (!scrollHandlers.length) {
        window.addEventListener('scroll', debounceScroll, { passive: true });
    }

    scrollHandlers.push(callback);

    return () => {
        scrollHandlers.splice(scrollHandlers.indexOf(callback), 1);

        if (!scrollHandlers.length) {
            window.removeEventListener('scroll', debounceScroll);
        }
    };
}
