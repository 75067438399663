import { timeout, isDOMElementFocusable } from 'widgets/toolbox/util';
import { TWidget } from 'widgets/Widget';

/**
 * @param Widget Base widget for extending
 * @returns AccessibilityFocusTrapMixin class
 */
function AccessibilityFocusTrapMixinClassCreator(Widget: TWidget) {
    /**
     * @category widgets
     * @subcategory global
     * @class AccessibilityFocusTrapMixin
     * @augments Widget
     * @classdesc Base AccessibilityFocusTrapMixin implementation.
     * Used to trap focus inside widget according to usability requirements
     * Can be either looped or not
     * This class is not intended to have a separate DOM representation, but should be used as a mixin.
     * Creates in runtime 2 `span` elements inside widget, which has `tabindex="0"` and can handle focus.
     * Once one of these spans are focused (depending last or first) - focus will be handled accordingly
     * (either stays at previously focused widget element, or will be looped).
     * @property {string} data-ref-last-focus-element - Reference to last possible focusable element
     * @property {string} data-ref-first-focus-element - Reference to first possible focusable element
     * @property {boolean} data-focus-loop - Do we need to loop focus inside widget?
     * @property {string} data-ref-traps-container - This ref element would be wrapped with 2 `span` elements - `firstTrapRef` and `lastTrapRef`
     * @property {string} data-first-trap-ref - Reference to first focusable `span` element to handle focus trap.
     * @property {string} data-last-trap-ref - Reference to last focusable `span` element to handle focus trap.
     * @property {number} data-focus-timeout - Timeout to set focus to element
     */
    class AccessibilityFocusTrapMixin extends Widget {
        prefs() {
            return {
                refFirstFocusElement: 'firstFocusElement',
                refLastFocusElement: 'lastFocusElement',
                focusLoop: true,
                refTrapsContainer: 'dialog',
                firstTrapRef: 'firstTrap',
                lastTrapRef: 'lastTrap',
                focusTimeout: 600,
                ...super.prefs()
            };
        }

        init() {
            super.init();

            const { firstTrapRef, lastTrapRef } = this.prefs();

            this.onDestroy(() => {
                [firstTrapRef, lastTrapRef].forEach((ref) => {
                    this.has(ref, el => el.remove());
                });
            });
        }

        /**
         * @description Handle Focus Trap Last.
         * Either focuses on first element (in case of focus loop) or stays at last element.
         * If last element is not found - tries to set focus on first element (loop-like behavior)
         * @listens dom#focus
         */
        handleFocusTrapLast() {
            if (this.prefs().focusLoop) {
                this.focusFirstElement();

                return;
            }

            const hasLastElement = this.focusLastElement();

            if (!hasLastElement) {
                this.focusFirstElement();
            }
        }

        /**
         * @description Handle Focus Trap First.
         * Either focuses on last element (in case of focus loop) or stays at first element.
         * @listens dom#focus
         */
        handleFocusTrapFirst() {
            if (this.prefs().focusLoop) {
                this.focusLastElement();

                return;
            }

            this.focusFirstElement();
        }

        /**
         * @description Method to focus on element by ref id
         * @param refId ref id of ref element
         * @returns boolean indicating whether necessary ref element found
         */
        focusElement(refId: string): boolean {
            return this.has(refId, (element) => {
                const domNode = element.get();

                if (domNode) { domNode.focus(); }
            });
        }

        /**
         * @description Focus first element
         * @returns  true if browser is able to focus on first element
         */
        focusFirstElement(): boolean {
            return this.focusElement(this.prefs().refFirstFocusElement)
                || this.focusFirstDescendant();
        }

        /**
         * @description Focus on first descendant element
         * @param element container element
         */
        focusFirstDescendant(element?: HTMLElement | Element): boolean {
            if (!element) {
                element = this.ref(this.prefs().refTrapsContainer).get();
            }

            if (element) {
                for (let i = 0; i < element.children.length; i++) {
                    const child = element.children[i];

                    if (child && (this.focusAttempt(child) || this.focusFirstDescendant(child))) {
                        return true;
                    }
                }
            }

            return false;
        }

        /**
         * @description Focus last element
         * Returns true if browser is able to focus on last element
         */
        focusLastElement(): boolean {
            return this.focusElement(this.prefs().refLastFocusElement)
                || this.focusLastDescendant();
        }

        /**
         * @description Focus on last descendant element
         * @param element container element
         */
        focusLastDescendant(element?: HTMLElement | Element): boolean {
            if (!element) {
                element = this.ref(this.prefs().refTrapsContainer).get();
            }

            if (element) {
                for (let i = element.children.length - 1; i >= 0; i--) {
                    const child = element.children[i];

                    if (child && (this.focusAttempt(child) || this.focusLastDescendant(child))) {
                        return true;
                    }
                }
            }

            return false;
        }

        /**
         * @description Try to focus on element if it's focusable
         * @param element target element to focus
         */
        focusAttempt(element: HTMLElement | Element): boolean {
            if (!isDOMElementFocusable(element) || this.ref('lastTrapRef').get() === element) {
                return false;
            }

            try {
                if (element instanceof HTMLIFrameElement) {
                    element.contentWindow?.focus();
                } else {
                    (element as HTMLElement).focus();
                }
            } catch (e) {
                // catch error
            }

            return (document.activeElement === element);
        }

        /**
         * @description Overridden `show` method, which will create 2 `span` elements for focus trap
         * on {@link Modal} show.
         */
        show(): this {
            super.show();

            this.addFocusTraps();

            return this;
        }

        /**
         * @description Creates 2 `span` element with `tabindex="0"` to be able to handle focus selection as per accessibility requirements.
         * FIXME: each time when dialog closed new dialog add more this.disposables
         * FIXME: initially this.refs do not contain firstTrapRef & lastTrapRef but they mysteriously appears on check
         * @param isForce do we need to add new elements even if .has check passed
         */
        addFocusTraps(isForce = false) {
            this.has(this.prefs().refTrapsContainer, trapsContainer => {
                const { firstTrapRef, lastTrapRef } = this.prefs();

                if (this.has(firstTrapRef) && this.has(lastTrapRef) && !isForce) {
                    return;
                }

                trapsContainer.prepend(
                    `<span tabindex="0" data-ref="${firstTrapRef}" data-event-focus.prevent="handleFocusTrapFirst"></span>`
                );
                trapsContainer.append(
                    `<span tabindex="0" data-ref="${lastTrapRef}" data-event-focus.prevent="handleFocusTrapLast"></span>`
                );
            });
        }

        /**
         * @description focus first element after show modal (when keyboard navigation)
         */
        afterShowModal() {
            this.onDestroy(timeout(() => this.focusFirstElement(), this.prefs().focusTimeout));
        }
    }

    return AccessibilityFocusTrapMixin;
}

export type TAccessibilityFocusTrapMixin = ReturnType<typeof AccessibilityFocusTrapMixinClassCreator>;

export type TAccessibilityFocusTrapMixinInstance = InstanceType<TAccessibilityFocusTrapMixin>;

export default AccessibilityFocusTrapMixinClassCreator;
