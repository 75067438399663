/**
 * @description Sticky header height
 * @module stickyHeaderHeightMap
 * @category config
 */

import {
    EXTRA_LARGE,
    LARGE,
    MEDIUM,
    SMALL
} from 'widgets/toolbox/viewtype';

export const stickyHeaderHeightMap = {
    [SMALL]: 64,
    [MEDIUM]: 64,
    [LARGE]: 80,
    [EXTRA_LARGE]: 80
};
