import { timeout } from './util';
/**
 * @category widgets
 * @subcategory toolbox
 * @class PausableTimeout
 * @classdesc Class represents the `setTimeout` with an ability to perform pause/resume actions
 *
 * @example <caption>Example of PausableTimeout widget usage</caption>
 * import { PausableTimeout } from 'widgets/toolbox/PausableTimeout';
 * this.pausableTimeout = new PausableTimeout(() => {
 *      this.hideAlert();
 * }, this.prefs().timeout);
 */
export class PausableTimeout {
    durationTimeout: (() => void) | undefined;

    done: boolean;

    callback: () => void;

    remaining: number;

    start: Date = new Date();

    /**
     * @description PausableTimeout init logic
     * @param callback function to be called after specified time
     * @param time time before call callback
     */
    constructor(callback: (() => void) | undefined, time = 0) {
        this.done = false;

        this.callback = () => {
            if (callback) {
                callback();
            }

            this.done = true;
            this.durationTimeout = undefined;
            callback = undefined;
        };

        this.remaining = time;
        this.resume();
    }

    /**
     * @description Pauses Timeout
     * @returns Instance of the PausableTimeout class
     */
    pause(): this {
        if (this.durationTimeout && !this.done) {
            this.clearTimeoutRef();
            this.remaining -= new Date().getTime() - (this.start ? this.start.getTime() : 0);
        }

        return this;
    }

    /**
     * @description Resumes Timeout
     * @returns Instance of the PausableTimeout class
     */
    resume(): this {
        if (!this.durationTimeout && !this.done) {
            this.start = new Date();
            this.durationTimeout = timeout(this.callback, this.remaining);
        }

        return this;
    }

    /**
     * @description Clear resources on demand
     */
    destroy() {
        this.clearTimeout();
    }

    /**
     * @description Clears the timeout and marks it as done.
     * After called, the timeout will not resume.
     */
    clearTimeout() {
        this.clearTimeoutRef();
        this.done = true;
    }

    /**
     * @description Clears resources

     */
    clearTimeoutRef() {
        if (this.durationTimeout) {
            this.durationTimeout();
            this.durationTimeout = undefined;
        }
    }
}
