import { TWidget } from 'widgets/Widget';

/**
 * @param Widget Base widget for extending
 * @returns AnimationOnScroll class
 */
function AnimationOnScrollClassCreator(Widget: TWidget) {
    /**
     * @category widgets
     * @subcategory plugin_page_designer_ext
     * @class AnimationOnScroll
     * @augments Widget
     * @classdesc Layout animation by adding m-animated class to data-ref=elemAnimated
     *   <div
     *     class="l-grid_layout m-1_up m-${pdict.scrollAnimations}"
     *     style="${pdict.styles}"
     *     data-widget="animationOnScroll"
     *     data-ref="elemAnimated"
     *    >
     *       <div class="l-grid_layout-content ${pdict.hideGutters}">
     *            <isprint value="${
     *                   pdict.regions.layout1Column1
     *                      .setClassName('l-grid_layout-item m-lg_12 m-md_12 m-sm_6')
     *                      .setComponentClassName('l-grid_layout-item_in')
     *                      .setComponentAttribute('data-cell-type', 'lg-12 md-12 sm-6')
     *                      .setComponentAttribute('data-layout-type', pdict.layoutContext)
     *                      .render()
     *             }" encoding="off"/>
     *       </div>
     *   </div>
     */
    class AnimationOnScroll extends Widget {
        prefs() {
            return {
                element: 'elemAnimated',
                animatedClass: 'm-animated',
                rootMargin: '0px 0px -30%',
                ...super.prefs()
            };
        }

        init() {
            super.init();
            this.bindObserver();
        }

        /**
         * @description Executed when widget is re-rendered
         */
        onRefresh() {
            super.onRefresh();
            this.bindObserver();
        }

        /**
         * @description bindObserver observe changes in the intersection of a target element
         */
        bindObserver() {
            const element = this.ref(this.prefs().element).get();

            if (!element) {
                return;
            }

            const options = {
                rootMargin: this.prefs().rootMargin
            };

            const animatedObserver = new IntersectionObserver((entries, observer) => {
                entries.forEach((entry) => {
                    const itemClasses = entry.target.classList;
                    const isAbove = entry.boundingClientRect.y < (entry.rootBounds?.y || 0);

                    if (isAbove || entry.isIntersecting) {
                        itemClasses.add(this.prefs().animatedClass);
                        observer.unobserve(entry.target);
                    }
                });
            }, options);

            animatedObserver.observe(element);
        }
    }

    return AnimationOnScroll;
}

export type TAnimationOnScroll = ReturnType<typeof AnimationOnScrollClassCreator>;

export type TAnimationOnScrollInstance = InstanceType<TAnimationOnScroll>;

export default AnimationOnScrollClassCreator;
