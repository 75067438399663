/**
 * @module eventBus
 * @category widgets
 * @subcategory toolbox
 * @description Create and returns an instance of EventEmitter. Refer [eventemitter3 documentation](https://www.npmjs.com/package/eventemitter3).
 *
 * @example  <caption>Example of eventBus module usage</caption>
 * import EventBus from 'widgets/toolbox/eventBus';
 * EventBus.addListener(eventName, fn);
 */

import { EventEmitter } from 'eventemitter3';
const EventBus = new EventEmitter();

export default EventBus;
