/**
 * @description Base InputEmail implementation
 * @param InputText Base widget for extending
 * @returns Input Email class
 */
function InputEmailClassCreator(InputText: ReturnType<typeof import('./InputText').default>) {
    /**
     * @category widgets
     * @subcategory forms
     * @class InputEmail
     * @augments InputText
     * @classdesc Input type Email implementation. Represents input `email` element together with widget-related HTML markup.
     * HTML structure assembled on backend and injected into resulted html markup by using `formElement` component
     * and dynamic forms configuration JSON.
     * @property {string} data-widget - Widget name `inputEmail`
     * @example <caption>InputEmail definition in dynamicForms.js</caption>
     * ...
     * // fields -> generic -> email
     * email: {
     *     extends: 'fields.input.base',
     *     element: {
     *         attributes: {
     *             autocomplete: 'email'
     *         },
     *         maxLength: 50,
     *         type: 'email',
     *         required: true
     *     },
     *     validation: {
     *         'patterns.parse': 'validation.patterns.parse.email',
     *         'errors.parse': 'validation.errors.email'
     *     },
     *     'label.text': 'form.profile.email'
     * },
     * ...
     * @example <caption>Insertion of InputEmail inside ISML templates</caption>
     * <isset name="formElement" value="${require('forms/formElement')}" scope="page"/>
     * ...
     * <form>
     *     ...
     *     <isprint value="${
     *         formElement(pdict.addressForm.email, pdict.addressFormOptions).render()
     *     }" encoding="off"/>
     *     ...
     * </form>
     * @example <caption>Resulted HTML structure for InputEmail</caption>
     * <div data-widget="inputEmail" class="b-form_field m-required m-invalid" data-id="dwfrm_login_email"
     *     data-validation-config="... validation config"
     * >
     *     <label class="b-form_field-label" for="dwfrm_login_email">
     *         <span class="b-form_field-required" aria-hidden="true">*</span>
     *         Email
     *     </label>
     *     <input data-ref="field" id="dwfrm_login_email" type="email" class="b-input m-invalid" placeholder=""
     *         aria-describedby="dwfrm_login_email-error" pattern="..." name="dwfrm_login_email"
     *         required="" value="" maxlength="50" aria-required="true" autocomplete="email" data-event-input="onInput"
     *         data-tau="login_email" data-event-blur="validate"
     *     >
     *     <div role="alert" class="b-form_field-message" data-ref="errorFeedback" id="dwfrm_login_email-error">This field is required.</div>
     * </div>
     */
    class InputEmail extends InputText {

    }

    return InputEmail;
}

export type TInputEmail = ReturnType<typeof InputEmailClassCreator>;

export type TInputEmailInstance = InstanceType<TInputEmail>;

export default InputEmailClassCreator;
