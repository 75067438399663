import { dialogMgr } from 'widgets/toolbox/dialogMgr';
import { timeout } from 'widgets/toolbox/util';

const ESCAPE_CODE = 27;

/**
 * @description Modal implementation
 * @param AccessibilityFocusTrapMixin mixin
 * @returns Modal class
 */
function ModalClassCreator(
    AccessibilityFocusTrapMixin: import('widgets/global/AccessibilityFocusTrapMixin').TAccessibilityFocusTrapMixin
) {
    /**
     * @category widgets
     * @subcategory global
     * @class Modal
     * @augments AccessibilityFocusTrapMixin
     * @classdesc Generic modal popups implementation.<br/>
     * - It renders a modal popup with grayed background. Can be closed by clicking outside modal.
     * - Allows to manage multi-modal depth structure (move one modal behind of another) (indirectly, bu using {@link dialogMgr}).
     * - If Modal need to be inited only on some viewport can be used modificators (sm,md,lg,xl). For example data-widget.sm.md="modal"</br>
     * Note that in this case need also add same modificator to sub events in html elements. This can be easily done via "dialogViewtypes" variable in ISML template (see example below).<br/>
     * Don't forget clear this variable after dialog closing tag.
     * TODO: move AccesibilityFocusTrapMixin after the modal and hold all abstract methods (like afterShowModal) here.
     * @property {string} data-widget - Widget name `modal`
     * @property {boolean} data-disable-rendering - Disable rendering flag
     * @property {string} data-classes-extra - Extra classes
     * @property {string} data-classes-global-dialog - a set of space separated additional modal classes
     * @property {string} data-classes-show - classes added to shown modal
     * @property {string} data-classes-top-dialog - top modal class in modals hierarchy depth
     * @property {string} data-classes-active - active modal classes
     * @property {string} data-ref-container - modal reference container
     * @property {string} data-ref-dialog - dialog reference container
     * @property {string} data-classes-extra - extra classes for dialog
     * @property {boolean} data-click-out-side - `true` if needed to close modal by clicking outside it
     * @property {boolean} data-close-by-escape - `true` if needed to close modal by using `Esc` button
     *
     * @example <caption>Example of typical Modal widget</caption>
     * <div
     *     data-widget="modal"
     *     data-accessibility-alerts='{
     *         "dialogContentLoaded": "${Resource.msg('alert.dialogContentLoaded', 'global', null)}"
     *     }'
     * >
     *     // dialogViewtypes need only in case when modal should be inited on some viewports
     *     <isset name="dialogViewtypes" value="sm.md" scope="page" />
     *
     *     <div
     *         class="b-dialog"
     *         id="editProductModal"
     *         data-ref="container"
     *         hidden="hidden"
     *         data-event-click.self="closeModal"
     *         data-label-loading="${Resource.msg('common.loading', 'common', '')}"
     *     >
     *         <div
     *             class="b-dialog-window"
     *             role="dialog"
     *             data-ref="dialog"
     *             aria-modal="true"
     *             aria-label="${Resource.msg('common.loading', 'common', '')}"
     *         >
     *             <div class="b-dialog-header">
     *                 <isinclude template="components/modal/closeButton">
     *             </div>
     *             <div
     *                 class="b-dialog-body b-user_content"
     *                 data-ref="content"
     *             ></div>
     *         </div>
     *     </div>
     *
     *     <script type="template/mustache" data-ref="template">
     *          <div
     *             class="b-dialog-window"
     *             role="dialog"
     *             data-ref="dialog"
     *             aria-modal="true"
     *             aria-labelledby="editProductModalTitle"
     *         >
     *             <div class="b-dialog-header">
     *                 <button
     *                     class="b-dialog-close"
     *                     title="${Resource.msg('common.close','common',null)}"
     *                     aria-label="${Resource.msg('common.close','common',null)}"
     *                     type="button"
     *                     data-dismiss="modal"
     *                     data-ref="closeEditPopup"
     *                     data-event-click.prevent="cancel"
     *                     data-tau="edit_product_dialog_close"
     *                 >
     *                     <isinclude template="/common/icons/standalone/close" />
     *                 </button>
     *             </div>
     *             {{${'#'}body}}
     *                 <div class="b-dialog-body" data-ref="content">
     *                     {{&body}}
     *                 </div>
     *             {{/body}}
     *
     *             {{${'#'}footer}}
     *                 <div class="b-dialog-footer">
     *                     {{&footer}}
     *                 </div>
     *             {{/footer}}
     *         </div>
     *     </script>
     * </div>
     */
    class Modal extends AccessibilityFocusTrapMixin {
        backFocusElement?: HTMLElement | null;

        classesWrapper: string | Array<string> = '';

        classesGlobalDialog = '';

        longWaitingTimeout?: () => void | undefined;

        escHandler?: () => void;

        clickOutsideHandler?: () => void;

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        attributes: any;

        isDialogOpen = false;

        prefs() {
            return {
                classesGlobalDialog: 'm-has_dialog',
                classesShow: 'm-opened',
                classesTopDialog: 'm-top_dialog',
                classesActive: 'm-active',
                classesClosed: 'm-closed',
                refContainer: 'container', // TODO: Investigate. get rid of container as required element in modal structure
                refDialog: 'dialog',
                refContent: 'content',
                refTitle: 'title',
                closeOpenedModal: false,
                classesExtra: '',
                clickOutSide: true,
                disableRendering: false,
                closeByEscape: true,
                accessibilityAlerts: <TAccessibilityAlerts>{},
                ...super.prefs()
            };
        }

        init() {
            super.init();

            this.classesGlobalDialog = this.prefs().classesGlobalDialog;

            this.onDestroy(() => {
                const refDialog = this.ref(this.prefs().refDialog);

                refDialog.attr('role', false);
                refDialog.attr('aria-modal', false);
                this.cancel();
            });
        }

        /**
         * @description Show Modal preview for modals with async modal content as an immediate reaction to user action
         * @param [wrapperClasses] - container class name to set appropriate modal window width
         */
        showModalPreview(wrapperClasses?: string | Array<string>) {
            const container = this.ref(this.prefs().refContainer);
            const dialog = this.ref(this.prefs().refDialog);

            container.removeClass(this.classesWrapper);

            if (wrapperClasses) {
                this.classesWrapper = wrapperClasses;
                container.addClass(wrapperClasses);
            }

            container.addClass(this.prefs().classesShow);
            container.show();
            dialog.addClass(this.prefs().classesActive);
            this.toggleSpinner(true);

            this.backFocusElement = <HTMLElement> document.activeElement;
            this.focusFirstElement();
            this.addGlobalDialogClass();
        }

        /**
         * @description Show Modal and puts it to the top of opened modals hierarchy.
         * TODO: Refactor.s it probably would be better to call it only from dialogMgr (if it possible)
         * @param [templateData] data to be rendered in template
         * @param [cb] optional callback
         * @param [isAsyncModalData] is asynchronous modal data
         */
        showModal(templateData?: Record<string, unknown>, cb?: () => void, isAsyncModalData?: boolean): Promise<void> {
            if (this.prefs().closeOpenedModal) {
                this.closeModal();
            }

            if (!isAsyncModalData) {
                this.backFocusElement = <HTMLElement> document.activeElement;
            }

            let renderedPromise = Promise.resolve();

            if (templateData && !this.prefs().disableRendering) {
                const renderRefElement = isAsyncModalData ? this.ref(this.prefs().refDialog) : this.ref(this.prefs().refContainer);

                renderedPromise = this.render(templateData.templateName as string, templateData, renderRefElement);
            }

            return renderedPromise.then(() => {
                this.onBeforeShowModal(<{ attributes: Record<string, unknown>}>templateData);
                dialogMgr.openDialog(this);
                this.show();

                if (isAsyncModalData) {
                    const accessibilityAlert = this.prefs().accessibilityAlerts.dialogContentLoaded;

                    /**
                     * @description Global event to show alert
                     * @event "alert.show"
                     */
                    this.eventBus().emit('alert.show', {
                        accessibilityAlert
                    });
                }

                if (cb && typeof cb === 'function') {
                    cb();
                } else if (!isAsyncModalData) {
                    this.afterShowModal();
                }
            });
        }

        /**
         * @description Shows spinner bar in widget once any update operations (server calls) are pending
         * @param isBusy - show / hide spinner
         */
        toggleSpinner(isBusy: boolean) {
            this.ref(this.prefs().refDialog).removeClass(this.prefs().classesLoading);

            if (this.longWaitingTimeout) {
                this.longWaitingTimeout();
            }

            if (isBusy) {
                // the spinner will be shown for an action that takes more than 1 second, which is recommended by UX practices
                this.longWaitingTimeout = timeout(() => {
                    this.ref(this.prefs().refDialog).addClass(this.prefs().classesLoading);
                }, 1000);
            }
        }

        /**
         * @description Open a dialog. This method is executed explicitly or implicitly from `showModal` method.
         */
        open() {
            const dialog = this.ref(this.prefs().refDialog);

            dialog.attr('role', 'dialog');
            dialog.attr('aria-modal', 'true');
            dialog
                .addClass(this.prefs().classesActive)
                .addClass(this.prefs().classesTopDialog)
                .removeClass(this.prefs().classesClosed);

            this.addGlobalDialogClass();
            this.addListeners();

            const classes = [this.prefs().classesShow];

            if (this.prefs().classesExtra) {
                classes.push(this.prefs().classesExtra);
            }

            this.ref(this.prefs().refContainer)
                .addClass(classes.join(' '))
                .show();

            this.isDialogOpen = true;
        }

        /**
         * @description Move Behind current modal in opened modals stack.
         */
        moveBehind() {
            this.cleanUpListeners();
            this.ref(this.prefs().refDialog).removeClass(this.prefs().classesTopDialog);
        }

        /**
         * @description Move To Top current modal in opened modals stack.
         */
        moveToTop() {
            this.addListeners();
            this.ref(this.prefs().refDialog).addClass(this.prefs().classesTopDialog);
        }

        /**
         * @description Close modal.
         */
        close() {
            this.cleanUpListeners();

            const classes = [this.prefs().classesShow];

            if (this.prefs().classesExtra) {
                classes.push(this.prefs().classesExtra);
            }

            this.ref(this.prefs().refContainer).removeClass(classes.join(' '));

            this.ref(this.prefs().refDialog)
                .removeClass([this.prefs().classesTopDialog, this.prefs().classesActive].join(' '))
                .addClass(this.prefs().classesClosed);

            this.isDialogOpen = false;
        }

        /**
         * @description Add Global Dialog Class
         */
        addGlobalDialogClass() {
            const html = this.ref('html');

            if (!this.classesGlobalDialog) { return; }

            if (!html.hasClass(this.classesGlobalDialog)) {
                html.addClass(this.classesGlobalDialog);
            }
        }

        /**
         * @description Remove Global Dialog Class
         */
        removeGlobalDialogClass() {
            if (!this.classesGlobalDialog) { return; }

            this.ref('html').removeClass(this.classesGlobalDialog);
        }

        /**
         * @description Close Modal
         * TODO: Refactor. it probably would be better to call it only from dialogMgr (if it possible)
         * What is difference from .close()
         */
        closeModal() {
            dialogMgr.closeDialog();

            if (this.backFocusElement) {
                this.backFocusElement.focus();
                this.backFocusElement = null;
            }

            if (!this.prefs().disableRendering) {
                const refContainer = this.ref(this.prefs().refContainer);

                refContainer.hide();
                this.ref(this.prefs().refContent).empty();
                this.ref(this.prefs().refTitle).empty();
                this.ref(this.prefs().refDialog).attr('aria-label', refContainer.data<string>('labelLoading'));
            }

            this.onAfterCloseModal();
        }

        /**
         * @description Clean Up Listeners
         */
        cleanUpListeners() {
            if (this.escHandler) {
                this.escHandler();
                this.escHandler = undefined;
            }

            if (this.clickOutsideHandler) {
                this.clickOutsideHandler();
                this.clickOutsideHandler = undefined;
            }
        }

        /**
         * @description Lifecycle hook `onAfterCloseModal` executes after closing modal window.
         * Used to:
         * - remove modal DOM element attributes as per modal setup
         */
        onAfterCloseModal() {
            if (this.attributes) {
                Object.keys(this.attributes).forEach((key) => {
                    this.ref('container').attr(key, false);
                });
            }
        }

        /**
         * @description Lifecycle hook `onBeforeShowModal` executes before opening modal window.
         * @param modalData Input object for modal popup.
         * @param modalData.attributes
         * Used to:
         * - add modal DOM element attributes as per modal setup
         */
        onBeforeShowModal(modalData: { attributes: Record<string, unknown>} | undefined) {
            if (modalData && modalData.attributes) {
                Object.keys(modalData.attributes).forEach((key) => {
                    const value = <string>modalData.attributes[key];

                    if (value === null || value === undefined || value === '') {
                        delete modalData.attributes[key];
                    }

                    this.ref('container').attr(key, value);
                });
                this.attributes = modalData.attributes;
            }
        }

        /**
         * @description Cancel Handler
         * @emits Modal#cancel
         */
        cancel() {
            this.closeModal();
            /**
             * @description Event dispatched, when modal was closed
             * @event Modal#cancel
             */
            this.emit('cancel');
        }

        /**
         * @description Add Click Outside / Close by ESC Listener
         */
        addListeners() {
            if (this.prefs().clickOutSide) {
                this.clickOutsideHandler = this.ev('click', (_, event) => {
                    if (event.target === this.ref(this.prefs().refContainer).get()) {
                        this.cancel();
                    }
                }, this.ref(this.prefs().refContainer).get()).pop();
            }

            if (this.prefs().closeByEscape) {
                this.escHandler = this.ev('keyup', (_, event) => {
                    const keyboardEvent = <KeyboardEvent> event;

                    if (keyboardEvent.keyCode === ESCAPE_CODE) {
                        this.cancel();
                    }
                }, window).pop();
            }
        }

        /**
         * @description Shows modal in a DOM
         * @returns this obj - current instance for chaining
         */
        show(): this {
            super.show();

            return this;
        }

        /**
         * @description Hide modal in DOM
         * @returns this obj - current instance for chaining
         */
        hide(): this {
            this.ref(this.prefs().refContainer).hide();

            return this;
        }
    }

    return Modal;
}

export type TModal = ReturnType<typeof ModalClassCreator>;

export type TModalInstance = InstanceType<TModal>;

export default ModalClassCreator;
