import { TWidget } from 'widgets/Widget';
import { setCookie } from '../toolbox/cookie';

function GeolocationClassCreator(Widget: TWidget) {
    /**
     * @category widgets
     * @subcategory global
     * @class Geolocation
     * @augments Widget
     * @classdesc Geolocation Widget - the alert that suggests customer to change Site
     * if customer's location is not applicable for the current site.
     * The alert is shown only once at the first visit to the site.
     * @property {string} data-widget - Widget name `Geolocation`
     */
    class Geolocation extends Widget {
        init() {
            if (window.sfccUnCachedData?.geolocationDialog) {
                setCookie(window.sfccUnCachedData.geolocationDialog.geolocationCookieName, '1', 360);
                this.ref('closeButton').setText(window.sfccUnCachedData?.geolocationDialog.selectedLocale);
                this.ref('customerCountryMessage').setText(window.sfccUnCachedData?.geolocationDialog.customerCountry);
                this.ref('self')
                    .attr('role', 'alert')
                    .show();
            }
        }

        /**
         * @description Cancel geolocation changing
         * @listens dom#click
         */
        cancel() {
            this.ref('self').hide();
        }
    }

    return Geolocation;
}

export type TGeolocation = ReturnType<typeof GeolocationClassCreator>;

export type TGeolocationInstance = InstanceType<TGeolocation>;

export default GeolocationClassCreator;
