import { TWidget, TDisposable } from 'widgets/Widget';
import cssLoadChecker from 'widgets/toolbox/cssLoadChecker';
import { timeout } from 'widgets/toolbox/util';
import { RefElement } from 'widgets/toolbox/RefElement';

/**
 * @param Widget Base widget for extending
 * @returns HeroCarousel class
 */
function HeroCarouselClassCreator(Widget: TWidget) {
    /**
     * @category widgets
     * @subcategory global
     * @class HeroCarousel
     * @augments Widget
     * @classdesc Represents HeroCarousel component with next features:
     * 1. Allow to use pagination for carousel rendered by mustache template
     * 2. Allow to use slides autoplay functionality
     * 3. Allow to use start/stop autoplay functionality
     * 4. Allow to change current slide to the next/previous/custom(index can be passed to the method) slide
     * 5. Support mousemove, touchmove, mouseup, mousedown, keydown event so we can use carousel even on touch devices
     * 6. Support for carousel control from the keyboard
     * @property {string} data-widget - widget name `heroCarousel`
     * @property {boolean} data-autoplay-enabled - enables/disables autoplay. If false, autoplay will be hidden
     * @property {boolean} data-autoplay-stopped - starts/stops autoplay. If true, autoplay will be shown but paused
     * @property {string} data-autoplay-duration - value for the duration of the autoplay slide change
     * @property {string} data-autoplay-label-start - autoplay button label text to start automatic slide change
     * @property {string} data-autoplay-label-stop - autoplay button label text to stop automatic slide change
     * @property {string} data-event-mousedown - Event listener for `touchStart` method
     * @property {string} data-event-touchstart - Event listener for `touchStart` method
     * <br>Uses as a basis slider from here (ScrollCarousel.js):
     * <br>https://github.com/dimanech/aria-components/tree/master/cartridge1/js/components/carousels/slider
     * @example <caption>Example of HeroCarousel widget usage</caption>
     * <div
     *     data-widget="heroCarousel"
     *     class="b-hero_carousel"
     *     role="region"
     *     aria-roledescription="carousel"
     *     aria-label="${Resource.msg('carousel.sliderLabel', 'carousel', null)}"
     *     data-event-mousedown.passive="touchStart"
     *     data-event-touchstart.passive="touchStart"
     *     data-tau="carousel_hero"
     *     data-autoplay-enabled="true"
     *     data-autoplay-stopped="false"
     *     data-autoplay-duration="8000"
     *     data-autoplay-label-start="${Resource.msg('carousel.startAutoplay', 'carousel', null)}"
     *     data-autoplay-label-stop="${Resource.msg('carousel.stopAutoplay', 'carousel', null)}"
     * >
     *     <button
     *         class="b-hero_carousel-ctrl m-prev"
     *         aria-label="${Resource.msg('carousel.previousSlide', 'carousel', null)}"
     *         aria-controls="slider-content"
     *         title="${Resource.msg('button.previous', 'common', null)}"
     *         data-ref="elemPrevButton"
     *         data-event-click="goToPrevSlide"
     *         data-tau="carousel_hero_prev"
     *     >
     *         <isinclude template="/common/icons/standalone/arrowCarouselLeft" />
     *     </button>
     *     <button
     *         class="b-hero_carousel-ctrl m-next"
     *         aria-label="${Resource.msg('carousel.nextSlide', 'carousel', null)}"
     *         aria-controls="slider-content"
     *         title="${Resource.msg('button.next', 'common', null)}"
     *         data-ref="elemNextButton"
     *         data-event-click="goToNextSlide"
     *         data-tau="carousel_hero_next"
     *     >
     *         <isinclude template="/common/icons/standalone/arrowCarouselRight" />
     *     </button>
     *     <div class="b-hero_carousel-pagination" data-ref="pagination">
     *         <div class="b-hero_carousel-pagination_content">
     *             <button
     *                 class="b-hero_carousel-autoplay"
     *                 data-ref="autoplay"
     *                 aria-pressed="false"
     *                 aria-label="${Resource.msg('carousel.stopAutoplay', 'carousel', null)}"
     *             >
     *                 <svg class="b-hero_carousel-autoplay_svg" width="36" height="36" viewBox="0 0 36 36" focusable="false" xmlns="http://www.w3.org/2000/svg">
     *                     <circle class="b-hero_carousel-autoplay_progress_back" cx="18" cy="18" r="16.5"></circle>
     *                     <circle class="b-hero_carousel-autoplay_progress" cx="18" cy="18" r="16.5" stroke-dasharray="104" transform="rotate(-90 18 18)"></circle>
     *                     <polygon class="b-hero_carousel-autoplay_play" points="14.4,12.2 14.4,23 25.1,17.5 "></polygon>
     *                     <polygon class="b-hero_carousel-autoplay_pause" points="14.5 12 14.5 24"></polygon>
     *                     <polygon class="b-hero_carousel-autoplay_pause" points="21.5 12 21.5 24"></polygon>
     *                 </svg>
     *             </button>
     *             <div class="b-hero_carousel-pagination_dots" role="group" data-ref="paginationDots" aria-label="${Resource.msg('carousel.chooseSlide', 'carousel', null)}"></div>
     *             <script type="template/mustache" data-ref="template">
     *                 <div class="b-hero_carousel-pagination_dots" role="group" data-ref="paginationDots" aria-label="${Resource.msg('carousel.chooseSlide', 'carousel', null)}">
     *                     {{${'#'}pagination}}
     *                     <button
     *                         class="b-hero_carousel-pagination_dot{{${'#'}isFirst}} m-current{{/isFirst}}"
     *                         aria-labelledby="slide-{{page}}"
     *                         data-page="{{page}}"
     *                         data-event-click="handlePaginationClick"
     *                         data-tau="carousel_hero_cta"
     *                     >
     *                         <svg class="b-hero_carousel-pagination_svg" height="18" width="18" viewBox="0 0 18 18">
     *                             <circle class="b-hero_carousel-pagination_dot_outline" cx="9" cy="9" r="7.5"></circle>
     *                         </svg>
     *                     </button>
     *                     {{/pagination}}
     *                 </div>
     *             </script>
     *         </div>
     *     </div>
     *     <div
     *         class="b-hero_carousel-track"
     *         id="slider-content"
     *         data-ref="elemCarouselTrack"
     *         data-event-touchstart.passive="touchStart"
     *         aria-atomic="false"
     *         aria-live="off"
     *     >
     *       <isset name="ContentModel" value="${require(' /cartridge/models/content')}" scope="page" />
     *       <isloop items="${slotcontent.content}" var="contentAsset" status="loopStatus">
     *           <div
     *               class="b-hero_carousel-item"
     *               role="group"
     *               aria-roledescription="slide"
     *               tabindex="0"
     *               data-label-delimiter="${Resource.msg('carousel.labelDelimiter', 'carousel', null)}"
     *               data-tau="carousel_hero_item"
     *           >
     *               <isprint value="${new ContentModel(contentAsset).getMarkup()}" encoding="off" />
     *           </div>
     *       </isloop>
     *     </div>
     * </div>
     */
    class HeroCarousel extends Widget {
        slides?: HTMLCollection;

        slidesTotal?: number;

        paginationDots: HTMLCollection | null = null;

        pagination?: Promise<HTMLElement | undefined>;

        slidesModel: Array<string> | null = null;

        transitionFallbackTimer?: ReturnType<typeof timeout>;

        nextSlideTimer: ReturnType<typeof timeout> | null = null;

        transitionEndDisposable?: TDisposable;

        touchMoveDisposable?: TDisposable;

        mouseMoveDisposable?: TDisposable;

        mouseUpDisposable?: TDisposable;

        mouseLeaveDisposable?: TDisposable;

        touchEndDisposable?: TDisposable;

        touchCancelDisposable?: TDisposable;

        contextMenuDisposable?: TDisposable;

        observer?: IntersectionObserver;

        remainingTime: number | null = null;

        currentSlideIndex = 0;

        startX = 0;

        startY = 0;

        blockedByAnimations = false;

        isGrabbing = false;

        isSlider = false;

        isFocused = false;

        isHover = false;

        isMoving = false;

        autoPlayStopped = false;

        autoPlayPaused = false;

        creationTime?: number;

        prefs() {
            return {
                autoplayEnabled: true,
                autoplayStopped: false,
                autoplayDuration: '3000',
                autoplayLabelStart: '',
                autoplayLabelStop: '',
                slideCurrentClass: 'm-current',
                slidePreviousClass: 'm-prev',
                slideNextClass: 'm-next',
                slideGrabbingClass: 'm-grabbing',
                slidePrefix: 'slide-',
                carouselInitialized: 'm-initialized',
                autoplayAnimated: 'm-animated',
                swipeMinShift: '16',
                swipeAngle: '30',
                clickShift: '1',
                ...super.prefs()
            };
        }

        /**
         * @description Widget initialization
         */
        init() {
            super.init();
            // Async loading to not block other widget init
            timeout(() => {
                cssLoadChecker.get().then(() => this.initCarousel());
            }, 0);
        }

        /**
         * @description Initial carousel configuration
         */
        initCarousel() {
            // Common carousel properties

            this.currentSlideIndex = 0;

            this.blockedByAnimations = false;

            this.slidesModel = null;

            this.paginationDots = null;

            this.isGrabbing = false;

            this.startX = 0;

            this.startY = 0;

            // Autoplay properties

            this.autoPlayStopped = this.prefs().autoplayStopped;

            this.nextSlideTimer = null;

            this.remainingTime = null;

            this.initStructure();

            if (!this.isSlider) { return; }

            this.initPagination();
            this.goToSlide(0);

            if (this.prefs().autoplayEnabled) {
                this.initAutoplay();
            }

            this.ref('self').addClass(this.prefs().carouselInitialized);
        }

        /**
         * @description Initialize carousel structure with slides
         */
        initStructure() {
            const track = this.ref('elemCarouselTrack').get();
            let slidePrefix = this.prefs().slidePrefix;

            if (this.ref('elemCarouselTrack').data('componentId')) {
                slidePrefix = `${this.ref('elemCarouselTrack').data('componentId')}-${this.prefs().slidePrefix}`;
            }

            if (track) {
                if (track.children.length === 2) {
                    const initialContent = track.innerHTML;

                    track.innerHTML = initialContent + initialContent; // Clone slides
                }

                this.slides = track.children;

                this.slidesTotal = this.slides.length;

                for (let i = 0; i < this.slides.length; i++) {
                    const slide = this.slides[i];

                    slide.setAttribute('id', (slidePrefix + i));
                    // eslint-disable-next-line sonarjs/no-duplicate-string
                    slide.setAttribute('aria-label', ((i + 1) + ' ' + track.getAttribute('data-label-delimiter')

                        + ' ' + this.slidesTotal));
                }
            }

            this.isSlider = typeof this.slidesTotal !== 'undefined' && this.slidesTotal > 1;
        }

        /**
         * @description Executed when widget is re-rendered
        */
        onRefresh() {
            super.onRefresh();
            this.initCarousel();
        }

        /**
         * @description Changes current slide to slide with provided index
         * @param index Next slide index
         * @param actionType Type of slide action
         * @param isAutoPlay Flag if the action is called auto-play
         */
        goToSlide(index: number, actionType?: string, isAutoPlay?: boolean) {
            if (this.blockedByAnimations) { return; }

            if (this.prefs().autoplayEnabled) {
                this.endAutoplay();
            }

            const newSlideIndex = this.normalizeIndex(index);

            this.slidesModel = this.getSlidesModel(newSlideIndex);

            this.toggleAnimationMode(true);
            this.waitForTransitionEnd(() => this.toggleAnimationMode(false));

            this.applySlidesModel();
            this.setActivePagination(newSlideIndex);

            this.currentSlideIndex = newSlideIndex;

            if (actionType && isAutoPlay !== true) {
                this.onCarouselClick(actionType);
            }

            if (this.prefs().autoplayEnabled) {
                this.startAutoplay(true);

                this.isFocused = false;

                this.isHover = false;
            }
        }

        /**
         * @description Blocks the ability to change slides until the animation is complete
         * @param isAnimated - block/unblock changing current slide
         */
        toggleAnimationMode(isAnimated: boolean) {
            this.blockedByAnimations = isAnimated;
            this.ref('elemPrevButton').attr('aria-busy', isAnimated);
            this.ref('elemNextButton').attr('aria-busy', isAnimated);
        }

        /**
         * @description Assigns classes to slide elements in accordance with position of current slide
         */
        applySlidesModel() {
            if (!this.slides || !this.slidesModel) { return; }

            const allClasses = [this.prefs().slidePreviousClass, this.prefs().slideNextClass, this.prefs().slideCurrentClass];

            let n = this.slidesTotal || 0;

            while (n--) {
                const slideElement = this.slides[n];

                const slideClass = this.slidesModel[n];

                slideElement.classList.remove(...allClasses);

                if (slideClass) {
                    slideElement.classList.add(slideClass);
                }
            }
        }

        /**
         * @description Updates classes list of slide elements in accordance with updated index
         * @param index - Updated slide index
         * @returns Array with updated classes
         */
        getSlidesModel(index: number): Array<string> {
            const model = new Array(this.slidesTotal);
            const nextIndex = this.normalizeIndex(index + 1);
            const prevIndex = this.normalizeIndex(index - 1);
            const currentIndex = this.normalizeIndex(index);

            model.fill(this.prefs().slidePreviousClass, 0, currentIndex);

            model.fill(this.prefs().slideNextClass, currentIndex, this.slidesTotal);

            model[currentIndex] = this.prefs().slideCurrentClass;
            model[nextIndex] = this.prefs().slideNextClass;
            model[prevIndex] = this.prefs().slidePreviousClass;

            return model;
        }

        /**
         * @description Normalizes slide index according to looped carousel approach
         * @param index - Slide index
         * @returns Normalized slide index
         */
        normalizeIndex(index: number): number {
            let normalizedIndex = 0;

            if (this.slidesTotal) {
                if (index < 0) {
                    normalizedIndex = (this.slidesTotal - 1);
                } else {
                    normalizedIndex = index % this.slidesTotal;
                }
            }

            return normalizedIndex;
        }

        /**
         * @description Calls callback after the animation is ended
         * @listens HeroCarousel#transitionend
         * @param callback - function to be executed when the transition ends
         */
        waitForTransitionEnd(callback: () => void) {
            const onEnd = (el, event) => {
                if (event && event.propertyName !== 'transform') { return; }

                if (this.transitionFallbackTimer) {
                    this.transitionFallbackTimer();
                }

                if (this.transitionEndDisposable) {
                    this.transitionEndDisposable.forEach(disposable => disposable());

                    this.transitionEndDisposable = undefined;
                }

                callback();
            };

            this.transitionEndDisposable = this.ev('transitionend', onEnd, this.ref('elemCarouselTrack').get());

            this.transitionFallbackTimer = timeout(onEnd, 800);
        }

        /**
         * @description Initialize carousel pagination
         */
        initPagination() {
            this.has('paginationDots', paginationRefEl => {
                const pagination = paginationRefEl.get();

                if (pagination) {
                    // If empty pagination - we need to render it. Otherwise - create.
                    if (pagination.innerHTML === '') {
                        this.createPaginationElements();
                    } else {
                        this.pagination = Promise.resolve(pagination);
                    }

                    const paginationDots = this.ref('paginationDots').get();

                    if (paginationDots) {
                        this.paginationDots = paginationDots.children;
                    }
                }
            });
        }

        /**
         * @description Creates carousel pagination
         */
        createPaginationElements() {
            const elemCarouselTrack = this.ref('elemCarouselTrack').get();

            if (!elemCarouselTrack) { return; }

            const pagination = new Array(this.slidesTotal).fill(0).map((_el, i) => ({ page: i, isFirst: i === 0 }));

            this.pagination = new Promise((resolve) => {
                this.render(undefined, { pagination }, this.ref('paginationDots')).then(() => {
                    resolve(this.ref('paginationDots').get());
                    this.ref('pagination').addClass(this.prefs().carouselInitialized);
                });
            });
        }

        /**
         * @description Pagination click Event handler
         * @listens dom#click
         * @param el - source of event
         */
        handlePaginationClick(el: RefElement) {
            if (el.hasClass(this.prefs().slideCurrentClass)) { return; }

            const nextSlideIndex = el.data<number>('page') || 0;

            this.goToSlide(nextSlideIndex, 'pagination_click');
        }

        /**
         * @description Updates attributes/classes on previous and current pagination element
         * @param index - Pagination dot index
         */
        setActivePagination(index: number) {
            if (this.paginationDots && this.paginationDots.length) {
                const currentIndex = this.currentSlideIndex || 0;

                this.paginationDots[currentIndex].classList.remove(this.prefs().slideCurrentClass);

                this.paginationDots[currentIndex].removeAttribute('aria-disabled');

                this.paginationDots[index].classList.add(this.prefs().slideCurrentClass);

                this.paginationDots[index].setAttribute('aria-disabled', 'true');
            }
        }

        /**
         * @description Shows the next slide
         * @param isAutoPlay Flag if the action is called auto-play
         * @listens dom#click
         */
        goToNextSlide(isAutoPlay?: boolean) {
            if (typeof this.currentSlideIndex !== 'undefined') {
                this.goToSlide(this.currentSlideIndex + 1, 'next_slide', isAutoPlay);
            }
        }

        /**
         * @description Shows the previous slide
         * @listens dom#click
         */
        goToPrevSlide() {
            if (typeof this.currentSlideIndex !== 'undefined') {
                this.goToSlide(this.currentSlideIndex - 1, 'prev_slide');
            }
        }

        /**
         * @description Shows the next slide in PDX edit mode
         * @param _el Source of Keydown event
         * @param event event instance if DOM event
         * @listens dom#click
         */
        goToNextSlideMouseDown(_el, event) {
            // We need this to have click on Page Designer.
            // Use mousedown,touchstart since click overloaded.
            // Check that it is not triggered by mouse buttons.
            // Restricted btn: mouse right and center. True - if restricted btn was clicked
            if (event.button) {
                return;
            }

            this.goToNextSlide();
        }

        /**
         * @description Shows the previous slide in PDX edit mode
         * @param _el Source of Keydown event
         * @param event event instance if DOM event
         * @listens dom#click
         */
        goToPrevSlideMouseDown(_el, event) {
            // We need this to have click on Page Designer.
            // Use mousedown,touchstart since click overloaded.
            // Check that it is not triggered by mouse buttons.
            // Restricted btn: mouse right and center. True - if restricted btn was clicked
            if (event.button) {
                return;
            }

            this.goToPrevSlide();
        }

        /**
         * @description Collect data for send to dataLayer
         * @param actionType Type of slide action
         */
        onCarouselClick(actionType: string) {
            const elemCarouselTrack = this.ref('elemCarouselTrack').get();
            const elemSlide = elemCarouselTrack?.children[this.currentSlideIndex];
            const slideData = elemSlide?.querySelector('h1, h2, h3')?.textContent;

            this.eventBus().emit('heroCarousel.click', actionType, slideData);
        }

        /**
         * @description Adds mouse/touch listeners for swipe functionality
         * @listens HeroCarousel#touchstart
         * @param el - source of event
         */
        addTouchListeners(el: HTMLElement|undefined) {
            this.touchMoveDisposable = this.ev('touchmove', this.touchMove, el, false);

            this.mouseMoveDisposable = this.ev('mousemove', this.touchMove, el, false);

            this.mouseUpDisposable = this.ev('mouseup', this.touchEnd, el);

            this.mouseLeaveDisposable = this.ev('mouseleave', this.touchEnd, el);

            this.touchEndDisposable = this.ev('touchend', this.touchEnd, el);

            this.touchCancelDisposable = this.ev('touchcancel', this.touchEnd, el, false);
            // call context menu on slide and close it should not be treated as mousemove

            this.contextMenuDisposable = this.ev('contextmenu', this.touchEnd, el, false);
        }

        /**
         * @description Removes mouse/touch listeners for swipe functionality
         * @listens HeroCarousel#touchend
         */
        removeTouchListeners() {
            if (this.touchMoveDisposable) {
                this.touchMoveDisposable.forEach(disposable => disposable());

                this.touchMoveDisposable = undefined;
            }

            if (this.mouseMoveDisposable) {
                this.mouseMoveDisposable.forEach(disposable => disposable());

                this.mouseMoveDisposable = undefined;
            }

            if (this.mouseUpDisposable) {
                this.mouseUpDisposable.forEach(disposable => disposable());

                this.mouseUpDisposable = undefined;
            }

            if (this.mouseLeaveDisposable) {
                this.mouseLeaveDisposable.forEach(disposable => disposable());

                this.mouseLeaveDisposable = undefined;
            }

            if (this.touchEndDisposable) {
                this.touchEndDisposable.forEach(disposable => disposable());

                this.touchEndDisposable = undefined;
            }

            if (this.touchCancelDisposable) {
                this.touchCancelDisposable.forEach(disposable => disposable());

                this.touchCancelDisposable = undefined;
            }

            if (this.contextMenuDisposable) {
                this.contextMenuDisposable.forEach(disposable => disposable());

                this.contextMenuDisposable = undefined;
            }
        }

        /**
         * @description Checks if swipe functionality is initialized according to provided touches angle
         * @param x - clientX coordinate value
         * @param y - clientY coordinate value
         * @returns true if swipe is initialized
         */
        isSwipe(x: number, y: number): boolean {
            const diffX = x - this.startX;

            const diffY = y - this.startY;
            const angleDeg = (Math.atan2(Math.abs(diffY), Math.abs(diffX)) * 180) / Math.PI;

            if (!this.prefs().swipeAngle) {
                return false;
            }

            return angleDeg < +this.prefs().swipeAngle;
        }

        /**
         * @description Defines swipe properties at the start of the event.
         * Adds event listeners to check if the swipe event is true
         * @listens HeroCarousel#touchstart
         * @listens HeroCarousel#mousedown
         * @param el - source of event
         * @param event - DOM event
         */
        touchStart(el: RefElement, event: Touch | TouchEvent) {
            if (!this.isSlider) { return; }

            (event as TouchEvent).stopPropagation();

            const touch: Touch = event instanceof TouchEvent ? event.changedTouches[0] : event;

            this.startX = touch.clientX;

            this.startY = touch.clientY;

            this.isMoving = false;

            this.addTouchListeners(el.get());
        }

        /**
         * @description Shows the previous/next slide in case if the swipe event is true
         * @listens HeroCarousel#mouseup
         * @listens HeroCarousel#mouseleave
         * @listens HeroCarousel#touchend
         * @param el - source of event
         * @param event - DOM event
         */
        touchEnd(el: HTMLElement, event: TouchEvent | Touch) {
            this.removeTouchListeners();

            const touch: Touch = event instanceof TouchEvent ? event.changedTouches[0] : event;

            this.isGrabbing = false;
            this.ref('elemCarouselTrack').removeClass(this.prefs().slideGrabbingClass);

            const shiftWidth = this.startX - touch.clientX;
            const isSwipe = this.isSwipe(touch.clientX, touch.clientY)
                && Math.abs(shiftWidth) >= (+this.prefs().swipeMinShift || 0);

            if (!isSwipe) { return; }

            if (shiftWidth > 0) {
                this.goToNextSlide();
            } else {
                this.goToPrevSlide();
            }
        }

        /**
         * @description Adds a class for showing grabbing cursor, prevents blocking of site scrolling, prevents child dragging.
         * @listens HeroCarousel#touchmove
         * @listens HeroCarousel#mousemove
         * @param el - source of event
         * @param event - DOM event
         */
        touchMove(el: HTMLElement, event: Touch | TouchEvent) {
            // To prevent starting touchMove on click event

            const touch: Touch = event instanceof TouchEvent ? event.changedTouches[0] : event;

            if (!this.isMoving && (Math.abs(this.startX - touch.clientX) < parseInt(this.prefs().clickShift, 10)

                || Math.abs(this.startY - touch.clientY) < parseInt(this.prefs().clickShift, 10))) {
                return;
            } else {
                this.isMoving = true;
            }

            // To prevent child dragging and to add dragging cursor

            if (!this.isGrabbing) {
                this.isGrabbing = true;
                this.ref('elemCarouselTrack').addClass(this.prefs().slideGrabbingClass);
            }

            const originalEvent = event as TouchEvent;

            // To prevent blocking of site scrolling
            const isSwipe = this.isSwipe(touch.clientX, touch.clientY);

            if (isSwipe && originalEvent.cancelable) {
                originalEvent.preventDefault();
                originalEvent.stopPropagation();
            }
        }

        /**
         * @description Autoplay initialization
         */
        initAutoplay() {
            this.ref('autoplay').addClass(this.prefs().carouselInitialized);

            if (this.autoPlayStopped) {
                this.autoPlayPaused = true;
                this.togglePlayButtonState();

                return;
            }

            this.startAutoplay(true);
            this.addAutoplayListeners();
            this.initIntersectionObserver();
        }

        /**
         * @description Starts autoplay functionality with automatic slides changing
         * @listens dom#click
         * @param isForce - true if autoplay starts forced (go to slide with animation/timer restart)
         */
        startAutoplay(isForce?: boolean) {
            if ((!this.autoPlayPaused && !isForce) || this.autoPlayStopped) { return; }

            if (this.nextSlideTimer) {
                this.nextSlideTimer();
            }

            this.autoPlayPaused = false;

            this.ref('elemCarouselTrack').attr('aria-live', 'off');

            this.creationTime = Date.now();

            if (this.remainingTime === null) {
                this.remainingTime = parseInt(this.prefs().autoplayDuration, 10); // force to access by value
            }

            this.nextSlideTimer = timeout(this.goToNextSlide.bind(this, true), this.remainingTime);

            this.startDotAnimation();
            this.togglePlayButtonState();
        }

        /**
         * @description Pause autoplay functionality
         * @listens dom#click
         */
        pauseAutoplay() {
            if (this.autoPlayPaused) { return; }

            this.autoPlayPaused = true;
            this.ref('elemCarouselTrack').attr('aria-live', 'polite');

            if (this.nextSlideTimer) {
                this.nextSlideTimer();
            }

            if (this.remainingTime) {
                this.remainingTime -= (Date.now() - (this.creationTime || 0));
            }

            this.pauseDotAnimation();
            this.togglePlayButtonState();
        }

        /**
         * @description Disable autoplay functionality
         */
        endAutoplay() {
            if (this.nextSlideTimer) {
                this.nextSlideTimer();
            }

            this.remainingTime = null;

            this.removeDotAnimation();
        }

        /**
         * @description Stops/starts autoplay. It can be unstopped only by using this method again.
         * @listens dom#click
         */
        togglePlay() {
            if (this.autoPlayPaused) {
                this.autoPlayStopped = false;

                this.startAutoplay();
                this.onCarouselClick('unpause');
            } else {
                this.autoPlayStopped = true;
                this.pauseAutoplay();
                this.onCarouselClick('pause');
            }
        }

        /**
         * @description Toggles autoplay button attributes according to state(started/stopped).
         */
        togglePlayButtonState() {
            if (typeof this.autoPlayPaused !== 'undefined') {
                this.ref('autoplay').attr('aria-pressed', this.autoPlayPaused.toString());
            }

            if (this.autoPlayPaused) {
                this.ref('autoplay').attr('aria-label', this.prefs().autoplayLabelStart);
            } else {
                this.ref('autoplay').attr('aria-label', this.prefs().autoplayLabelStop);
            }
        }

        /**
         * @description Toggles autoplay button attributes according to state(started/stopped).
         */
        startDotAnimation() {
            const autoplayBtn = this.ref('autoplay').get();

            if (!autoplayBtn) { return; }

            autoplayBtn.style.animationDuration = `${this.prefs().autoplayDuration}ms`;
            autoplayBtn.style.animationPlayState = 'running';

            timeout(() => this.ref('autoplay').addClass(this.prefs().autoplayAnimated), 100);
        }

        /**
         * @description Pauses dot animation.
         */
        pauseDotAnimation() {
            const autoplayBtn = this.ref('autoplay').get();

            if (!autoplayBtn) { return; }

            autoplayBtn.style.animationPlayState = 'paused';
        }

        /**
         * @description Removes dot animation.
         */
        removeDotAnimation() {
            this.ref('autoplay').removeClass(this.prefs().autoplayAnimated);
        }

        /**
         * @description Adds autoplay listeners
         */
        addAutoplayListeners() {
            const track = this.ref('elemCarouselTrack').get();

            this.ev('click', this.togglePlay, this.ref('autoplay').get());
            this.ev('focusin', this.autoPlayFocusIn, track);
            this.ev('focusout', this.autoPlayFocusOut, track);
            this.ev('mouseover', this.autoPlayMouseOver, track);
            this.ev('mouseleave', this.autoPlayMouseLeave, track);
        }

        /**
         * @description Pauses the autoplay functionality when the slide is in focus.
         * @listens HeroCarousel#focusin
         */
        autoPlayFocusIn() {
            if (this.isFocused) { return; }

            this.isFocused = true;
            this.pauseAutoplay();
        }

        /**
         * @description Starts the autoplay functionality when the slide loses focus.
         * @listens HeroCarousel#focusout
         */
        autoPlayFocusOut() {
            this.isFocused = false;

            if (!this.isHover) {
                this.startAutoplay();
            }
        }

        /**
         * @description Pauses the autoplay functionality when the slide is in hover.
         * @listens HeroCarousel#mouseover
         */
        autoPlayMouseOver() {
            if (this.isHover) { return; }

            this.isHover = true;
            this.pauseAutoplay();
        }

        /**
         * @description Starts the autoplay functionality when the slide loses hover.
         * @listens HeroCarousel#mouseleave
         */
        autoPlayMouseLeave() {
            this.isHover = false;

            if (!this.isFocused) {
                this.startAutoplay();
            }
        }

        /**
         * @description Attach IntersectionObserver to carousel to pause autoplay in case if carousel is not visible
         */
        initIntersectionObserver() {
            const carousel = this.ref('self').get();

            if (!carousel) { return; }

            this.observer = new IntersectionObserver(
                ([entry]) => this.autoPlayVisible(entry.isIntersecting),
                { threshold: [0, 1] }
            );

            this.observer.observe(carousel);
        }

        /**
         * @description IntersectionObserver handler that pause/start autoplay depending of carousel visibility
         * @param isVisible do carousel in viewport
         */
        autoPlayVisible(isVisible: boolean) {
            if (this.isHover || this.isFocused) { return; }

            if (isVisible) {
                this.startAutoplay();
            } else {
                this.pauseAutoplay();
            }
        }

        /**
         * @description Destroy IntersectionObserver in case of component destroy
         */
        destroy() {
            const carousel = this.ref('self').get();

            if (this.observer && carousel) {
                this.observer.unobserve(carousel);
            }
        }
    }

    return HeroCarousel;
}

export type THeroCarousel = ReturnType<typeof HeroCarouselClassCreator>;

export type THeroCarouselInstance = InstanceType<THeroCarousel>;

export default HeroCarouselClassCreator;
