/**
 * @description Base ProcessButton implementation
 * @param Button Base widget for extending
 * @returns Process Button class
 */
function ProcessButtonClassCreator(Button: import('widgets/global/Button').TButton) {
    /**
     * @category widgets
     * @subcategory global
     * @class ProcessButton
     * @augments Button
     * @classdesc ProcessButton widget, which could be used in different contexts (html-markup, inside widgets etc).
     * <br>Could be also used to trigger parent's widget method
     * @property {string} data-widget - Widget name `processButton`
     * @property {string} data-widget-event-click - Event listener to call Parent's widget method
     * @property {string} data-event-click - Event listener method `handleClick` for click event on widget
     * @property {string} data-processing-label - Processing Label
     * @property {string} data-processing-class - Processing Class
     * @example
     * // use this code to display widget
     * <button type="submit" class="btn btn-block btn-primary"
     *     class="b-button_multi_state b-button b-product_addtocard_set-button"
     *     type="button"
     *     data-widget="processButton"
     *     data-widget-event-click="onAddAllToCart"
     *     data-event-click="handleClick"
     *     data-pid="${product.id}"
     *     data-selected-quantity="${product.selectedQuantity}"
     *     data-add-to-cart-url="${pdict.addToCartUrl}"
     *     data-status-ooo-label="${Resource.msg('addtocart.unavailable', 'product', null)}"
     *     data-processing-label="${Resource.msg('addtocart.processing', 'product', null)}"
     *     data-processing-class="m-processing"
     * >
     *     <isinclude template="/common/icons/standalone/loader" />
     *     <span data-ref="container">
     *         ... content
     *     </span>
     * </button>
     */
    class ProcessButton extends Button {
        currentText = '';

        isBusy = false;

        prefs() {
            return {
                processingLabel: 'processing...',
                processingClass: 'm-processing',
                pid: '',
                addToCartUrl: '',
                selectedQuantity: 0,
                ...super.prefs()
            };
        }

        /**
         * @description Start Progress
         * @param val - Value to set as a text of the Button
         */
        startProcess(val?: string) {
            if (this.isBusy) {
                return;
            }

            this.busy();
            this.currentText = this.getText();
            this.setText(val || this.prefs().processingLabel);
            this.ref('self').addClass(this.prefs().processingClass);
        }

        /**
         * @description Stop Progress
         * @param val - Value to set as a text of the Button
         */
        stopProcess(val?: string) {
            this.unbusy();

            const buttonText = val || this.currentText;

            if (buttonText) {
                this.setText(buttonText);
            }

            this.ref('self').removeClass(this.prefs().processingClass);
        }

        /**
         * @description Get Text
         * @returns Button Text
         */
        getText(): string {
            return this.ref('container').getText();
        }

        /**
         * @description Set Text
         * @param val - Value to set as a text of the Button
         * @returns Reference element
         */
        setText(val = '') {
            return this.ref('container').setText(val);
        }
    }

    return ProcessButton;
}

export type TProcessButton = ReturnType<typeof ProcessButtonClassCreator>;

export type TProcessButtonInstance = InstanceType<TProcessButton>;

export default ProcessButtonClassCreator;
