import { TWidget } from 'widgets/Widget';

const DATA_HOTSPOT = '[data-widget="hotSpot"]';
const DATA_HOTSPOT_BTN = '[data-ref="hotSpotBtn"]';
const ATTRIBUTE_ARIA_HIDDEN = 'aria-hidden';
const keyCode = Object.freeze({
    ESCAPE: 27,
    RETURN: 13
});

/**
 * @param Widget Base widget for extending
 * @returns HotSpot class
 */

function HotSpotClassCreator(Widget: TWidget) {
    /**
     * @category widgets
     * @subcategory plugin_page_designer_ext
     * @class HotSpot
     * @augments Widget
     * @classdesc Represents HotSpot component with feature:
     * 1. Show and hide popup
     *
     * <div class="b-hot_spot ${pdict.mobileHide}"
     *     style="${pdict.styles}"
     *     data-widget="hotSpot"
     *     data-event-keydown="handleKeydown"
     * >
     *       <button class="b-hot_spot-btn"
     *           data-ref="hotSpotBtn"
     *           data-event-click="handleHotspotInteraction"
     *           title="${Resource.msg('common.close', 'common', null)}"
     *           tabindex="0"
     *           aria-haspopup="true"
     *           aria-label="${Resource.msg('hotspot.show', 'pagedesigner', null)}"
     *       >
     *           <span class="b-hot_spot-sign"></span>
     *       </button>
     *       <div class="b-hot_spot-popup"
     *            data-ref="hotSpotPopup"
     *            tabindex="-1"
     *            aria-hidden="true"
     *       >
     *           <button
     *               class="b-hot_spot-close"
     *               title="${Resource.msg('common.close', 'common', null)}"
     *               data-event-click="closePopup"
     *               aria-label="${Resource.msg('common.close', 'common', null)}"
     *           >
     *                  <isinclude template="/common/icons/standalone/close" />
     *           </button>
     *           <div class="b-hot_spot-scrollbox">
     *              <div class="b-hot_spot-content">
     *              </div>
     *           </div>
     *       </div>
     *       <div class="b-hot_spot-overlay"  data-event-click="closePopup"></div>
     *  </div>
     * </div>
     */

    class HotSpot extends Widget {
        timeout?: ReturnType<typeof setTimeout>;

        hotSpots!: NodeListOf<HTMLElement>;

        hotSpotButtons!: Array<HTMLButtonElement>;

        prefs() {
            return {
                active: 'm-active',
                ...super.prefs()
            };
        }

        init() {
            super.init();
            this.hotSpots = document.querySelectorAll(DATA_HOTSPOT);

            this.hotSpotButtons = [];

            this.hotSpots.forEach(item => {
                const hotSpotButton: HTMLButtonElement | null = item.querySelector(DATA_HOTSPOT_BTN);

                if (hotSpotButton) {
                    this.hotSpotButtons.push(hotSpotButton);
                }
            });
        }

        /**
         * @description Set tabindex value
         * @param indexValue attribute value
         */
        toggleButtonTabindex(indexValue) {
            this.hotSpotButtons.forEach(item => {
                item.setAttribute('tabindex', indexValue);
            });
        }

        /**
         * @description Open hotspot popup
         */
        openPopup() {
            this.ref('hotSpotBtn').addClass(this.prefs().active);
            this.ref('hotSpotPopup').attr(ATTRIBUTE_ARIA_HIDDEN, 'false');
            this.toggleButtonTabindex(-1);

            if (this.timeout) {
                clearTimeout(this.timeout);
            }

            this.timeout = setTimeout(() => {
                this.ref('buttonClose').focus();
            }, 0);
        }

        /**
         * @description Close hotspot popup
         * @param _el Source of Keydown event
         * @param event event instance if DOM event
         */
        closePopup(_el, event) {
            // We need this to have click on Page Designer.
            // Use mousedown,touchstart since click overloaded.
            // Check that it is not triggered by mouse buttons.
            // Restricted btn: mouse right and center. True - if restricted btn was clicked
            if (event.button) {
                return;
            }

            this.ref('hotSpotBtn').removeClass(this.prefs().active);
            this.ref('hotSpotPopup').attr(ATTRIBUTE_ARIA_HIDDEN, 'true');
            this.ref('hotSpotBtn').focus();
            this.toggleButtonTabindex(0);
        }

        /**
         * @description Handle hotSpot click
         * @param _el Source of Keydown event
         * @param event event instance if DOM event
         */
        handleHotspotInteraction(_el, event) {
            // We need this to have click on Page Designer.
            // Use mousedown,touchstart since click overloaded.
            // Check that it is not triggered by mouse buttons.
            // Restricted btn: mouse right and center. True - if restricted btn was clicked
            if (event.button) {
                return;
            }

            const hotSpot = this.ref('self').get();
            const productTileName = (hotSpot?.querySelector('[data-product-name]') as HTMLElement)?.dataset?.productName;
            const textBlockValue = hotSpot?.querySelectorAll('h1, h2, h3')[0]?.textContent;

            this.openPopup();
            this.eventBus().emit('hotSpot.click', productTileName || textBlockValue);
        }

        /**
         * @param _el Source of Keydown event
         * @param event event instance if DOM event
         */
        handleKeydown(_el, event) {
            if (!event) {
                return;
            }

            if (event.keyCode === keyCode.ESCAPE) {
                event.preventDefault();
                event.stopPropagation();

                this.closePopup(_el, event);
            }
        }
    }

    return HotSpot;
}

export type THotSpot = ReturnType<typeof HotSpotClassCreator>;

export type THotSpotInstance = InstanceType<THotSpot>;

export default HotSpotClassCreator;
