import { TRefElementInstance } from 'widgets/toolbox/RefElement';
import { TWidget, TWidgetInstance } from 'widgets/Widget';
import { WIDGET_PROP_NAME } from 'widgets/widgetsMgr';

export type TFocusableElementInstance = TWidgetInstance & { focus: () => void } | TRefElementInstance;

/**
 * @param Widget Base widget for extending
 * @returns ListAccessibility widget
 */
function ListAccessibilityClassCreator(Widget: TWidget) {
    /**
     * @category widgets
     * @subcategory global
     * @class ListAccessibility
     * @augments Widget
     * @classdesc Represents ListAccessibility component that implement Basic Accessibility requirements for list of elements.
     * Has next features:
     * 1. Define list of focusable elements inside current widget
     * 2. Allow set focus to item or current item
     * 3. Allow set focus to first/last item
     * 4. Allow set focus to next/previous item
     *
     * Widget should be not used as standalone widget, it should be mixed to other widgets (see {@tutorial WidgetsMixinsStrategy})
     */
    class ListAccessibility extends Widget {
        focusableItems: Array<TFocusableElementInstance> = [];

        currentItem: TFocusableElementInstance | undefined;

        firstItem: TFocusableElementInstance | undefined;

        lastItem: TFocusableElementInstance | undefined;

        prefs() {
            return {
                itemLink: 'itemLink',
                ...super.prefs()
            };
        }

        /**
         * @description Define Items
         * @param currentItem Current Item
         */
        defineItems(currentItem?: TFocusableElementInstance) {
            this.focusableItems = this.getSortedItems().filter((item) => {
                return item.isShown() && 'focus' in item;
            }) as Array<TFocusableElementInstance>;

            if (this.focusableItems.length > 0) {
                if (currentItem && this.focusableItems.includes(currentItem)) {
                    this.currentItem = currentItem;
                } else {
                    this.currentItem = this.focusableItems[0];
                }

                this.firstItem = this.focusableItems[0];
                this.lastItem = this.focusableItems[this.focusableItems.length - 1];
            } else {
                this.firstItem = undefined;
                this.lastItem = undefined;
            }
        }

        /**
         * @description Set focus to item
         * @param item item
         */
        setFocusToItem(item?: TFocusableElementInstance) {
            if (item && typeof item.focus === 'function') {
                item.focus();
                this.currentItem = item;
            }
        }

        /**
         * @description Set focus to current item
         */
        setFocusToCurrentItem() {
            this.setFocusToItem(this.currentItem);
        }

        /**
         * @description Set focus to first item
         */
        setFocusToFirstItem() {
            this.setFocusToItem(this.firstItem);
        }

        /**
         * @description Set focus to last item
         */
        setFocusToLastItem() {
            this.setFocusToItem(this.lastItem);
        }

        /**
         * @description Get item index
         * @param item item element
         * @returns Item index
         */
        getItemIndex(item?: TFocusableElementInstance): number {
            return this.focusableItems && item ? this.focusableItems.indexOf(item) : -1;
        }

        /**
         * @description Set focus to next item
         */
        setFocusToNextItem() {
            if (this.focusableItems) {
                const newItem = this.currentItem === this.lastItem
                    ? this.firstItem
                    : this.focusableItems[this.getItemIndex(this.currentItem) + 1];

                this.setFocusToItem(newItem);
            }
        }

        /**
         * @description Set focus to previous item
         */
        setFocusToPreviousItem() {
            if (this.focusableItems) {
                const newItem = this.currentItem === this.firstItem
                    ? this.lastItem
                    : this.focusableItems[this.getItemIndex(this.currentItem) - 1];

                this.setFocusToItem(newItem);
            }
        }

        /**
         * @description Set Focus
         */
        focus() {
            const isItemLink = this.has(this.prefs().itemLink, (itemLink) => {
                itemLink.focus();
            });

            if (!isItemLink) {
                // Set focus to a child that has data-ref="itemLink" and data-widget attributes simultaneously
                this.eachChild(child => {
                    child.has(this.prefs().itemLink, (childItemLink) => {
                        childItemLink.focus();
                    });
                });
            }
        }

        /**
         * @description Process DOM Element and find all direct child widgets in same order as they in DOM.
         * This required for proper focus order.
         * @param element - current element to process
         * @param sortedItems - resulting array of sub widget items
         */
        findSubWidgetItems(element: HTMLElement, sortedItems: Array<TWidgetInstance>) {
            let child = element.firstElementChild as HTMLElement;

            while (child) {
                const currentElementWidget = child[WIDGET_PROP_NAME];

                if (currentElementWidget) {
                    if (this.items.includes(currentElementWidget)) {
                        sortedItems.push(currentElementWidget);
                    }
                } else {
                    this.findSubWidgetItems(child, sortedItems);
                }

                child = child.nextElementSibling as HTMLElement;
            }
        }

        /**
         * @description Get list of direct child widget in sorted order as they appear in DOM
         */
        getSortedItems() {
            const sortedItems = [] as Array<TWidgetInstance>;
            const currentElement = this.ref('self').get();

            if (currentElement) {
                this.findSubWidgetItems(currentElement, sortedItems);
            }

            return sortedItems;
        }
    }

    return ListAccessibility;
}

export type TListAccessibility = ReturnType<typeof ListAccessibilityClassCreator>;

export type TListAccessibilityInstance = InstanceType<TListAccessibility>;

export default ListAccessibilityClassCreator;
