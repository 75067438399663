import WidgetsMgr from 'widgets/widgetsMgr';
import 'widgets/widgetsList';

/*
Don't delete next comment, it's a placeholder for additional imports
See details in documentation/tools/WebpackConfiguration.md
 */

import 'core/widgetsList';

WidgetsMgr.run();

if (module.hot) {
    module.hot.accept(['widgets/widgetsList', 'core/widgetsList'], () => WidgetsMgr.restartWidgets());
}
