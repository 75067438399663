import { TWidget } from 'widgets/Widget';
import { isDOMElementFocusable } from 'widgets/toolbox/util';
import { scrollIntoView } from 'widgets/toolbox/scroll';

/**
 * @typedef {InstanceType<typeof import('widgets/toolbox/RefElement').RefElement>} RefElement
 */

/**
 * @param Widget Base widget for extending
 * @returns AccessibilityFocusMixin class
 */
function AccessibilityFocusMixinClassCreator(Widget: TWidget) {
    /**
     * @category widgets
     * @subcategory global
     * @class AccessibilityFocusMixin
     * @augments Widget
     * @classdesc Base implementation for inheritance, to be used to handle focus-related actions like getting set of focusable inputs etc.
     * Also could be used to set focus on:
     * - concrete element in widget's markup by ref element
     * - first input in widget's markup
     * - first focusable element in widget's markup
     * This class is not intended to have a separate DOM widget. It should be used as a mixin for any other target widgets.
     */
    class AccessibilityFocusMixin extends Widget {
        /**
         * @description Get keyboard-focusable elements
         * @param refName ref name to get focusable elements in, or nothing to get from `self`
         * @param types array of elements selectors to search for, or nothing for default set
         * @returns array containing focusable elements, or empty array if no elements found
         */
        getFocusableElements(refName = 'self', types: Array<string> = []): Array<HTMLElement> {
            if (!types.length) {
                types = ['a', 'button', 'input', 'textarea', 'select', 'details'];
            }

            types.push('[tabindex]:not([tabindex="-1"])');
            const selector = types.join(',');
            let result: Array<HTMLElement> = [];

            this.has(refName, refElement => {
                const htmlEl = refElement.get();

                if (htmlEl) {
                    result = Array.from(htmlEl.querySelectorAll(selector))
                        .filter(isDOMElementFocusable) as Array<HTMLElement>;
                }
            });

            return result;
        }

        /**
         * @description Get keyboard-focusable input elements
         * @param refName ref name to get focusable elements in, or nothing to get from `self`
         * @returns array containing focusable input elements, or empty array if no elements found
         */
        getFocusableInputElements(refName = 'self'): Array<Element> {
            return this.getFocusableElements(refName, ['input', 'textarea', 'select']);
        }

        /**
         * @description Focus first element found in ref
         * @param containerRefName container ref name to get focusable elements in, or nothing to get from `self`
         * @param types optional, array of elements selectors to search for, or nothing for default set
         * @param needScrollIntoView scroll into view active element after focus
         */
        focusFirst(containerRefName = 'self', types: Array<string> = [], needScrollIntoView = false) {
            const focusableElements = this.getFocusableElements(containerRefName, types);

            if (focusableElements.length) {
                focusableElements[0].focus();

                if (needScrollIntoView) {
                    scrollIntoView(focusableElements[0]); // Fix iOS not scrolled to focused element
                }
            }
        }

        /**
         * @description Method to focus on first focusable input, accepts refName as param to search input in
         * @param containerRefName container ref name to get focusable elements in, or nothing to get from `self`
         * @param needScrollIntoView scroll into view active element after focus
         */
        focusFirstInput(containerRefName = 'self', needScrollIntoView = false): void {
            const focusableInputElements = this.getFocusableInputElements(containerRefName);

            if (focusableInputElements.length) {
                (focusableInputElements[0] as HTMLElement).focus();

                if (needScrollIntoView) {
                    scrollIntoView(focusableInputElements[0] as HTMLElement); // Fix iOS not scrolled to focused element
                }
            }
        }

        /**
         * @description Method to focus on a concrete focusable element, accepts refName as param to search element
         * @param elementRefName element ref name to check if it focusable, end set focus into it
         */
        focusElement(elementRefName: string) {
            if (elementRefName) {
                this.has(elementRefName, (element) => {
                    const domNode = element.get();

                    if (domNode && isDOMElementFocusable(domNode)) {
                        domNode.focus();
                    }
                });
            }
        }
    }

    return AccessibilityFocusMixin;
}

export type TAccessibilityFocusMixin = ReturnType<typeof AccessibilityFocusMixinClassCreator>;

export type TAccessibilityFocusMixinInstance = InstanceType<TAccessibilityFocusMixin>;

export default AccessibilityFocusMixinClassCreator;
