/**
 * @module ajax
 * @category widgets
 * @subcategory toolbox
 * @description
 * Represents ajax component with next features:
 * 1. Allow to send request on form submit
 * 2. Allow to get content by URL
 * 3. Allow to get JSON response by URL
 * 4. Allow to handle response with 500 status
 * 5. Allow to set token
 *
 * @example <caption>Example of ajax module usage</caption>
 * import { submitFormJson, getContentByUrl } from 'widgets/toolbox/ajax';
 * submitFormJson(inputSelect.data('action'), {
 *  pid: inputSelect.data('pid'),
 *  uuid: uuid,
 *  quantity: inputSelect.getValue()
 * }, 'GET')
 *  .then((resp) => {
 *      this.renderCartResponse(resp);
 *      this.accessibilityAlert(this.prefs().accessibilityAlerts.quantitychanged);
 *  })
 *  .catch((error) => {
 *      if (error && !error.success && error.errorMessage) {
 *          this.render('errorTemplate', { message: error.errorMessage }, this.ref('errorMsgs'));
 *      } else {
 *          this.renderCartWithItemLevelActionError(uuid, error.message);
 *      }
 *  })
 *  .finally(() => {
 *      this.hideProgressBar();
 *  });
 */

import { showErrorLayout, appendParamsToUrl, timeout } from './util';
import eventBus from './eventBus';
import token from './token';
// eslint-disable-next-line max-len
export const errorFallbackMessage = 'For technical reasons, your request could not be handled properly at this time. We apologize for any inconvenience.';
const SAME_ORIGIN = 'same-origin';
const APPLICATION_JSON = 'application/json';
const TEXT_HTML = 'text/html';
const NO_REFERRER = 'no-referrer';
const CONTENT_TYPE = 'content-type';
const FAILED_FETCH = 'Failed to fetch';

type TLegacyServerResponse = {
    error?: boolean | string;
    message: string;
    fields: Array<TFieldError>;
};

/**
 * @description Get fetch
 * @returns Promise whose internal state matches the provided promise
 */
function getFetch(): Promise<Array<unknown>> {
    const dependencies: Array<Promise<unknown>> = [];

    if (window.fetch) {
        dependencies.push(Promise.resolve(window.fetch));
    } else {
        dependencies.push(import(/* webpackChunkName: 'fetch' */ 'whatwg-fetch'));
    }

    if (window.AbortController) {
        dependencies.push(Promise.resolve(window.AbortController));
    } else {
        dependencies.push(import(/* webpackChunkName: 'fetch' */ 'yet-another-abortcontroller-polyfill'));
    }

    return Promise.all(dependencies);
}

/**
 * @description Build valid request URL or add form data to the request
 * @param method - HTTP method
 * @param data - request data
 * @param skipToken - Checks if CSRF token should be skipped in the request
 * @param url - request url
 * @returns result
 */
function handleUrlOrFormData(
    method: string,
    data: { [keys: string]: string | number | boolean | undefined },
    skipToken: boolean,
    url: string
): { valuedUrl: string; formData: string | undefined } {
    let formData: string | undefined;

    let valuedUrl: string;

    if (method === 'POST') {
        const tokenObj = !skipToken && token.name ? { [token.name]: token.value } : {};
        const dataToSend = { ...data, ...tokenObj };

        formData = Object.keys(dataToSend).map(key => key + '=' + encodeURIComponent(<string>dataToSend[key])).join('&');

        valuedUrl = url;
    } else if (skipToken) {
        valuedUrl = appendParamsToUrl(url, data);
    } else {
        valuedUrl = appendParamsToUrl(url, { ...data, ...{ [token.name]: token.value } });
    }

    // parameter to identify ajax request
    valuedUrl = appendParamsToUrl(valuedUrl, { ajax: 'true' });

    return { valuedUrl, formData };
}

/**
 * @description Response handler from the server with 500 status
 * @param response Response object
 * @returns A Promise for the completion of which ever callback is executed.
 */
function handleResponse500(response: Response): Promise<unknown> {
    eventBus.emit('alert.error', {
        errorCode: 500
    });

    return response.text().then(textResponse => {
        let errorObj;

        try {
            errorObj = JSON.parse(textResponse);
        } catch (e) {
            errorObj = {};
        }

        if (errorObj.csrfError) {
            if (errorObj) {
                if (errorObj.csrfError && errorObj.redirectUrl) {
                    window.location.assign(errorObj.redirectUrl);
                } else {
                    showErrorLayout(errorObj);
                }
            }
        } else if (errorObj.errorMessage || errorObj.error || errorObj.message) {
            // TODO: Change the 'lib' compiler option to 'es2022' in tsconfig.json file
            // @ts-ignore TS2554: Expected 0-1 arguments, but got 2
            // currently we use only "errorMessage", but legacy code can use "error" or "message"
            return Promise.reject(new Error(errorObj.errorMessage || errorObj.message || errorObj.error, { cause: { code: 500 } }));
        } else {
            const div = document.createElement('div');

            div.innerHTML = textResponse;
            const err = Array.from(div.querySelectorAll('code')).map(code => code.innerHTML).join('<br/>');

            showErrorLayout(err);
        }

        // @ts-ignore TS2554: Expected 0-1 arguments, but got 2
        return Promise.reject(new Error('Unexpected error', { cause: { code: 500 } }));
    });
}

/**
 * @description A function, called after each success ajax call. Emits an event with given response context.
 * @emits module:ajax#responseok
 * @param response Response formatted object
 * @returns Response formatted object
 */
function handleOkResponse(response: Promise<unknown>): Promise<unknown> {
    /**
     * @description Event about success ajax server response
     * @event module:ajax#responseok
     */
    timeout(() => eventBus.emit('responseok', response), 0);

    return response;
}

/**
 * @description Prepares general params for the fetch request
 * @returns result
 */
function prepareRequestData(): Record<string, unknown> {
    return {
        mode: SAME_ORIGIN, // no-cors, cors, *same-origin
        cache: 'default', // *default, no-cache, reload, force-cache, only-if-cached
        redirect: 'follow' // manual, *follow, error
    };
}

/**
 * @description Prepares general header params
 * @returns result
 */
function prepareHeaders(): Record<string, string> {
    const locale = document.documentElement?.dataset?.locale || '';
    const siteId = document.documentElement?.dataset?.siteid || '';

    return {
        'x-sf-cc-siteid': siteId,
        'x-sf-cc-requestlocale': locale,
        'x-requested-with': 'XMLHttpRequest'
    };
}

/**
 * @description Adapter function to handle legacy response error properties' names and convert it to the new ones
 * @param response - Promise that returns server response
 * @returns result
 */
function cleanupServerResponse(response: Promise<TServerResponse & TLegacyServerResponse>): Promise<TServerResponse> {
    return response.then(res => {
        const errors = res.fieldErrors || res.fields;

        let success = res.success ?? res.error !== true;

        if (errors && Object.keys(errors).length > 0) {
            success = false;
        }

        res.success = success;

        let errorMessage = res.errorMessage || ((typeof res.error === 'string') ? res.error : null);

        if (!success && !errorMessage) {
            errorMessage = res.message;
        }

        const fieldErrors = Array.isArray(errors)
            // convert [{field1: error1}, {field2: error2}] to {field1: error1, field2: error2}
            ? errors.reduce((acc, el) => ({ ...acc, ...el }), {})
            : errors;

        if (errorMessage) {
            res.errorMessage = String(Array.isArray(errorMessage) ? errorMessage.join('<br>') : errorMessage);
        }

        if (fieldErrors) {
            res.fieldErrors = fieldErrors;
        }

        return res;
    });
}

/**
 * @description Handles response by type and status
 * @param response - response data
 * @param type - content type to handle
 * @returns result
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function handleResponse(response: Response, type: string = APPLICATION_JSON): Promise<any> {
    const contentType = response.headers.get(CONTENT_TYPE);

    if (response.ok) {
        if (contentType && contentType.includes(APPLICATION_JSON) && (type === APPLICATION_JSON)) {
            return handleOkResponse(cleanupServerResponse(response.json()));
        }

        if (contentType && contentType.includes(TEXT_HTML) && (type === TEXT_HTML)) {
            return handleOkResponse(response.text());
        }

        if (type !== TEXT_HTML || response.status !== 204) {
            showErrorLayout(errorFallbackMessage);
            throw new TypeError(errorFallbackMessage);
        }
    } else if (response.status === 401) {
        window.location.reload();

        return Promise.reject(new Error('Unauthorized error'));
    } else if (response.status === 404) {
        showErrorLayout(errorFallbackMessage);
        throw new TypeError(errorFallbackMessage);
    } else if (response.status === 500) {
        return handleResponse500(response);
    }

    return response.json().then(errorJson => {
        return Promise.reject(errorJson);
    });
}

export type TFailedRequestResponse = {
    errorMessage: string;
    errorCode: number;
    success: false;
}

/**
 * @description Handle errors when response failed for some reason
 * @param error - error object
 * @returns result
 */
function handleRequestError(error: Error | TServerResponse): TFailedRequestResponse {
    let message = '';

    switch (error.message) {
        case FAILED_FETCH:
            message = errorFallbackMessage;
            break;
        default:
            message = 'errorMessage' in error ? error.errorMessage : error.message as string || '';
            break;
    }

    return {
        success: false,
        errorMessage: message,
        // @ts-ignore TS2550: Property 'cause' does not exist on type 'Error'
        errorCode: error?.cause?.code
    };
}

/**
 * @description Form submission handler
 * @param url url of resource
 * @param [data] form content
 * @param  [method] typeof request
 * @param [skipToken] skip token for request
 * @returns Fetching result promise
 */
export const submitFormJson = (
    url: string | undefined,
    data: { [x: string]: string | number | boolean | undefined } = {},
    method: 'POST' | 'GET' | 'PATCH' | 'DELETE' = 'POST',
    skipToken = false
) => {
    if (url === undefined) {
        return Promise.reject();
    }

    return getFetch().then(() => {
        const { valuedUrl, formData } = handleUrlOrFormData(method, data, skipToken, url);
        /**
         * This magic is mandatory for MS Edge because fetch polyfill is returning not polyfilled Promise object
         */

        return Promise.resolve(fetch(valuedUrl, {
            ...prepareRequestData(),
            method: method, // *GET, POST, PUT, DELETE, etc.
            credentials: SAME_ORIGIN, // include, *same-origin, omit
            headers: {
                ...prepareHeaders(),
                'Content-Type': 'application/x-www-form-urlencoded',
                Accept: APPLICATION_JSON
            },
            referrer: NO_REFERRER, // no-referrer, *client
            body: formData // body data type must match "Content-Type" header
        })).then(handleResponse).catch(handleRequestError);
    });
};

/**
 * @description Submit data via POST method
 * @param url url of resource
 * @param [data] content
 * @param [skipToken] skip token for request
 * @returns Fetching result promise
 */
export const postJsonData = (
    url: string,
    data: { [x: string]: string | Record<string, unknown> } = {},
    skipToken = false
) => {
    return getFetch().then(() => {
        const tokenObj = !skipToken && token.name ? { [token.name]: token.value } : {};
        const dataToSend = { ...data, ...tokenObj };

        /**
         * This magic is mandatory for MS Edge because fetch polyfill is returning not polyfilled Promise object
         */
        return Promise.resolve(fetch(appendParamsToUrl(url, { ajax: 'true' }), {
            ...prepareRequestData(),
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            credentials: SAME_ORIGIN, // include, *same-origin, omit
            headers: {
                ...prepareHeaders(),
                'Content-Type': APPLICATION_JSON,
                Accept: APPLICATION_JSON
            },
            referrer: NO_REFERRER, // no-referrer, *client
            body: JSON.stringify(dataToSend) // body data type must match "Content-Type" header
        })).then(handleResponse).catch(handleRequestError);
    });
};

/**
 * @description Get content by URL
 * @param url URL to get data
 * @param params optional params to url
 * @returns Fetching result promise
 */
export function getContentByUrl(url: string | undefined, params: { [keys: string]: string | undefined } = {}): Promise<string> {
    if (url === undefined) {
        return Promise.reject();
    }

    params.ajax = 'true';

    /**
     * This magic is mandatory for MS Edge because fetch polyfill is returning not polyfilled Promise object
     */
    return Promise.resolve(fetch(appendParamsToUrl(url, params), {
        method: 'GET', // *GET, POST, PUT, DELETE, etc.
        ...prepareRequestData(),
        credentials: SAME_ORIGIN, // include, *same-origin, omit
        headers: {
            ...prepareHeaders(),
            Accept: 'text/html'
        },
        referrer: NO_REFERRER // no-referrer, *client
    })).then((response) => {
        return handleResponse(response, TEXT_HTML);
    });
}

/**
 * @description Get JSON response from the server by URL
 * @param url URL to get data
 * @param [params] optional params to url
 * @param [skipToken] skip token for request
 * @returns Fetching result promise
 */
export function getJSONByUrl(
    url: string | undefined,
    params: { [keys: string]: string | undefined } = {},
    skipToken = true
) {
    return submitFormJson(url, params, 'GET', skipToken);
}

type TAbstractControlledRequest<Response> = {
    promise: Promise<Response>;
    abortController: AbortController;
};

export type TSuccessControlledRequest<Response> = TAbstractControlledRequest<Response & { success: true }>;
export type TFailedControlledRequest<Response> = TAbstractControlledRequest<Response & TFailedRequestResponse>;
export type TAbortedRequest = TAbstractControlledRequest<null>;

const handleControlledRequestError = (error: Error): null | TFailedRequestResponse => {
    if (error.name === 'AbortError') {
        return null;
    }

    return handleRequestError(error);
};

export type TControlledRequest<SuccessResponse, UnsuccessResponse = unknown> = TSuccessControlledRequest<SuccessResponse>
    | TFailedControlledRequest<UnsuccessResponse>
    | TAbortedRequest;

/**
 * @description Form submission handler with possibility to abort request
 * @param url url of resource
 * @param [data] form content
 * @param [method] typeof request
 * @param [skipToken] skip token for request
 * @returns An object contains the Fetching result promise and AbortController
 */
export function submitFormJsonWithAbort<SuccessResponse = unknown, UnsuccessResponse = unknown>(
    url: string | undefined,
    data: { [x: string]: string | undefined } = {},
    method: 'POST' | 'GET' = 'POST',
    skipToken = false
): TControlledRequest<SuccessResponse, UnsuccessResponse> {
    const abortController = new AbortController();
    const signal = abortController.signal;

    const result = {
        abortController,
        promise: getFetch().then(() => {
            if (url === undefined) {
                return Promise.reject();
            }

            const { valuedUrl, formData } = handleUrlOrFormData(method, data, skipToken, url);

            /**
             * This magic is mandatory for MS Edge because fetch polyfill is returning not polyfilled Promise object
             */
            return Promise.resolve(fetch(valuedUrl, {
                ...prepareRequestData(),
                method: method, // *GET, POST, PUT, DELETE, etc.
                credentials: SAME_ORIGIN, // include, *same-origin, omit
                headers: {
                    ...prepareHeaders(),
                    'Content-Type': 'application/x-www-form-urlencoded',
                    Accept: APPLICATION_JSON
                },
                referrer: NO_REFERRER, // no-referrer, *client
                body: formData, // body data type must match "Content-Type" header
                signal: signal // can be used to communicate with/abort a DOM request
            })).then(handleResponse).catch(handleControlledRequestError);
        })
    };

    return result;
}
