/**
 * @module dom
 * @category widgets
 * @subcategory toolbox
 * @description Represents the component with next features:
 * 1. Modify string HTML markup, removing certain wrappers by their class names
 *
 * @example <caption>Example of dom module usage</caption>
 * import { unwrapElementsByClasses } from 'widgets/toolbox/dom';
 *
 * const someServerRespone = `
 *     <div class="container">
 *         <h1>Response</h1>
 *         <p>Lorem</p>
 *     </div>
 * `;
 *
 * const handleResponse = (htmlMarkup) => {
 *    return unwrapElementsByClasses(htmlMarkup, 'container');
 * }
 *
 * const processedResponse = handleResponse(someServerRespone);
 *
 * The result is:  `<h1>Response</h1>
 *                  <p>Lorem</p>`
 */

/**
 * @description Removes wrapper block while keeping inner markup
 * @param wrapper Element containing other html elements
 */
function unwrap(wrapper: Element) {
    wrapper.outerHTML = wrapper.innerHTML;
}

/**
 * @description Removes wrapper blocks with passed class names while keeping inner markup
 * @param stringDOM HTML markup containing wrapper elements
 * @param classNames target classes which blocks should be unwrapped
 * @returns new string with unwrapped elements
 */
export function unwrapElementsByClasses(stringDOM: string, ...classNames: Array<string>) {
    const DOM = new DOMParser().parseFromString(stringDOM, 'text/html');

    for (let i = 0; i < classNames.length; i++) {
        const node = DOM.querySelector('.' + classNames[i]);

        if (node) {
            unwrap(node);
        }
    }

    return DOM.body.innerHTML;
}

/**
 * @description Checks if html element is something like input (select, textarea, etc)
 *
 * @param el html element to check
 * @returns check result
 */
export function isInputLike(el: HTMLElement): el is HTMLInputElement {
    return el instanceof HTMLInputElement
        || el instanceof HTMLSelectElement
        || el instanceof HTMLOptionElement
        || el instanceof HTMLTextAreaElement;
}
