/**
 * @param BasicForm Base widget for extending
 * @returns Form class
 */
function FormClassCreator(BasicForm: import('widgets/forms/BasicForm').TBasicForm) {
    /**
     * @category widgets
     * @subcategory forms
     * @class Form
     * @augments BasicForm
     * @classdesc Represents Form component
     * @example <caption>Example of BasicForm widget usage when Submit Button have assigned widget</caption>
     * <form
     *     action="${URLUtils.url('Order-Track')}"
     *     method="GET"
     *     data-widget="form"
     *     data-event-submit="handleSubmit"
     * >
     *      ... form contents
     *    <button
     *       data-widget="button"
     *       data-id="submitButton"
     *       type="submit"
     *       data-forward-to-parent="handleSubmit"
     *       data-event-click="handleSubmit"
     *    >
     *       ...Button name
     *    </button>
     * </form>
     * @example <caption>Example of BasicForm widget usage when submit button NOT have assigned widget</caption>
     * <form
     *     action="${URLUtils.url('Order-Track')}"
     *     method="GET"
     *     data-widget="form"
     *     data-event-submit="handleSubmit"
     * >
     *      ... form contents
     *    <button
     *       data-ref="submitButton"
     *       type="submit"
     *       data-event-click="handleSubmit"
     *    >
     *       ...Button name
     *    </button>
     * </form>
     * @property {string} data-widget - Widget name `form`
     * @property {string} data-event-submit - Event listener for form submission
     */
    class Form extends BasicForm {

    }

    return Form;
}

export type TForm = ReturnType<typeof FormClassCreator>;

export type TFormInstance = InstanceType<TForm>;

export default FormClassCreator;
