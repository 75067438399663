import { TInputTextInstance } from 'widgets/forms/InputText';
import { TBasicInputInstance } from 'widgets/forms/BasicInput';

/**
 * @param AjaxForm Base widget for extending
 * @returns EmailSubscribe widget
 */
function EmailSubscribeClassCreator(AjaxForm: import('widgets/forms/AjaxForm').TAjaxForm) {
    /**
     * @category widgets
     * @subcategory forms/ajax
     * @class EmailSubscribe
     * @augments AjaxForm
     * @classdesc Serves email subscription form in footer.
     * The main purpose EmailSubscribe widget is to allow send/validate the request to server and handles server response
     * @property {string} data-widget - Widget name `emailSubscribe`
     * @property {string} data-event-submit - Event listener for form submission
     *
     * @example <caption>Example of EmailSubscribe widget usage</caption>
     * <form
     *     class="b-newsletters"
     *     aria-label="${Resource.msg('subscribe.label', 'global', null)}"
     *     name="subscribe-form"
     *     action="${URLUtils.url('EmailSubscribe-Subscribe')}"
     *     method="POST"
     *     data-signup-email="${emailsubscribeForm.email.htmlName}"
     *     data-accessibility-announcement-msg="${Resource.msg('subscribe.alert', 'global', null)}"
     *     data-widget="emailSubscribe"
     *     data-event-submit.prevent="handleSubmit"
     * >
     *     <div class="b-newsletters-caption">
     *         <iscontentasset aid="footer-newsletters" local="${true}" />
     *     </div>
     *     <div
     *         class="b-newsletters-group"
     *         data-ref="formContent"
     *         data-tau="newsletters_group"
     *     >
     *         ... form fields
     *     </div>
     *     <div
     *         class="b-newsletters-message_success"
     *         data-ref="successMessage"
     *         hidden="hidden"
     *         data-tau="newsletters_message_success"
     *     ></div>
     * </form>
     */
    class EmailSubscribe extends AjaxForm {
        prefs() {
            return {
                signupEmail: 'dwfrm_emailsubscribe_email',
                formContent: 'formContent',
                successBlock: 'successBlock',
                successMessage: 'successMessage',
                agreeToPrivacy: 'agreeToPrivacy',
                accessibilityAnnouncementMsg: '',
                ...super.prefs()
            };
        }

        /**
         * @description Handles email input
         * @param el event source element
         */
        onEmailInput(el: TInputTextInstance) {
            const entered = el && el.getValue();

            if (entered && entered.length) {
                this.showAgreeToPrivacy();
            }
        }

        /**
         * @description Show agree to privacy block
         */
        showAgreeToPrivacy() {
            this.ref('agreeToPrivacy').show();
        }

        /**
         * @description Handles server response
         * @emits "alert.show"
         * @param data Server JSON response once form submitted
         */
        onSubmitted(data: TServerResponse) {
            if (data.success) {
                this.ref('formContent').hide();
                this.ref('successMessage')
                    .setText(<string>data.msg)
                    .show();
                /**
                 * @description Global event to show alert
                 * @event "alert.show"
                 */
                this.eventBus().emit('alert.show', {
                    accessibilityAlert: this.prefs().accessibilityAnnouncementMsg
                });
            }

            if (!data.success) {
                if (data.fieldErrors) {
                    Object.entries(data.fieldErrors).forEach(([name, errorMsg]) => {
                        this.getById(name, (formField: TBasicInputInstance) => formField.setError(errorMsg));
                    });
                }

                if (data.errorMessage) {
                    // Show only Accessibility Alert in case of 500 error
                    if (data.errorCode !== 500) {
                        this.getById(this.prefs().signupEmail, (inputEmail: TBasicInputInstance) => inputEmail.setError(data.errorMessage));
                    }
                }
            }
        }
    }

    return EmailSubscribe;
}

export type TEmailSubscribe = ReturnType<typeof EmailSubscribeClassCreator>;

export type TEmailSubscribeInstance = InstanceType<TEmailSubscribe>;

export default EmailSubscribeClassCreator;
