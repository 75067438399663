/**
 * @module cookie
 * @category widgets
 * @subcategory toolbox
 * @description Represents cookie component with next features:
 * 1. Allow to set/get cookie
 *
 * @example <caption>Example of cookie module usage</caption>
 * import { setCookie } from '../toolbox/cookie';
 * setCookie('dw_accept_cookie', '1', 365, true);
 */

/**
 * @description Uses to set cookies to the document
 * @param cookieName - cookie name
 * @param cookieValue - cookie value
 * @param expireInDays - number of days before cookie expiration. 0 - end of a session, 365 - default value
 * @param isSecure - adds secure cookie if true
 */
export function setCookie(cookieName: string, cookieValue: string, expireInDays = 365, isSecure = false) {
    const d = new Date();

    d.setTime(d.getTime() + (expireInDays * 24 * 60 * 60 * 1000));
    const expires = expireInDays === 0 ? '' : `expires=${d.toUTCString()}`;

    document.cookie = `${cookieName}=${cookieValue};${expires};path=/${isSecure ? ';secure' : ''}`;
}

/**
 * @description Gets cookie value from document.Empty string is returned by default
 * @param cookieName - cookie name
 * @returns cookie value
 */
export function getCookie(cookieName: string): string {
    const name = `${cookieName}=`;
    const ca = document.cookie.split(';');

    for (let i = 0; i < ca.length; i += 1) {
        let c = ca[i];

        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }

        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }

    return '';
}
