/* eslint-disable max-len, indent, sonarjs/no-duplicate-string */
/* sonar-ignore-start */

import widgetsMgr from 'widgets/widgetsMgr';
import ContextLoader from 'widgets/global/ContextLoader';
import ContentLoader from 'widgets/global/ContentLoader';

import Tabs from 'widgets/global/Tabs';
import TabPanel from 'widgets/global/TabPanel';
import Accordion from 'widgets/global/Accordion';
import AccordionItem from 'widgets/global/AccordionItem';
import Label from 'widgets/global/Label';
import Modal from 'widgets/global/Modal';
import GlobalModal from 'widgets/global/GlobalModal';
import ConfirmDialog from 'widgets/global/ConfirmDialog';
import GlobalAlerts from 'widgets/global/GlobalAlerts';
import Carousel from 'widgets/global/Carousel';
import BackToTop from 'widgets/global/BackToTop';
import EmitBusEvent from 'widgets/global/EmitBusEvent';
import StickyHeader from 'widgets/global/StickyHeader';
import Header from 'widgets/global/Header';
import ConnectionMonitor from 'widgets/global/ConnectionMonitor';

import ListAccessibility from 'widgets/global/ListAccessibility';
import AccessibilityFocusTrapMixin from 'widgets/global/AccessibilityFocusTrapMixin';
import AccessibilityFocusMixin from 'widgets/global/AccessibilityFocusMixin';
import SoftKeyboardToggleMixin from 'widgets/global/SoftKeyboardToggleMixin';
import FocusHighlighter from 'widgets/global/FocusHighlighter';
import Disclosure from 'widgets/global/Disclosure';

import Form from 'widgets/forms/Form';
import BasicForm from 'widgets/forms/BasicForm';
import AjaxForm from 'widgets/forms/AjaxForm';
import BasicInput from 'widgets/forms/BasicInput';
import InputEmail from 'widgets/forms/InputEmail';
import InputPassword from 'widgets/forms/InputPassword';
import InputCheckbox from 'widgets/forms/InputCheckbox';
import InputText from 'widgets/forms/InputText';
import InputTextarea from 'widgets/forms/InputTextarea';
import InputHidden from 'widgets/forms/InputHidden';
import InputSelect from 'widgets/forms/InputSelect';
import InputRadio from 'widgets/forms/InputRadio';
import Button from 'widgets/global/Button';
import ButtonWithIcon from 'widgets/global/ButtonWithIcon';
import ProcessButton from 'widgets/global/ProcessButton';

import DismissBlock from 'widgets/global/DismissBlock';
import ConsentTracking from 'widgets/global/ConsentTracking';
import Geolocation from 'widgets/global/Geolocation';
import EmailSubscribe from 'widgets/forms/ajax/EmailSubscribe';
import Combobox from 'widgets/forms/Combobox';
import SearchBox from 'widgets/search/SearchBox';
import ProductTile from 'widgets/product/ProductTile';
import AlternativeImage from 'widgets/product/AlternativeImage';

import CarouselExt from 'widgets/pagedesigner/CarouselExt';
import VideoExt from 'widgets/pagedesigner/VideoExt';
import AnimationOnScroll from 'widgets/pagedesigner/AnimationOnScroll';
import CtaExt from 'widgets/pagedesigner/CtaExt';
import HotSpot from 'widgets/pagedesigner/HotSpot';
import CarouselSimple from 'widgets/pagedesigner/CarouselSimple';
import ProgressBar from 'widgets/pagedesigner/ProgressBar';
import EinsteinCarouselLoader from 'widgets/pagedesigner/EinsteinCarouselLoader';
import HeroCarousel from 'widgets/global/HeroCarousel';

widgetsMgr.addWidgetsList('global.widgets', () => [
    ['contextLoader', ContextLoader],
    ['contentLoader', ContentLoader],
    ['label', Label],
    ['carousel', Carousel],
    ['button', Button],
        ['buttonWithIcon', ButtonWithIcon, 'button'],
        ['processButton', ProcessButton, 'button'],
    ['listAccessibility', ListAccessibility],
        ['accordion', Accordion, 'listAccessibility'],
    ['accordionItem', AccordionItem],
    ['dismissBlock', DismissBlock],
    ['backtotop', BackToTop],
    ['consentTracking', ConsentTracking],
    ['geolocation', Geolocation],
    ['emitBusEvent', EmitBusEvent],
    ['focusHighlighter', FocusHighlighter],
    ['stickyHeader', StickyHeader],
    ['header', Header, 'contextLoader'],
    ['basicForm', BasicForm],
        ['form', Form, 'basicForm'],
            ['ajaxform', AjaxForm, 'basicForm'],
                ['emailSubscribe', EmailSubscribe, 'ajaxform'],
    ['basicInput', BasicInput],
        ['inputPassword', InputPassword, 'basicInput'],
        ['inputCheckbox', InputCheckbox, 'basicInput'],
        ['inputSelect', InputSelect, 'basicInput'],
        ['inputText', InputText, 'basicInput'],
            ['inputEmail', InputEmail, 'inputText'],
            ['inputTextarea', InputTextarea, 'inputText'],
        ['inputRadio', InputRadio, 'basicInput'],
        ['inputHidden', InputHidden, 'basicInput'],
    ['modal', Modal],
        ['modal', AccessibilityFocusTrapMixin, 'modal'],
        ['globalModal', GlobalModal, 'modal'],
        ['confirmDialog', ConfirmDialog, 'modal'],
    ['combobox', Combobox],
        ['searchBox', SearchBox, 'combobox'],
            ['searchBox', AccessibilityFocusTrapMixin, 'searchBox'],
            ['searchBox', SoftKeyboardToggleMixin, 'searchBox'],
            ['searchBox', Modal, 'searchBox'],
    ['tabs', Tabs],
    ['tabPanel', TabPanel],
    ['productTile', ProductTile],
        ['productTile', AlternativeImage, 'productTile'],
    ['disclosure', Disclosure],
        ['disclosure', AccessibilityFocusMixin, 'disclosure'],
    ['globalAlerts', GlobalAlerts],
    ['connectionMonitor', ConnectionMonitor]
]);

widgetsMgr.addWidgetsList('page_designer.global.core', () => [
    ['carouselExt', CarouselExt, 'carousel'],
    ['videoExt', VideoExt],
    ['animationOnScroll', AnimationOnScroll],
    ['ctaExt', CtaExt],
    ['hotSpot', HotSpot],
    ['carouselSimple', CarouselSimple, 'carousel'],
    ['progressBar', ProgressBar],
    ['einsteinCarouselLoader', EinsteinCarouselLoader],
    ['heroCarousel', HeroCarousel]
]);

/* sonar-ignore-end */
