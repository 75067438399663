import { TWidget } from 'widgets/Widget';
import { getContentByUrl } from 'widgets/toolbox/ajax';
import { appendParamsToUrl } from 'widgets/toolbox/util';
const EINSTEIN_VERSION = '1.02';

declare type TRecommendationParams = {
    userId: string | unknown;
    cookieId: string | unknown;
    emailId: string | unknown;
    loginId: string | unknown;
    ccver: string | unknown;
    anchors?: object;
}

declare type TCQuotient = {
    clientId: string;
    getCQUserId: () => Record<string, unknown>;
    getCQCookieId: () => Record<string, unknown>;
    getRecs: (clientId: string, recommender: string, RecsParams, callback: (recommenders) => void) => Record<string, unknown>;
    getCQHashedEmail: () => Record<string, unknown>;
    getCQHashedLogin: () => Record<string, unknown>;
    widgets: Array<object>;
}

declare global {
    interface Window {
        CQuotient: TCQuotient;
    }
}

type TAnchorObject = {
    id: string;
    sku?: string;
    type?: string;
    // eslint-disable-next-line camelcase
    alt_id?: string;
};

/**
 * @param Widget Base widget for extending
 * @returns EinsteinCarouselLoader widget
 */
function EinsteinCarouselLoaderClassCreator(Widget: TWidget) {
    /**
     * @category widgets
     * @subcategory plugin_page_designer
     * @class EinsteinCarouselLoader
     * @augments Widget
     * @classdesc EinsteinCarouselLoader Widget - used to load recommendation data from Einstein engine
     * and pass data to backend for carousel markup rendering
     * @property {string} data-title - Carousel title
     * @property {string} data-recommender - Einstein recommender to use
     * @property {string} data-category-id - category id if need show recommendations in context of category
     * @property {string} data-primary-product-id - product id if need show recommendations in context of product.
     * For variations or variations groups it is ID of master product
     * @property {string} data-secondary-product-id - if context product is variations or variations groups it is their id, otherwise empty
     * @property {string} data-alternative-group-type - if product has types 'set', 'bundle' or 'vgroup' then it has same value, otherwise empty
     * @property {string} data-alternative-group-id - product id if product has type 'set', 'bundle' or 'vgroup'
     * @property {object} data-tile-params - tile rendering parameters
     * @property {string} data-load-url - URL to get rendered carousel markup
     * @example <caption>Example of EinsteinCarouselLoader widget usage</caption>
     * <section
     *     data-widget="einsteinCarouselLoader"
     *     data-title="<isprint value="${pdict.title}" encoding="htmldoublequote"/>"
     *     data-recommender="${pdict.recommender}"
     *     data-load-url="${pdict.productLoadUrl}"
     *     data-primary-product-id="${pdict.primaryProductId}"
     *     data-secondary-product-id="${pdict.secondaryProductId}"
     *     data-alternative-group-type="${pdict.alternativeGroupType}"
     *     data-alternative-group-id="${pdict.alternativeGroupId}"
     *     data-category-id="${pdict.categoryId}"
     *     data-tile-params="<isprint value="${JSON.stringify(pdict.tileParams)}" encoding="htmldoublequote"/>"
     * ></section>
     */
    class EinsteinCarouselLoader extends Widget {
        prefs() {
            return {
                title: '',
                recommender: '',
                categoryId: '',
                primaryProductId: '',
                secondaryProductId: '',
                alternativeGroupType: '',
                alternativeGroupId: '',
                tileParams: {},
                loadUrl: '',
                ...super.prefs()
            };
        }

        init() {
            super.init();
            this.loadEinsteinRecommendations();
        }

        /**
         * @description Build request to Einstein engine and submit it to get recommenders object
         */
        loadEinsteinRecommendations() {
            const einsteinUtils = this.getEinsteinUtils();

            if (!einsteinUtils) {
                return;
            }

            const params: TRecommendationParams = {
                userId: einsteinUtils.getCQUserId(),
                cookieId: einsteinUtils.getCQCookieId(),
                emailId: einsteinUtils.getCQHashedEmail(),
                loginId: einsteinUtils.getCQHashedLogin(),
                ccver: EINSTEIN_VERSION
            };
            const anchors = this.createAnchor();

            if (anchors) {
                params.anchors = anchors;
            }

            if (einsteinUtils.getRecs) {
                einsteinUtils.getRecs(
                    einsteinUtils.clientId,
                    this.prefs().recommender,
                    params,
                    (recommenders) => {
                        this.loadCarousel(recommenders);
                    }
                );
            } else {
                // If SFCC Analytics is not inited at time of this code execution
                // we can put our params into widgets array that will be processed
                // on SFCC Analytic init
                einsteinUtils.widgets = einsteinUtils.widgets || []; // eslint-disable-line no-param-reassign
                einsteinUtils.widgets.push({
                    recommenderName: this.prefs().recommender,
                    parameters: params,
                    callback: (recommenders) => {
                        this.loadCarousel(recommenders);
                    }
                });
            }
        }

        /**
         * @description Create anchor parameter object to be passed to Einstein engine.
         * If category is specified then it will be used as anchor
         * Otherwise will be used product, or undefined for global recommendations
         * @returns anchor object
         */
        createAnchor(): Array<TAnchorObject> | undefined {
            if (this.prefs().categoryId) {
                return [{ id: this.prefs().categoryId }];
            }

            if (this.prefs().primaryProductId) {
                return [{
                    id: this.prefs().primaryProductId,
                    sku: this.prefs().secondaryProductId,
                    type: this.prefs().alternativeGroupType,
                    alt_id: this.prefs().alternativeGroupId
                }];
            }

            return undefined;
        }

        /**
         * @description Validates and Return the CQuotient namespace provided by the commerce cloud platform
         */
        getEinsteinUtils(): TCQuotient | null {
            const einsteinUtils = window.CQuotient;

            if (einsteinUtils
                && (typeof einsteinUtils.getCQUserId === 'function')
                && (typeof einsteinUtils.getCQCookieId === 'function')
                && (typeof einsteinUtils.getCQHashedEmail === 'function')
                && (typeof einsteinUtils.getCQHashedLogin === 'function')) {
                return einsteinUtils;
            }

            return null;
        }

        /**
         * @description Based on recommenders response build URL and load recommendation carousel markup
         * @param recommenders object received from Einstein engine
         * @returns Promise object represents server response with carousel markup
         */
        loadCarousel(recommenders): Promise<string|null> {
            if (!recommenders || !recommenders[this.prefs().recommender]) {
                return Promise.resolve(null);
            }

            const recommendations = recommenders[this.prefs().recommender].recs || [];

            if (recommendations.length === 0) {
                // If recommendations are empty not make sense execute request
                return Promise.resolve(null);
            }

            const pids = recommendations.map((rec) => rec.id);
            const url = appendParamsToUrl(this.prefs().loadUrl, {
                pids: JSON.stringify(pids),
                tileParams: JSON.stringify(this.prefs().tileParams),
                title: this.prefs().title
            });

            return getContentByUrl(url).then((response) => {
                return this.render(undefined, undefined, this.ref('self'), response.toString()).then(() => response);
            });
        }
    }

    return EinsteinCarouselLoader;
}

export type TEinsteinCarouselLoader = ReturnType<typeof EinsteinCarouselLoaderClassCreator>;

export type TEinsteinCarouselLoaderInstance = InstanceType<TEinsteinCarouselLoader>;

export default EinsteinCarouselLoaderClassCreator;
