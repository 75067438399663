/**
 * @category widgets
 * @subcategory toolbox
 * @module token
 * @description Module represents functionality to get/set token name and value
 *
 * @example <caption>Example of token updating from some module</caption>
 * import token from 'widgets/toolbox/token';
 * ...
 * return submitFormJson(this.prefs().sessionContinueUrl, {}).then((response) => {
 *     token.name = response.csrf.tokenName;
 *     token.value = response.csrf.token;
 *     this.closeModal();
 *     this.countdownShown = false;
 * });
 * ...
 */
export default {
    tokenName: '',

    tokenValue: '',

    /**
     * @description Token name
     */
    get name(): string {
        if (!this.tokenName) {
            this.tokenName = window.csrfToken?.name || '';
        }

        return this.tokenName;
    },

    set name(value: string) {
        this.tokenName = value;
    },

    /**
     * @description Token value
     */
    get value(): string {
        if (!this.tokenValue) {
            this.tokenValue = window.csrfToken?.value || '';
        }

        return this.tokenValue;
    },

    set value(value: string) {
        this.tokenValue = value;
    }
};
