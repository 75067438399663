import { TWidget } from 'widgets/Widget';

/**
 * @param Widget Base widget for extending
 * @returns ConnectionMonitor class
 */
function ConnectionMonitorClassCreator(Widget: TWidget) {
    /**
     * @category widgets
     * @subcategory global
     * @class ConnectionMonitor
     * @augments Widget
     * @classdesc Connection monitor component to notify the user about the loss of connection
     *
     * @example <caption>Example of Modal widget</caption>
     * <div data-widget="connectionMonitor">
     *     <div data-ref="container" hidden="hidden"></div>
     *
     *     <script type="template/mustache" data-ref="template">
     *         <div
     *             id="connection-dialog"
     *             role="alert"
     *             class="b-message m-error b-connection_monitor"
     *             data-ref="container"
     *         >
     *             <div class="b-connection_monitor-inner">
     *                 ... connection monitor content
     *             </div>
     *             <button
     *                 class="b-connection_monitor-btn"
     *                 aria-controls="connection-dialog"
     *                 aria-label="${Resource.msg('common.close', 'common', null)}"
     *                 title="${Resource.msg('common.close', 'common', null)}"
     *                 type="button"
     *                 data-event-click="hideAlert"
     *             >
     *                 <isinclude template="/common/icons/standalone/close" />
     *             </button>
     *         </div>
     *     </script>
     * </div>
     */
    class ConnectionMonitor extends Widget {
        isContentInserted: boolean | undefined;

        prefs() {
            return {
                connectionTimeoutValue: 3000,
                ...super.prefs()
            };
        }

        init() {
            super.init();

            this.ev<HTMLElement, Event>('offline', this.onOffline, window);
            this.ev<HTMLElement, Event>('online', this.onOnline, window);
        }

        /**
         * @description Shows Alert message in case if client goes offline
         */
        onOffline() {
            this.showAlert();
        }

        /**
         * @description Calls hiding alert after the delay time
         */
        onOnline() {
            setTimeout(() => {
                this.hideAlert();
            }, this.prefs().connectionTimeoutValue);
        }

        /**
         * @description Insert alert content or just show it up when browser if offline
         */
        showAlert() {
            if (this.isContentInserted) {
                this.ref('container').show();
            } else {
                this.insertAlertContent();
            }
        }

        /**
         * @description Hides an Alert message
         */
        hideAlert() {
            this.ref('container').hide();
        }

        /**
         * @description insert dialog content. We use raw insertion because when
         * user offline it is impossible to load chunk with mustache and diff-dom
         */
        insertAlertContent() {
            const alertContent = this.ref('template').get(0);
            const container = this.ref('container').get(0);

            if (alertContent && container) {
                container.innerHTML = alertContent.innerText;
                this.isContentInserted = true;
                container.querySelector('button')?.addEventListener('click', this.hideAlert);
            }
        }
    }

    return ConnectionMonitor;
}

export type TConnectionMonitor = ReturnType<typeof ConnectionMonitorClassCreator>;

export type TConnectionMonitorInstance = InstanceType<TConnectionMonitor>;

export default ConnectionMonitorClassCreator;
