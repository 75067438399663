/**
 * @param ContextLoader Base widget for extending
 * @returns Header widget
 */
function HeaderClassCreator(ContextLoader: import('widgets/global/ContextLoader').TContextLoader) {
    /**
     * @category widgets
     * @subcategory global
     * @class Header
     * @augments ContextLoader
     * @classdesc The main purpose is to define anchor for header and to toggle active class
     * @property {string} data-widget - Widget name `header`
     * @example <caption>Example of Header widget usage</caption>
     * <header
     *     class="l-header"
     *     data-widget="header"
     *     data-context="header"
     *     data-init-on-load="loadContext"
     *     aria-label="${Resource.msg('header.label','global',null)}"
     *     data-tau-unique="header"
     * >
     *      <div class="l-header-inner">
     *            ... account header inner content
     *      </div>
     * </header>
     */

    class Header extends ContextLoader {
        prefs() {
            return {
                classesMenuBarActive: 'm-menu_bar-active',
                ...super.prefs()
            };
        }

        /**
         * @description Initialize widget logic
         */
        init() {
            super.init();
            this.eventBus().on('menu.bar.toggle', 'toggleMenuBarStyle');
        }

        /**
         * @description Toggle class on header relevant to menu bar dropdown submenu displayed
         * @param status - boolean value, witch tell the function remove or add class
         */
        toggleMenuBarStyle(status: boolean) {
            this.ref('self').toggleClass(this.prefs().classesMenuBarActive, status);
        }
    }

    return Header;
}

export type THeader = ReturnType<typeof HeaderClassCreator>;

export type THeaderInstance = InstanceType<THeader>;

export default HeaderClassCreator;
