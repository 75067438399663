import { TFocusableElementInstance } from 'widgets/global/ListAccessibility';

import { TAccordionItemInstance } from 'widgets/global/AccordionItem';

/**
 * @description Base Accordion implementation
 * @param ListAccessibility Base widget for extending
 * @returns Accordion widget
 */
function AccordionClassCreator(ListAccessibility: import('widgets/global/ListAccessibility').TListAccessibility) {
    /**
     * @category widgets
     * @subcategory global
     * @class Accordion
     * @augments ListAccessibility
     * @classdesc Represents Accordion component with next features:
     * 1. Configurable via data attributes single or multiple selection of panels
     * 2. Allow has opened or closed first panel
     * 3. Allow or disallow closing panel on second click
     * 4. Support keyboard navigation for accessibility
     *
     * Accordion widget should contain {@link AccordionItem} widgets that implement one accordion section.
     * Widget has next relationship:
     * * Accordion widget expand first item if such defined in `data-open-first` attribute by calling method {@link AccordionItem#openPanel}
     * * Close all items using method {@link AccordionItem#closePanel} by event {@link AccordionItem#event:closeallitems} from {@link AccordionItem} widget.
     * * Update current Accordion item using methods {@link AccordionItem#handleKeydown} and {@link AccordionItem#handleFocus} by event {@link AccordionItem#event:accordionitemupdate} from {@link AccordionItem} widget.
     *
     * @property {string} data-widget - Widget name `accordion`
     * @property {string} data-event-keydown - Event listener for `handleKeydown` method
     * @property {boolean} [data-allow-toggle=false] - Flag that allows or disallows toggle
     * @property {boolean} [data-open-first=false] - Flag that open first item
     * @property {boolean} [data-allow-multiple=false] - Flag that allows or disallows multiple open items
     * @example <caption>Example of Single Selectable Accordion with one panel that is opened on init</caption>
     * <div
     *     class="b-product_accordion"
     *     data-widget="accordion"
     *     data-allow-toggle="false"
     *     data-open-first="true"
     *     data-allow-multiple="false"
     * >
     *      <section
     *          class="b-product_accordion-item"
     *          data-widget="accordionItem"
     *          data-widget-event-closeallitems="closeItems"
     *          data-widget-event-accordionitemupdate="updateFocusedItem"
     *       >
     *          <button
     *              class="b-product_accordion-button"
     *              data-ref="accordionItemBtn"
     *              data-event-click.prevent="togglePanel"
     *              data-event-keydown="handleKeydown"
     *              data-event-focus="handleFocus"
     *              type="button"
     *      >
     *              <span>${Resource.msg('details.product', 'product', null)}</span>
     *              <span class="b-icon_chevron"></span>
     *          </button>
     *          <div data-ref="accordionItemPanel">
     *              <div data-ref="accordionItemPanelInner">
     *                  ... account panel content
     *              </div>
     *          </div>
     *      </section>
     * </div>
     * @example <caption>Example of Multi Selectable Accordion with two panel. First panel closed on init</caption>
     * <div
     *     data-widget="accordion"
     *     data-allow-toggle="false"
     *     data-open-first="false"
     *     data-allow-multiple="true"
     *     class="b-footer_nav"
     * >
     *      <section
     *          data-widget="accordionItem"
     *          data-widget-event-closeallitems="closeItems"
     *          data-widget-event-accordionitemupdate="updateFocusedItem"
     *          class="b-footer_nav-column"
     *      >
     *          <h2 class="b-footer_nav-title">
     *              <button
     *                  data-ref="accordionItemBtn"
     *                  data-event-click.prevent="togglePanel"
     *                  data-event-keydown="handleKeydown"
     *                  data-event-focus="handleFocus"
     *                  class="b-footer_nav-button"
     *                  type="button"
     *              >
     *                  <span>Account</span>
     *                  <span class="b-icon_chevron"></span>
     *              </button>
     *          </h2>
     *          <div data-ref="accordionItemPanel">
     *              <div data-ref="accordionItemPanelInner">
     *                 ... account panel content
     *              </div>
     *          </div>
     *      </section>
     *      <section
     *          data-widget="accordionItem"
     *          data-widget-event-closeallitems="closeItems"
     *          data-widget-event-accordionitemupdate="updateFocusedItem"
     *          class="b-footer_nav-column"
     *      >
     *          <h2 class="b-footer_nav-title">
     *              <button
     *                  data-ref="accordionItemBtn"
     *                  data-event-click.prevent="togglePanel"
     *                  data-event-keydown="handleKeydown"
     *                  data-event-focus="handleFocus"
     *                  class="b-footer_nav-button"
     *                  type="button"
     *              >
     *                  Wishlist
     *              </button>
     *          </h2>
     *          <div data-ref="accordionItemPanel">
     *              <div data-ref="accordionItemPanelInner">
     *                  ... wishlist panel content
     *              </div>
     *          </div>
     *      </section>
     * </div>
     */

    class Accordion extends ListAccessibility {
        prefs() {
            return {
                allowToggle: false,
                allowMultiple: false,
                openFirst: false,
                ...super.prefs()
            };
        }

        /**
         * @description Initialize widget logic
         */
        init() {
            super.init();
            this.defineItems();
            this.eachChild<TAccordionItemInstance>((item) => {
                item.isToggleAllowed = this.prefs().allowToggle;
                item.isMultipleSections = this.prefs().allowMultiple;
            });

            if (this.prefs().openFirst) {
                this.openFirstItem();
            }
        }

        /**
         * @description Reinit accordion once accordion has changed
         */
        reinit() {
            this.init();
            this.updateAccordionItems();
        }

        /**
         * @description Updates accordion items state
         */
        updateAccordionItems() {
            this.eachChild<TAccordionItemInstance>((item) => {
                item.defineAttributes(item.isPanelOpen);
                item.updateState();
            });
        }

        /**
         * @description Update focused accordion item
         * @param accordionItem - Accordion item that fired event
         * @param action - action name of the update
         * @listens AccordionItem#accordionitemupdate
         */
        updateFocusedItem(accordionItem: TFocusableElementInstance, action) {
            switch (action) {
                case 'current':
                    this.currentItem = accordionItem;
                    break;

                case 'next':
                    this.setFocusToNextItem();
                    break;

                case 'previous':
                    this.setFocusToPreviousItem();
                    break;

                case 'first':
                    this.setFocusToFirstItem();
                    break;

                case 'last':
                    this.setFocusToLastItem();
                    break;

                default:
                    break;
            }
        }

        /**
         * @description Opens first item based on openFirst preference
         */
        openFirstItem() {
            if (this.firstItem) {
                (this.firstItem as TAccordionItemInstance).openPanel();
            }
        }

        /**
         * @description Close items
         * @listens AccordionItem#closeallitems
         */
        closeItems() {
            this.eachChild<TAccordionItemInstance>((accordionItem) => {
                accordionItem.closePanel(false);
            });
        }

        /**
         * @description Returns current opened accordion item
         * @returns accordion item
         */
        getCurrentOpenItem<T extends TAccordionItemInstance = TAccordionItemInstance>() {
            let currentItem: T | undefined;

            this.eachChild<TAccordionItemInstance>(item => {
                if (item.isPanelOpen) {
                    currentItem = <T> item;
                }
            });

            return currentItem;
        }
    }

    return Accordion;
}

export type TAccordion = ReturnType<typeof AccordionClassCreator>;

export type TAccordionInstance = InstanceType<TAccordion>;

export default AccordionClassCreator;
