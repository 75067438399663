import { debounce } from './../toolbox/debounce';
import { isSmallView } from './../toolbox/viewtype';
import { timeout } from 'widgets/toolbox/util';
import { TWidget } from 'widgets/Widget';

type TEvFunction = InstanceType<TWidget>['ev'];

/**
 * @param Widget Base widget for extending
 */
function BackToTopClassCreator(Widget: TWidget) {
    /**
     * @category widgets
     * @subcategory global
     * @class BackToTop
     * @augments Widget
     * @classdesc Back to top component. Allows scrolling window to top, pressing on a corresponding element.
     * The main functionality based on native anchor links to not only scroll the page but move focus to header.
     * This component only toggle button visibility depending on scroll position.
     * @property {string} data-widget - Widget name `backtotop`
     * @example <caption>Example of BackToTop widget usage</caption>
     * <div
     *     aria-label="${Resource.msg('backtotop.backtotop', 'global', '')}"
     *     data-widget="backtotop"
     *     class="b-back_to_top"
     *     href="${'#'}page-body"
     *     data-tau="back_to_top"
     * >
     *     ... back to top element
     * </div>
     */
    class BackToTop extends Widget {
        scrollDisposable?: ReturnType<TEvFunction>;

        prefs() {
            return {
                pageSize: 1.5,
                activeClassName: 'm-showed',
                ...super.prefs()
            };
        }

        /**
         * @description Initialize widget logic
         * @listens "viewtype.change"
         */
        init(): void {
            super.init();
            // Async init to not block other widget init
            timeout(() => {
                this.doInit();
                this.eventBus().on('viewtype.change', 'doInit');
            }, 0);
        }

        /**
         * @description Attach scroll listener
         */
        attachScrollListener(): void {
            this.scrollDisposable = this.ev('scroll', debounce(() => {
                this.toggleButtonVisibility();
            }, 50), window, true);
        }

        /**
         * @description Remove scroll listener
         */
        removeScrollListener(): void {
            if (this.scrollDisposable) {
                this.scrollDisposable.forEach(disposable => disposable());

                this.scrollDisposable = undefined;
            }
        }

        /**
         * @description Initialize widget logic
         */
        doInit(): void {
            if (isSmallView()) {
                this.removeScrollListener();
            } else {
                this.attachScrollListener();
                this.toggleButtonVisibility();
            }
        }

        /**
         * @description Toggle back to top button visibility
         */
        toggleButtonVisibility(): void {
            const scrolled = document.body.scrollTop || document.documentElement.scrollTop;
            const windowHeight = window.innerHeight;
            const isButtonVisible = scrolled > (windowHeight * this.prefs().pageSize);

            this.ref('self')[isButtonVisible ? 'addClass' : 'removeClass'](this.prefs().activeClassName);
        }
    }

    return BackToTop;
}

export type TBackToTop = ReturnType<typeof BackToTopClassCreator>;

export type TBackToTopInstance = InstanceType<TBackToTop>;

export default BackToTopClassCreator;
