import { RefElement } from 'widgets/toolbox/RefElement';
import { TWidget } from 'widgets/Widget';

/**
 * @param Widget Base widget for extending
 */
function LabelClassCreator(Widget: TWidget) {
    /**
     * @category widgets
     * @subcategory global
     * @class Label
     * @augments Widget
     * @classdesc Represents Label component with next features:
     * 1. Widget could be used in different context, where we need to display dynamic content depending on different global events using mustache template
     * 2. Allow being enabled/disabled
     * 3. Allow managing text/value/error messages on the referenced element
     * @example <caption>Example of Label widget usage with checkout.step.updated event listener</caption>
     * <div
     *     data-widget="label"
     *     data-listen-events="checkout.step.updated"
     *     data-widget-event-click="submitCurrentStep"
     * >
     *     <script data-ref="template" type="template/mustache">
     *         <div
     *             data-widget="label"
     *             data-listen-events="checkout.step.updated"
     *             data-widget-event-click="submitCurrentStep"
     *         >
     *             {{${'#'}shipping}}
     *                 <button data-event-click="handleClick" type="submit" name="submit" value="submit-shipping">
     *                     ${Resource.msg('button.next.payment', 'checkout', null)}
     *                 </button>
     *             {{/shipping}}
     *             {{${'#'}billing}}
     *                 <button data-event-click="handleClick" type="submit" name="submit" value="submit-payment">
     *                     ${Resource.msg('button.next.place.order', 'checkout', null)}
     *                 </button>
     *             {{/billing}}
     *             {{${'#'}summary}}
     *                 <button data-event-click="handleClick" type="submit" name="submit" value="place-order">
     *                     ${Resource.msg('button.next.place.order', 'checkout', null)}
     *                 </button>
     *             {{/summary}}
     *         </div>
     *     </script>
     * </div>
     * @property {string} data-widget - Widget name `label`
     * @property {string} data-listen-events - Events used to update template
     * Listen to global bus events, separated by comma and updates content based on event data
     * @property {string} data-widget-event-click - Triggers parent's method on widget's elements click
     */
    class Label extends Widget {
        disabled = false;

        prefs() {
            return {
                listenEvents: '',
                classesError: 'm-error',
                classesActive: 'active',
                target: 'self',
                ...super.prefs()
            };
        }

        /**
         * @description Widget logic initialization
         */
        init() {
            super.init();

            if (!this.id) {
                this.id = String(this.config.id);
            }

            this.shown = true;

            if (this.config.hidden || this.ref('self').hasAttr('hidden')) {
                this.hide();
            } else {
                this.show();
            }

            this.disabled = this.ref('self').attr('disabled') === 'disabled';

            const listenEvents = this.config.listenEvents;

            if (typeof listenEvents === 'string' && listenEvents) {
                listenEvents.split(',').forEach(eventName => {
                    this.eventBus().on(eventName.trim(), 'updateTemplate');
                });
            }
        }

        /**
         * @description Update template with passed data
         * @param data data to render, usually executes with the data, streamed down with an event
         * @emits Label#updated
         * @returns resolved if rendered or rejected if no found template promise
         */
        updateTemplate(data: Record<string, unknown> = {}): Promise<void> {
            return this.render(undefined, data)
                /**
                 * @description Event to update block after rendering
                 * @event Label#updated
                 */
                .then(() => this.emit('updated'));
        }

        /**
         * @description get Label value
         * @returns value of widget's target referenced attribute
         */
        getValue(): string | RefElement {
            return this.ref(this.prefs().target).val();
        }

        /**
         * @description set Label value
         * @param val - Value to set into widget's target referenced attribute
         */
        setValue(val: (string | number | boolean) = '') {
            this.setError();
            this.ref(this.prefs().target).val(val);
        }

        /**
         * @description get Label text
         * @returns text of widget's target referenced attribute
         */
        getText(): string | RefElement {
            return this.ref(this.prefs().target).getText();
        }

        /**
         * @description Set Label text
         * @param val - Value to set as a text of the widget's target referenced attribute
         */
        setText(val: (string | undefined) = ''): this {
            const currentText = this.getText();

            this.ref(this.prefs().target).removeClass(this.prefs().classesError);

            if (currentText !== val) {
                this.ref(this.prefs().target).setText(val);
            }

            return this;
        }

        /**
         * @description Sets `err` as a widget's target referenced attribute text
         * and if `err` is not empty - adds error class to widget's target referenced attribute
         * @param err - Value to set as a text of the widget's target referenced attribute
         */
        setError(err: (string | undefined) = ''): this {
            this.setText(err);

            if (err) {
                this.ref(this.prefs().target).addClass(this.prefs().classesError);
            }

            return this;
        }

        /**
         * @description Method to mark element as `active`
         */
        activate(): this {
            this.ref('self').addClass(this.prefs().classesActive);

            return this;
        }

        /**
         * @description Method to mark element as `inactive`
         */
        deactivate(): this {
            this.ref('self').removeClass(this.prefs().classesActive);

            return this;
        }

        /**
         * @description Disable Label
         */
        disable() {
            this.disabled = true;
            this.ref('self').disable();
        }

        /**
         * @description Enable Label
         */
        enable() {
            this.disabled = false;
            this.ref('self').enable();
        }

        /**
         * @description Emit click event
         * @emits Label#click
         * @param data - additional data for parent
         */
        handleClick(data: {[key: string]: unknown}) {
            /**
             * @description Event to handle click
             * @event Label#click
             */
            this.emit('click', data);
        }
    }

    return Label;
}

export type TLabel = ReturnType<typeof LabelClassCreator>;

export type TLabelInstance = InstanceType<TLabel>;

export default LabelClassCreator;
