/**
 * @description Base ConfirmDialog implementation
 * @param Modal Base Modal widget for extending
 * @returns Confirmation Dialog class
 */
function ConfirmDialogClassCreator(Modal: ReturnType <typeof import('widgets/global/Modal').default>) {
    /**
     * @category widgets
     * @subcategory global
     * @class ConfirmDialog
     * @augments Modal
     * @classdesc Confirm dialog, which could be used to confirm an action in different contexts, like
     * - Remove coupon from the cart
     * - Remove product from the cart
     * Can have different markup, but serves 3 actions: `confirm`, `cancel` and `alternativeconfirm`
     * Emits 3 events: {@link ConfirmDialog#event:confirm}, {@link Modal#event:cancel} and {@link ConfirmDialog#event:alternativeconfirm} to notify parents about user triggered action.
     * @property {string} data-widget - Widget name `confirmDialog`
     * @property {string} data-widget-event-confirm - method to be triggered on parent's widget, once customer confirms dialog
     * @property {string} data-widget-event-alternativeConfirm - method to be triggered on parent's widget, once customer confirms dialog
     * @property {string} data-widget-event-cancel - method to be triggered on parent's widget, once customer cancels dialog
     *
     * @example <caption>Example of Modal widget</caption>
     * <div
     *     data-widget="confirmDialog"
     *     data-id="confirmDialog"
     *     data-ref-first-focus-element="firstFocusElement"
     *     data-ref-last-focus-element="lastFocusElement"
     *     data-focus-loop="true"
     *     data-widget-event-confirm="confirmedRemoveAddress"
     * >
     *     <div
     *         class="b-dialog"
     *         data-ref="container"
     *         aria-busy="true"
     *         hidden="hidden"
     *     ></div>
     *     <script type="template/mustache" data-ref="template">
     *         <div
     *             class="b-dialog"
     *             data-ref="container"
     *             aria-busy="false"
     *         >
     *             <div
     *                 data-ref="dialog"
     *                 class="b-dialog-window"
     *                 role="dialog"
     *                 aria-modal="true"
     *                 aria-label="${Resource.msg('deleteAddressConfirmation.title','address',null)}"
     *                 aria-describedby="remove-address-confirmation-body"
     *             >
     *                 ... dialog content
     *
     *                 <div class="b-dialog-footer m-actions">
     *                     <button
     *                         type="button"
     *                         class="b-button"
     *                         data-dismiss="modal"
     *                         data-event-click.prevent="confirm"
     *                     >
     *                         ${Resource.msg('deleteAddressConfirmation.confirm','address',null)}
     *                     </button>
     *                     <button
     *                         type="button"
     *                         class="b-button m-outline"
     *                         data-event-click.prevent="cancel"
     *                         data-dismiss="modal"
     *                         data-ref="lastFocusElement"
     *                     >
     *                         ${Resource.msg('deleteAddressConfirmation.cancel','address',null)}
     *                     </button>
     *                 </div>
     *             </div>
     *         </div>
     *     </script>
     * </div>
     */
    class ConfirmDialog extends Modal {
        /**
         * @description Confirm Handler
         * @emits ConfirmDialog#confirm
         */
        confirm() {
            this.closeModal();
            /**
             * @description Event dispatched, when modal `confirm` button was pressed
             * @event ConfirmDialog#confirm
             */
            this.emit('confirm');
        }

        /**
         * @description alternativeConfirm Handler
         * @emits ConfirmDialog#alternativeconfirm
         */
        alternativeConfirm() {
            this.closeModal();
            /**
             * @description Event dispatched, when modal alternative button (not `cancel` and not `confirm`) was pressed
             * @event ConfirmDialog#alternativeconfirm
             */
            this.emit('alternativeconfirm');
        }
    }

    return ConfirmDialog;
}

export type TConfirmDialog = ReturnType<typeof ConfirmDialogClassCreator>;

export type TConfirmDialogInstance = InstanceType<TConfirmDialog>;

export default ConfirmDialogClassCreator;
