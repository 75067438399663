import { TWidget } from 'widgets/Widget';

/**
 * @description Base AlternativeImage implementation
 * @param Widget Base widget for extending
 * @returns Alternative image view widget
 */
function AlternativeImageClassCreator(Widget: TWidget) {
    /**
     * @category widgets
     * @subcategory product
     * @class AlternativeImage
     * @augments Widget
     * @classdesc Alternative image component that mixed to ProductTile widget.
     * It implement possibility to show alternative product image on tile in form of hover slider (on desktop)
     * and carousel with swapping (on mobile/tablet). It designed to be as lightweight as possible to not blow up very
     * heavy PLP page. For product carousels (e.g. "Recently Viewed" or "Recommendations" carousel), we have disabled
     * the feature with pictures swapping(on mobile/tablet) since we have double scrolling.
     * For this reasons in mind alternative image do not stored into markup it added dynamically on user
     * actions (hover, scroll). As approach for proper image size fetch (viewport, pixels density etc) we
     * use front-end url generation. As base for it we use basic image size - the most proper size that browser
     * choose from proposed sources. To get this image we use `currentSrc` HTMLImageElement property.
     * @property {string} data-widget - Widget name `productTile`
     * @property {string} data-alt-image-url - alternative image src
     * @property {string} data-alt-image-title - alternative image title
     */
    class AlternativeImage extends Widget {
        imageInserted = false;

        observer: IntersectionObserver | undefined;

        /**
         * @description Prepare alternative image viewport dependent src.
         * The trick is get already defined by browser image from set and prepare same image for alt.
         * If project has completely different images on breakpoints this should be extended with
         * update image src on viewport change.
         * @returns alternative image url
         */
        getAlternativeImageSrc(): string {
            if (!this.has('alternativeViewContainer')) {
                return '';
            }

            const altImageUrl = new URL(this.ref('alternativeViewContainer').attr('data-alt-image-url'));

            const baseImage = this.ref('tileImage').get();

            if (!(baseImage instanceof HTMLImageElement)) {
                return '';
            }

            const baseImageUrl = baseImage.currentSrc || baseImage.src;
            const baseImageUrlParsed = new URL(baseImageUrl);
            const disQueryString = baseImageUrlParsed.search;

            return altImageUrl.origin + altImageUrl.pathname + disQueryString;
        }

        /**
         * @description Prepare alternative image viewport dependent alternative text
         * @returns alternative image url
         */
        getAlternativeImageText(): string {
            const alternativeImageText = this.ref('alternativeViewContainer').attr('data-alt-image-title');

            if (typeof alternativeImageText === 'string') {
                return alternativeImageText;
            } else {
                return '';
            }
        }

        /**
         * @description Insert img tag to alternative image picture container
         */
        insertAlternativeImage() {
            if (this.imageInserted) {
                return;
            }

            const alternativeImageSrc = this.getAlternativeImageSrc();

            if (!alternativeImageSrc) {
                return;
            }

            const altImageElement = document.createElement('img');

            altImageElement.src = alternativeImageSrc;
            altImageElement.alt = this.getAlternativeImageText();
            const alternativeImagePicture = this.ref('alternativeViewPicture').get(0);

            if (!alternativeImagePicture) {
                return;
            }

            alternativeImagePicture.append(altImageElement);

            this.imageInserted = true;
        }

        /**
         * @description Executes hover logic
         */
        handleHover() {
            this.insertAlternativeImage();
        }

        /**
         * @description Executes scroll pagination logic + image insertion. We use intersection observer
         * to easily handle rtl direction.
         * Otherwise the same could be done with `track.scrollLeft > (track.offsetWidth / 2))`
         */
        handleScroll() {
            if (this.observer) {
                return;
            }

            const track = this.ref('tileImageLink').get();
            const altImage = this.ref('alternativeViewPicture').get(0);

            if (!track || !altImage) {
                return;
            }

            this.observer = new IntersectionObserver(this.markAlternativeImageActive.bind(this), {
                root: track,
                threshold: 0.5
            });

            this.observer.observe(altImage);
        }

        /**
         * @description Handles alternative images active state
         * @param entries - intersection observer entries
         */
        markAlternativeImageActive(entries: Array<IntersectionObserverEntry>) {
            if (!entries || !entries[0]) {
                return;
            }

            this.ref('alternativeViewContainer').toggleClass('m-alt_active', entries[0].isIntersecting);
            this.insertAlternativeImage();
        }
    }

    return AlternativeImage;
}

export type TAlternativeImage = ReturnType<typeof AlternativeImageClassCreator>;

export type TAlternativeImageInstance = InstanceType<TAlternativeImage>;

export default AlternativeImageClassCreator;
