/**
 * @typedef {InstanceType<typeof import('widgets/toolbox/RefElement').RefElement>} refElement
 */

/**
 * @description Base ButtonWithIcon implementation
 * @param Button Base widget for extending
 */
function ButtonWithIconClassCreator(Button: import('widgets/global/Button').TButton) {
    /**
     * @category widgets
     * @subcategory global
     * @class ButtonWithIcon
     * @augments Button
     * @classdesc ButtonWithIcon widget, which could be used together with icon
     * @property {string} data-widget - Widget name `processButton`
     * @property {string} data-widget-event-click - Event listener to call Parent's widget method
     * @property {string} data-event-click - Event listener method `handleClick` for click event on widget
     * @example
     * // use this code to display widget
     * <button type="submit" class="btn btn-block btn-primary"
     *     data-widget="buttonWithIcon"
     * >
     *     <!-- svg icon below -->
     *     <span>
     *         <isinclude template="/common/svg/wishlist" />
     *     </span>
     *
     *     <span class="container">
     *         ${Resource.msg('button.text.loginform', 'login', null)}
     *     </span>
     * </button>
     */
    class ButtonWithIcon extends Button {
        /**
         * @description Get Text
         * @returns Button Text
         */
        getText(): string {
            return this.ref('container').getText();
        }

        /**
         * @description Set Text
         * @param val - Value to set as a text of the Button
         * @returns Reference element
         */
        setText(val = '') {
            return this.ref('container').setText(val);
        }
    }

    return ButtonWithIcon;
}

export type TButtonWithIcon = ReturnType<typeof ButtonWithIconClassCreator>;

export type TButtonWithIconInstance = InstanceType<TButtonWithIcon>;

export default ButtonWithIconClassCreator;
