// CLARIFY: get rid of smoothscroll-polyfill due to big size and not critical value
import './getAttributeNames'; // needed for MS Edge

if (!('scrollBehavior' in document.documentElement.style)) { // needed for MS Edge
    setTimeout(() => {
        import(/* webpackChunkName: 'smoothscroll' */ 'smoothscroll-polyfill').then(smoothScroll => {
            smoothScroll.default.polyfill();
        });
    }, 0);
}
