import { timeout } from 'widgets/toolbox/util';
import { debounce } from 'widgets/toolbox/debounce';
import { TWidget } from 'widgets/Widget';

type TEvFunction = InstanceType<TWidget>['ev'];

const KEY_TAB = 9;

type TRefElementInstance = InstanceType<typeof import('widgets/toolbox/RefElement').RefElement>;
type TDomNode = HTMLTextAreaElement | HTMLInputElement | HTMLElement;

/**
 * @description Focus highlighter
 * @param Widget Base widget for extending
 * @returns Focus Highlighter class
 */
function FocusHighlighterClassCreator(Widget: TWidget) {
    /**
     * @category widgets
     * @subcategory global
     * @class FocusHighlighter
     * @augments Widget
     * @classdesc This class intended to address Accessibility issue with focus highlighting on links element.
     * Usually used on `<body>` tag to observe focus move events
     *
     * In case if page was focused, next subsequent `TAB` press will leads to next available link/input element focus highlighting
     * Shows bordered rectangle, which fits currently focused element and moves rectangle to element's position.
     * @property {string} data-widget - Widget name `focusHighlighter`
     * @property {string} data-event-keyup - Event handler for `handleKeyup` method
     * @property {string} data-event-click - Event handler for `handleClick` method
     * @property {string} data-classes-inited - Inited classes, added to element after initialization
     * @property {string} data-classes-hurry - Classes, which don't contains animation and used to move `highlighter` (bordered rectangle) between focused elements without animation.
     *
     * @example <caption>FocusHighlighter widget</caption>
     * <body
     *     class="l-page"
     *     id="page-body"
     *     data-widget="focusHighlighter"
     *     data-event-keyup="handleKeyup"
     *     data-event-click="handleClick"
     * >
     *     ... page content here
     * </body>
     */
    class FocusHighlighter extends Widget {
        lastFocusedElement?: HTMLElement;

        recheckTimeout?: ReturnType<typeof timeout>;

        recheckSecondTimeout?: ReturnType<typeof timeout>;

        hideTimeOut?: ReturnType<typeof timeout>;

        focusinHandlers?: ReturnType<TEvFunction>;

        lastKeyTime?: number;

        isHurryNavigation?: boolean;

        lastFocusedElementCoords = '';

        keyboardModality = false;

        isHighlighterVisible = false;

        observer?: MutationObserver;

        prefs() {
            return {
                classesHighlighter: 'b-highlighter',
                classesInited: 'b-highlighter_inited',
                classesEnabled: 'm-visible',
                classesHurry: 'm-hurry',
                DELAY_TIMEOUT: 200, // Delay for animation

                ...super.prefs()
            };
        }

        /**
         * @description Widget initialization logic
         */
        init(): void {
            this.ref('self').addClass(this.prefs().classesInited);
            this.ev('resize', debounce(this.handleResize.bind(this), 200), window);
            this.eventBus().on('rendering.applied', 'handleResize');
            this.eventBus().on('highlighter.update', 'updateHighlighter');

            // We need to set focus highlighter with a delay since we have CSS animation
            this.observer = new MutationObserver(debounce(this.handleMutations.bind(this), 500));
        }

        /**
         * @description Returns document width without scroll bar
         * @returns Document width without scroll bar
         */
        getDocumentWidth(): number {
            const clientWidth = document.documentElement.clientWidth || document.body.clientWidth;

            return clientWidth === window.innerWidth ? window.innerWidth : clientWidth;
        }

        /**
         * @param ref - key up event referenced element
         * @param event - key up event
         * @listens dom#keyup
         */
        handleKeyup(ref: TRefElementInstance, event: KeyboardEvent): void {
            if (event.keyCode === KEY_TAB) {
                this.enableHighlighter();
            }
        }

        /**
         * @param ref - click event referenced element
         * @param event - click event
         * @listens dom#click
         */
        handleClick(ref: TRefElementInstance, event: MouseEvent): void {
            if (!this.keyboardModality) {
                return;
            }

            const target = event.target;

            // When select HTMLElement popup open via keyboard click event is
            // synthetically clicked
            const isSelect = target instanceof HTMLSelectElement;

            // Space key up on button HTML element generates synthetic click.
            // We do not find cross browser solution to detect this synthetical click
            // We prevent to hide focus frame when click on buttons
            const isButton = target instanceof HTMLButtonElement
                || (<HTMLLinkElement> target)?.getAttribute('role') === 'button';

            // Space key up on input checkbox HTML element generates synthetic click.
            // We prevent to hide focus frame when click on checkboxes
            const isCheckBox = target instanceof HTMLInputElement
                && (<HTMLInputElement> target)?.getAttribute('type') === 'checkbox';

            const isRadioButton = target instanceof HTMLInputElement
                && (<HTMLInputElement> target)?.getAttribute('type') === 'radio';

            if (isSelect || isButton || isCheckBox || isRadioButton) { return; }

            if (this.isHighlighterVisible) { this.disableHighlighter(); }
        }

        /**
         * @description Window resize event handler
         * @listens dom#resize
         */
        handleResize(): void {
            if (this.isHighlighterVisible && this.lastFocusedElement) {
                this.moveTo(this.lastFocusedElement);
            }
        }

        /**
         * @description Update highlighter position. Needed when we need to update position from outside
         * @listens "highlighter.update"
         */
        updateHighlighter(): void {
            if (this.isHighlighterVisible && document.activeElement instanceof HTMLElement) {
                this.moveTo(document.activeElement);
            }
        }

        /**
         * @description Handles focus change on page
         * @listens dom#focusin
         */
        handleFocus(): void {
            if (!(document.activeElement instanceof HTMLElement)) { return; } // Needed only for TS linter

            const focusedElement = document.activeElement;

            if (
                !this.isValidTarget(focusedElement)
                || (this.isTextInput(focusedElement)
                && !this.keyboardModality)
            ) {
                return;
            }

            this.detectHurryNavigation();
            this.moveTo(focusedElement);

            // We need to recheck focused element position since coords could
            // be changed during scroll in carousels, animation, dynamic page changes

            if (this.recheckTimeout) { this.recheckTimeout(); }

            if (this.recheckSecondTimeout) { this.recheckSecondTimeout(); }

            this.recheckTimeout = timeout(() => this.moveTo(document.activeElement as HTMLElement), 400);

            this.recheckSecondTimeout = timeout(() => this.moveTo(document.activeElement as HTMLElement), 800);
        }

        /**
         * @description mutations handler for MutationObserver
         * @param mutations Array of MutationObserver records
         */
        handleMutations(mutations: Array<MutationRecord>) {
            mutations.some((mutation) => {
                if (!(mutation.target as HTMLElement).classList.contains(this.prefs().classesHighlighter)) {
                    this.updateHighlighter();

                    return true;
                }

                return false;
            });
        }

        /**
         * @description Enables a `highlighter` - a bordered box with sizes of currently focused element.
         */
        enableHighlighter(): void {
            if (this.keyboardModality) {
                return;
            }

            this.observer?.observe(document.body, {
                attributes: true,
                childList: true,
                subtree: true
            });

            this.keyboardModality = true;

            this.ref('highlighter').addClass(this.prefs().classesEnabled);

            this.isHighlighterVisible = true;
            this.onDestroy(() => this.ref('highlighter').removeClass(this.prefs().classesEnabled));

            this.handleFocus();

            this.focusinHandlers = this.ev('focusin', this.handleFocus, document);
            // all other events are handled by `focus in` event
        }

        /**
         * @description Disables a `highlighter` - a bordered box with sizes of currently focused element.
         */
        disableHighlighter(): void {
            if (!this.keyboardModality) {
                return;
            }

            this.observer?.disconnect();

            this.keyboardModality = false;

            this.ref('highlighter').removeClass(this.prefs().classesEnabled);

            this.isHighlighterVisible = false;

            this.hideTimeOut = timeout(this.hide.bind(this), this.prefs().DELAY_TIMEOUT);

            if (this.focusinHandlers) {
                this.focusinHandlers.forEach(fn => fn());
            }

            this.lastFocusedElement = undefined;
        }

        /**
         * @description Moves `highlighter` (a border box) in place of focused element
         * @param focusedElement - element, which gets focus
         */
        moveTo(focusedElement: HTMLElement) {
            if (!(focusedElement instanceof HTMLElement)) { return; }

            const highlighterNode = this.ref('highlighter').get();

            if (!highlighterNode) { return; }

            const targetRectangle = focusedElement.getBoundingClientRect();
            const targetTop = targetRectangle.top + window.scrollY;
            const targetLeft = targetRectangle.left + window.scrollX;
            const targetWidth = focusedElement.offsetWidth;
            const targetHeight = focusedElement.offsetHeight;
            const borderWidth = 3;
            const outlineWidth = 3;
            const documentWidth = this.getDocumentWidth();
            const isIntersectsViewport = (targetLeft + targetWidth + borderWidth + outlineWidth) > documentWidth;

            if (
                focusedElement === this.lastFocusedElement
                && this.lastFocusedElementCoords === '' + targetTop + targetLeft + targetWidth + targetHeight
            ) {
                // If we come from coords recheck do not reapply changes
                return;
            }

            const highlighterStyle = highlighterNode.style;

            highlighterStyle.top = `${targetTop - 5}px`;
            highlighterStyle.left = `${targetLeft - 5}px`;
            highlighterStyle.width = `${targetWidth + 4}px`;
            highlighterStyle.height = `${targetHeight + 4}px`;

            if (isIntersectsViewport) {
                highlighterStyle.left = `${targetLeft + borderWidth}px`;
                highlighterStyle.width = `${documentWidth - targetLeft - (borderWidth + outlineWidth) * 2}px`;
            }

            this.lastFocusedElementCoords = '' + targetTop + targetLeft + targetWidth + targetHeight;

            this.lastFocusedElement = focusedElement;
        }

        /**
         * @description Hide `highlighter`
         */
        hide(): this {
            const highlighterNode = this.ref('highlighter').get();

            if (!highlighterNode) {
                return this;
            }

            const highlighterStyle = highlighterNode.style;

            highlighterStyle.width = '0';
            highlighterStyle.height = '0';

            if (this.hideTimeOut) {
                this.hideTimeOut();
            }

            return this;
        }

        /**
         * @description Detects too fast customer navigation, and displays `highlighter`,
         * moving from previously focused element to currently focused without animation.
         */
        detectHurryNavigation(): void {
            const currentTime = Date.now();

            const isHurryNavigation = (currentTime - (this.lastKeyTime || 0)) < 190;

            this.ref('highlighter').toggleClass(this.prefs().classesHurry, isHurryNavigation);

            this.isHurryNavigation = isHurryNavigation;

            this.lastKeyTime = currentTime;
        }

        /**
         * @param domNode - focused element
         */
        isValidTarget(domNode: HTMLElement): boolean {
            return domNode !== this.lastFocusedElement
                && domNode.nodeName !== 'HTML'
                && domNode.nodeName !== 'BODY';
        }

        /**
         * @param domNode - focused element
         */
        isTextInput(domNode: TDomNode): boolean {
            return ((domNode instanceof HTMLTextAreaElement) && !domNode.readOnly)
                || ((domNode instanceof HTMLInputElement) && !domNode.readOnly)
                || !!(domNode.getAttribute('contenteditable'));
        }
    }

    return FocusHighlighter;
}

export type TFocusHighlighter = ReturnType<typeof FocusHighlighterClassCreator>;

export type TFocusHighlighterInstance = InstanceType<TFocusHighlighter>;

export default FocusHighlighterClassCreator;
