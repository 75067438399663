import { debounce } from 'widgets/toolbox/debounce';
import { timeout } from 'widgets/toolbox/util';
import { getViewType } from 'widgets/toolbox/viewtype';
import { stickyHeaderHeightMap } from 'config/stickyHeaderHeightMap';
import cssLoadChecker from 'widgets/toolbox/cssLoadChecker';
import { TWidget } from 'widgets/Widget';

const progressBarHeight = 50;
const DATA_PROGRESS_CONTENT = '[data-ref="progressBarContent"]';
const DATA_PROGRESS_ITEM = '[data-ref="progressBarItem"]';

/**
 * @param Widget Base widget for extending
 * @returns ProgressBar class
 */
function ProgressBarClassCreator(Widget: TWidget) {
    /**
     * @category widgets
     * @subcategory plugin_page_designer_ext
     * @class ProgressBar
     * @augments Widget
     * @classdesc Added progress bar on page
     * <div class="b-progress_bar" data-widget="progressBar" data-ref="progressBarContainer">
     *     <div class="b-progress_bar-inner">
     *         <div class="b-progress_bar-content">
     *             <span class="b-progress_bar-line" data-ref="progressBarLine"></span>
     *             <div class="b-progress_bar-btns" data-ref="progressBarActions"></div>
     *             <script type="template/mustache" data-ref="template">
     *             <div class="b-progress_bar-btns" data-ref="progressBarActions">
     *                   {{${'#'}progressBarButtons}}
     *                       <button
     *                          data-event-click="onClick"
     *                          style="left:{{xAxisPercentage}}"
     *                          data-target="{{page}}"
     *                          aria-label="{{layoutName}}"
     *                          class="b-progress_bar-dot"
     *                          id="{{layoutName}}-{{page}}">
     *                              <span class="b-progress_bar-dot_name">{{layoutName}}</span>
     *                       </button>
     *                   {{/progressBarButtons}}
     *             </div>
     *             </script>
     *         </div>
     *     </div>
     * </div>
     */

    class ProgressBar extends Widget {
        progressBarContainer!: HTMLElement | undefined;

        progressBar!: HTMLElement | undefined;

        progressBarActions!: HTMLElement | undefined;

        targetElement!: HTMLElement | null;

        progressBarItems!: undefined | NodeListOf<HTMLElement>;

        scrollDisposable!: Array<() => void>;

        targetElementHeight = 0;

        spacer = 0;

        targetElementOffset = 0;

        progressBarButtons!: Promise<HTMLElement | undefined>;

        prefs() {
            return {
                activeClass: 'm-active',
                initialized: 'm-initialized',
                textLeft: 'm-text_left',
                lastActive: 'm-last_active',
                ...super.prefs()
            };
        }

        /**
         * @description Widget initialization
         */
        init() {
            super.init();
            /* Async loading to not block other widget init */
            timeout(() => {
                cssLoadChecker.get().then(() => this.initProgressBar());
            }, 200);
        }

        /**
         * @description Initial ProgressBar configuration
         * @listens "viewtype.change"
         */
        initProgressBar() {
            this.progressBarContainer = this.ref('progressBarContainer').get();
            this.progressBar = this.ref('progressBarLine').get();
            this.progressBarActions = this.ref('progressBarActions').get();
            this.targetElement = document.querySelector(DATA_PROGRESS_CONTENT);
            this.progressBarItems = this.targetElement?.querySelectorAll(DATA_PROGRESS_ITEM);

            this.setVars();
            this.getHeaderHeight();
            this.generateButtons();
            this.attachScrollListener();
            this.setProgressWidth();
            this.eventBus().on('viewtype.change', 'resize');
        }

        /**
         * @description Added scroll listener
         */
        attachScrollListener() {
            this.scrollDisposable = this.ev('scroll', debounce(() => {
                this.setProgressWidth();
            }, 0), window, true);
        }

        /**
         * @description Recalculate data when page resize
         */
        resize() {
            this.setVars();
            this.getHeaderHeight();
            this.generateButtons();
            this.setProgressWidth();
        }

        /**
         * @description Calculate data for progress bar
         */
        setVars() {
            const headerHeight = stickyHeaderHeightMap[getViewType()];

            this.targetElementHeight = this.targetElement?.offsetHeight || 0;
            this.spacer = headerHeight + progressBarHeight;
            this.targetElementOffset = this.getCorrectedOffsetTop(this.targetElement);
        }

        /**
         * @description Get header height
         */
        getHeaderHeight () {
            if (this.progressBarContainer) {
                this.progressBarContainer.style.top = stickyHeaderHeightMap[getViewType()] + 'px';
            }
        }

        /**
         * @description Calculate progress indicator percentage
         */
        setProgressWidth() {
            const currentWidth = this.getProgressWidth();

            if (this.progressBar) {
                this.progressBar.style.width = currentWidth + '%';
            }

            this.setActive(currentWidth);
        }

        /**
         * @description Get data to scroll to the each of element
         * @returns Scroll to element
         */
        getCorrectedOffsetTop(element) {
            const offsetTop = element.getBoundingClientRect().top + window.pageYOffset;

            return offsetTop - this.spacer;
        }

        getRoundNum(num) {
            return Math.round(num * 100) / 100;
        }

        /**
         * @description Get scroll position
         * @returns Scroll position
         */
        getScrollPosition() {
            let windowScrollTop = document.body.scrollTop || document.documentElement.scrollTop;

            windowScrollTop -= this.targetElementOffset;

            return windowScrollTop <= 0 ? 0 : windowScrollTop;
        }

        /**
         * @description Check progress indicator percentage
         * @returns width
         */
        getProgressWidth() {
            const width = this.getRoundNum((this.getScrollPosition() / this.targetElementHeight) * 100);

            return (width >= 100 ? 100 : width);
        }

        /**
         * @description Generate buttons on progressBarLine
         */
        generateButtons() {
            this.has('progressBarActions', progressRefEl => {
                const progressBarActions = progressRefEl.get();

                if (progressBarActions) {
                    progressBarActions.innerHTML = '';
                    this.createProgressBarButtons();
                }
            });
        }

        /**
         * @description Create buttons on progressBarLine
         */
        createProgressBarButtons() {
            if (!this.progressBarItems) { return; }

            const progressBarButtons = [...this.progressBarItems].map((item: HTMLElement, i) => {
                const layoutName = item.dataset.progressBarItem;
                const yOffset = item.getBoundingClientRect().top - (this.targetElement?.getBoundingClientRect().top || 0);
                const xAxis = this.getRoundNum((yOffset / this.targetElementHeight) * 100);
                const xAxisPercentage = xAxis + '%';
                let extraClass = '';

                if (xAxis < 6) {
                    extraClass = this.prefs().textLeft;
                }

                if (this.getProgressWidth() >= xAxis) {
                    extraClass += ' ' + this.prefs().activeClass;
                }

                return {
                    page: i,
                    layoutName,
                    xAxisPercentage,
                    extraClass
                };
            });

            this.progressBarButtons = new Promise((resolve) => {
                this.render(undefined, { progressBarButtons }, this.ref('progressBarActions')).then(() => {
                    resolve(this.progressBarActions);
                    this.ref('self').addClass(this.prefs().initialized);
                });
            });
        }

        /**
         * @description Get active buttons on progress bar
         */
        setActive(currentWidth) {
            if (!this.progressBarActions) {
                return;
            }

            const progressBarButtons = this.progressBarActions.children as HTMLCollectionOf<HTMLElement>;
            let lastActiveIndex = 0;

            for (let i = 0; i < progressBarButtons.length; i++) {
                progressBarButtons[i].classList.remove(this.prefs().lastActive);

                if (Math.ceil(currentWidth) >= parseFloat(progressBarButtons[i].style.left)) {
                    progressBarButtons[i].classList.add(this.prefs().activeClass);
                    lastActiveIndex = i;
                } else {
                    progressBarButtons[i].classList.remove(this.prefs().activeClass);
                }
            }

            if (progressBarButtons.length > 0) {
                progressBarButtons[lastActiveIndex].classList.add(this.prefs().lastActive);
            }
        }

        /**
         * @description Scroll to select element after click on progress bar button
         */
        onClick(ref) {
            const targetIndex = ref.get().dataset.target;
            const targetElement = this.progressBarItems?.[targetIndex];
            const yAxis = this.getCorrectedOffsetTop(targetElement);

            window.scrollTo({
                top: yAxis,
                behavior: 'smooth'
            });
        }
    }

    return ProgressBar;
}

export type TProgressBar = ReturnType<typeof ProgressBarClassCreator>;

export type TProgressBarInstance = InstanceType<TProgressBar>;

export default ProgressBarClassCreator;
