import { TWidget } from 'widgets/Widget';

/**
 * @param Widget Base widget for extending
 * @returns SoftKeyboardToggleMixin class
 */
function SoftKeyboardToggleMixinClassCreator(Widget: TWidget) {
    /**
     * @category widgets
     * @subcategory global
     * @class SoftKeyboardToggleMixin
     * @augments Widget
     * @classdesc Represents SoftKeyboardToggleMixin component with next features:
     * 1. Hides software keyboard on touches outside the keyboard to avoid iOS issues.
     * 2. This class is not intended to have a separate DOM representation.
     * @property {string} data-widget - Widget name "searchBoxToggle"
     * @property {string} data-input - Target input
     */
    class SoftKeyboardToggleMixin extends Widget {
        touchStartDisposables?: Array<TArrowVoidFunction>;

        prefs() {
            return {
                input: '',
                ...super.prefs()
            };
        }

        /**
         * @description Initialize widget logic
         */
        init() {
            super.init();

            const input = this.ref('input').get();

            if (input) {
                this.ev('focus', this.hideKeyboardOnOutsideTouch, input);
                this.ev('blur', this.hideKeyboardReset, input);
            }
        }

        /**
         * @description Hide keyboard
         * @param el - dom element,
         * @param event - dom event
         */
        hideKeyboard(el: HTMLElement, event: Event) {
            const input = this.ref('input').get();

            if (input && (event.target !== input)) {
                input.blur();
            }
        }

        /**
         * @description Hide keyboard on outside touch
         */
        hideKeyboardOnOutsideTouch() {
            this.touchStartDisposables = this.ev('touchstart', this.hideKeyboard, window);
        }

        /**
         * @description Remove event listeners
         */
        hideKeyboardReset() {
            const disposables = this.touchStartDisposables;

            if (typeof disposables !== 'undefined') {
                disposables.forEach(fn => fn());
            }
        }
    }

    return SoftKeyboardToggleMixin;
}

export type TSoftKeyboardToggleMixin = ReturnType<typeof SoftKeyboardToggleMixinClassCreator>;

export type TSoftKeyboardToggleMixinInstance = InstanceType<TSoftKeyboardToggleMixin>;

export default SoftKeyboardToggleMixinClassCreator;
