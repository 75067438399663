function CarouselExtClassCreator(Carousel: ReturnType<typeof import('widgets/global/Carousel').default >) {
    /**
     * @category widgets
     * @subcategory plugin_page_designer_ext
     * @class CarouselExt
     * @augments Widget
     * @classdesc Represents Carousel component with next features:
     * 1. Allow to set carousel direction based on the view type
     * 2. Allow to use pagination for carousel rendered by mustache template
     * 3. Allow to scroll carousel to the next/previous/custom( index can be passed to the method) page index
     * 4. Allow to scroll by on the page click(can be used for carousel with thumbnails)
     * 5. Allow to scroll to custom element's position
     * 6. Allow to scroll to focused element
     * 7. Support mousemove, touchmove, mouseup, mousedown, keydown event so we can use carousel even on touch devices.
     * Also we can control carouse with keyboard
     * 8. Allow to get carousel images
     *
     * <br>Uses as a basis slider from here (ScrollCarousel.js):
     * <br>https://github.com/dimanech/aria-components/tree/master/cartridge1/js/components/carousels/carousel
     * @property {string} data-widget - Widget name `carousel`
     * @property {string} data-elem-prev-button - Previous button element
     * @property {string} data-elem-next-button - Next button element
     * @property {string} data-elem-carousel-track - Carousel inner element
     * @property {string} data-direction - Carousel direction - an object, contains direction per viewport
     * @example <caption>Example of Carousel widget usage</caption>
     * <div
     *     data-widget="carouselExt"
     *     data-elem-prev-button="elemPrevButton"
     *     data-elem-next-button="elemNextButton"
     *     data-elem-carousel-track="elemCarouselTrack"
     *     data-direction='{
     *         "small": "horizontal",
     *         "medium": "horizontal",
     *         "large": "vertical",
     *         "extraLarge": "vertical"
     *     }'
     * >
     *     <button
     *         class="carousel__ctrl _prev"
     *         data-elem-prev-button
     *         tabindex="-1"
     *         aria-hidden="true"
     *         data-ref="elemPrevButton"
     *         data-event-click="scrollToPrevPageExt"
     *     >Prev</button>
     *     <div
     *         class="carousel__track"
     *         data-elem-carousel-track
     *         data-ref="elemCarouselTrack"
     *         data-event-scroll="onScroll"
     *         data-event-touchstart="onScroll"
     *         data-event-mousedown.prevent="onMouseDown"
     *         data-event-mouseup="onMouseUp"
     *     >
     *         <isloop items="${slotcontent.content}" var="contentAsset">
     *             <div class="box _single" tabindex="0">
     *                 <isprint value="${contentAsset.custom.body}" encoding="off" />
     *             </div>
     *         </isloop>
     *     </div>
     *     <button
     *         class="carousel__ctrl _next"
     *         data-elem-next-button
     *         tabindex="-1"
     *         aria-hidden="true"
     *         data-ref="elemNextButton"
     *         data-event-click="scrollToNextPageExt"
     *     >Next</button>
     *     <div class="pagination" data-ref="pagination"></div>
     *     <script type="template/mustache" data-ref="template">
     *         <div class="pagination" data-ref="pagination">
     *             {{${'#'}pagination}}
     *             <button
     *                 class="page"
     *                 data-page="{{page}}"
     *                 tabindex="-1"
     *                 data-event-click.prevent="handlePaginationClick"
     *             >
     *             </button>
     *             {{/pagination}}
     *         </div>
     *     </script>
     * </div>
     */
    class CarouselExt extends Carousel {
        elemCarouselTrack!: HTMLElement;

        currentPageIndex = 0;

        slidesQty = 0;

        slideStart: Array<number> = [];

        slides!: HTMLCollectionOf<HTMLElement>;

        initCarousel() {
            this.setCarouselDimensions();
            this.scrollToPage(0);
            super.initCarousel();
        }

        /**
         * @description Set carousel details: current slide index, slides qty
        */
        setCarouselDimensions() {
            this.elemCarouselTrack = super.ref('elemCarouselTrack').get() as HTMLElement;
            this.currentPageIndex = 0;
            this.slidesQty = this.elemCarouselTrack.childElementCount;
            this.slideStart = [];
            this.slides = this.elemCarouselTrack.children as HTMLCollectionOf<HTMLElement>;

            if (this.elemCarouselTrack) {
                for (let i = 0; i < this.slides.length; i++) {
                    this.slideStart[i] = 0;

                    for (let j = 0; j < i; j++) {
                        this.slideStart[i] += this.slides[j].clientWidth;
                    }
                }
            }
        }

        /**
         * @description Executed when widget is re-rendered
         */
        onRefresh() {
            this.setCarouselDimensions();
            super.onRefresh();
        }

        /**
         * @description Scroll carousel to the page width index
         * @param index of the page to scroll to
         */
        scrollToPage(index) {
            this.elemCarouselTrack.scrollTo({ top: 0, left: this.slideStart[index], behavior: 'smooth' });

            return this;
        }

        /**
         * @description Scroll carousel to the next page
         * @listens dom#click
         */
        scrollOneForward(_el, event) {
            // We need this to have click on Page Designer.
            // Use mousedown,touchstart since click overloaded.
            // Check that it is not triggered by mouse buttons.
            // Restricted btn: mouse right and center. True - if restricted btn was clicked
            if (event.button) {
                return;
            }

            this.currentPageIndex += (this.currentPageIndex < this.slidesQty ? 1 : 0);
            this.scrollToPage(this.currentPageIndex);
            this.isNavButtonClicked = true;
        }

        /**
         * @description Scroll carousel to the previous page
         * @listens dom#click
         */
        scrollOneBack(_el, event) {
            // We need this to have click on Page Designer.
            // Use mousedown,touchstart since click overloaded.
            // Check that it is not triggered by mouse buttons.
            // Restricted btn: mouse right and center. True - if restricted btn was clicked
            if (event.button) {
                return;
            }

            this.currentPageIndex -= (this.currentPageIndex > 0 ? 1 : 0);
            this.scrollToPage(this.currentPageIndex);
            this.isNavButtonClicked = true;
        }

        updateCarouselMetric() {
            const carouselElem = this.ref('elemCarouselBody').get();
            const roundingDelta = this.roundingDelta || 0;

            if (this.elemCarouselTrack && carouselElem) {
                const totalScrollWidth = this.elemCarouselTrack.scrollLeft + carouselElem.offsetWidth;

                this.isScrollStart = this.elemCarouselTrack.scrollLeft <= 0;
                this.isScrollEnd = (totalScrollWidth + roundingDelta) >= this.elemCarouselTrack.scrollWidth;
            }
        }

        onScrollEnd() {
            const totalScrollWidth = this.elemCarouselTrack.scrollLeft + this.elemCarouselTrack.offsetLeft;
            const allSlidesAttr = Array.from(this.slides).filter(item => item.offsetLeft > 0).map(function(item, index) {
                return {
                    leftPosition: item.offsetLeft,
                    'slide-index': index
                };
            });

            if (!this.isNavButtonClicked) {
                for (let i = 0; i < allSlidesAttr.length; i++) {
                    if (totalScrollWidth <= allSlidesAttr[i].leftPosition) {
                        this.currentPageIndex = allSlidesAttr[i]['slide-index'];
                        break;
                    }
                }
            }

            super.onScrollEnd();
        }

        /**
         * @description Scroll carousel to the next page
         * @param _el Source of Keydown event
         * @param event event instance if DOM event
         * @listens dom#click
         */
        scrollToNextPageExt(_el, event) {
            // We need this to have click on Page Designer.
            // Use mousedown,touchstart since click overloaded.
            // Check that it is not triggered by mouse buttons.
            // Restricted btn: mouse right and center. True - if restricted btn was clicked
            if (event.button) {
                return;
            }

            super.scrollToNextPage();
        }

        /**
         * @description Scroll carousel to the previous page
         * @param _el Source of Keydown event
         * @param event event instance if DOM event
         * @listens dom#click
         */
        scrollToPrevPageExt(_el, event) {
            // We need this to have click on Page Designer.
            // Use mousedown,touchstart since click overloaded.
            // Check that it is not triggered by mouse buttons.
            // Restricted btn: mouse right and center. True - if restricted btn was clicked
            if (event.button) {
                return;
            }

            super.scrollToPrevPage();
        }
    }

    return CarouselExt;
}

export type TCarouselExt = ReturnType<typeof CarouselExtClassCreator>;

export type TCarouselExtInstance = InstanceType<TCarouselExt>;

export default CarouselExtClassCreator;
