/**
 * @module util
 * @category widgets
 * @subcategory toolbox
 * @description Represents util component with next features:
 * 1. Allow formatting message
 * 2. Allow setting timeout/interval for provided function
 * 3. Allow adding/remove/get parameter from URL
 * 4. Allow showing error layout
 * 5. Allow checking if `event` is triggered outside of `el`
 * 6. Allow creating a function to unsubscribe listener
 * 7. Allow creating a listener for click outside of `el` to execute a callback
 * 8. Allow comparing object instances
 * 9. Allow generating an integer Array containing an arithmetic progression
 * 10. Allow converting a value from data attribute into js type value
 * 11. Allow creating a function that memoizes the result of `func`
 * 12. Allow adding script tag on a page
 * 13. Allow getting value in tree object by path
 * 14. Allow checking if DOM element is focusable
 *
 * @example <caption>Example of util module usage</caption>
 * import { range, timeout } from 'widgets/toolbox/util';
 * itemObject.quantityOptionsList = range(
 *      item.quantityOptions.minOrderQuantity,
 *      item.quantityOptions.maxOrderQuantity + 1
 * ).map(qty => ({
 *      index: qty,
 *      selected: qty === item.quantity ? 'selected' : ''
 * }));
 */

import { RefElement } from './RefElement';

/**
 * @description Window console
 */
export const log = window.console;

// IE11 fix
if (!log.table) {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    log.table = () => {};
}

/**
 * @description Format message
 * @param  message message with placeholders i.e. {0}
 * @param params values for placeholders
 * @returns formatted message
 */
export function format(message: string, ...params: Array<string>): string {
    return params.reduce((msg, param, idx) => {
        const reg = new RegExp('\\{' + idx + '\\}', 'gm');

        return msg.replace(reg, param);
    }, message);
}

/**
 * @description Timeout for provided function
 * @param fn function to be called after specified time
 * @param time time before call callback
 * @returns Timeout disposable
 */
export function timeout(fn?: TAbstractFunction, time = 0) {
    let timer: ReturnType<typeof setTimeout> | undefined;

    timer = setTimeout(() => {
        if (fn) {
            fn();
        }

        timer = undefined;
        fn = undefined;
    }, time);

    return () => {
        if (timer) {
            clearTimeout(timer);

            timer = undefined;
            fn = undefined;
        }
    };
}

/**
 * @param delay time in milliseconds
 * @returns Promise that will be resolved after delay
 */
export const sleep = (delay: number) => new Promise((res) => { setTimeout(res, delay); });

export type TTimeout = ReturnType<typeof timeout>;

/**
 * @description Interval for provided function
 * @param fn function to be called regularly, after specified time delay
 * @param time time regularity for callback execution
 * @returns Interval disposable
 */
export function interval(fn?: () => void, time = 0) {
    let intervalID: ReturnType<typeof setInterval> | undefined;

    intervalID = setInterval(() => {
        if (fn) {
            fn();
        }
    }, time);

    return () => {
        if (intervalID) {
            clearInterval(intervalID);

            intervalID = undefined;
            fn = undefined;
        }
    };
}

/**
 * @description Append parameter to provided URL
 * @param initialUrl initial url
 * @param name name of params
 * @param value value of param
 * @returns url with appended param
 */
export function appendParamToURL(initialUrl: string, name: string, value: string): string {
    const url = new URL(initialUrl, document.location.origin);

    // quit if the param already exists
    if (url.searchParams.get(name)) {
        return url.toString();
    }

    url.searchParams.append(name, value);

    return url.toString();
}

/**
 * @description Remove provided parameter from URL
 * @param initialUrl Source Url
 * @param name Parameter to remove
 * @returns Url without parameter
 */
export function removeParamFromURL(initialUrl: string, name: string): string {
    const url = new URL(initialUrl, document.location.origin);

    url.searchParams.delete(name);

    return url.toString();
}

/**
 * @description Add parameters to provided URL
 * @param url initial url
 * @param params  params as key value-object
 * @returns Url with appended parameters
 */
export function appendParamsToUrl(url: string, params: { [key: string]: string|number|boolean|undefined }): string {
    return Object.entries(params).reduce((accumulator, [name, value]) => {
        return appendParamToURL(accumulator, name, String(value));
    }, url);
}

/**
 * @description Get parameters from URL
 * @param [url] Source Url
 * @returns Hash map of Url parameters
 */
export function getUrlParams(url?: string): {[x: string]: string} {
    // get query string from url (optional) or window
    let queryString = url ? url.split('?')[1] : window.location.search.slice(1);

    // we'll store the parameters here
    /**
     * @type {{[x: string]: string}}
     */
    const obj = {};

    // if query string doesn't exist
    if (!queryString) {
        return obj;
    }

    // stuff after # is not part of query string, so get rid of it
    queryString = queryString.split('#')[0];

    const queryParams = new URLSearchParams(queryString);

    // transform instance of URLSearchParams to a Hash map
    queryParams.forEach((value, key) => {
        obj[key] = value;
    });

    return obj;
}

/**
 * @description Array of errors
 * @type {string[]}
 */
let errors: Array<string> = [];

/**
 * @description Show error layout
 * @param message to show
 */
export function showErrorLayout(message: string | Error) {
    const errorLayout = document.querySelector('#errorLayout');

    if (!errorLayout) {
        return;
    }

    if (message instanceof Error) {
        if (message.stack) {
            errors.unshift(message.stack);
        }

        errors.unshift(message.message);
    } else {
        errors.unshift(message);
    }

    log.error(message);
    errorLayout.addEventListener('click', () => {
        errorLayout.innerHTML = ''; errors = [];
    }, { once: true });

    errorLayout.innerHTML = `<div class="danger" style="
        bottom: 0;
        right: 0;
        position: fixed;
        background-color: #ff0000c7;
        border: black;
        padding: 5px;
        z-index: 9999999;
        border-radius: 10px;
    ">
            Error: <br/>
            ${errors.join('<hr/>')}
        </div>`;
}

/**
 * @description Check if event `event` is triggered outside of element `el`
 * @param event DOM event
 * @param el element to track click on
 * @returns `true` if triggered outside of element
 */
export function isEventTriggeredOutsideElement(event: Event, el: HTMLElement): boolean {
    if (event.target && event.target instanceof Element) {
        let currElement = event.target;

        while (currElement.parentElement) {
            if (currElement === el) {
                return false;
            }

            currElement = currElement.parentElement;
        }

        return true;
    }

    return false;
}

/**
 * @description Create a function to unsubscribe listener
 * @param listener Listener to unsubscribe
 * @param eventName Event to unsubscribe
 * @returns Unsubscribed listener or undefined
 */
function makeExposableListener(listener: EventListener | undefined, eventName: string): EventListener | undefined {
    if (listener) {
        document.removeEventListener(eventName, listener);
        listener = undefined;
    }

    return listener;
}

/**
 * @description Create listener for click outside of element `el` to execute callback `cb`
 * @param el Element to track click on
 * @param cb Callback
 * @param preventDefault Optional to prevent the default event
 * @returns Disposable function for listener (for unsubscription)
 */
export function clickOutside(el: RefElement, cb: (event) => void|boolean, preventDefault = true): () => void {
    // need for support desktop emulation
    const eventName = 'click';
    const domEl = el.get();
    /**
     * @type {EventListener|undefined}
     */
    let listener;

    function expose() {
        listener = makeExposableListener(listener, eventName);
    }

    if (domEl) {
        listener = event => {
            if (isEventTriggeredOutsideElement(event, domEl)) {
                if (cb(event) === false) {
                    expose();
                }

                if (preventDefault === true) {
                    event.preventDefault();
                }
            }
        };

        setTimeout(() => {
            if (listener) {
                document.addEventListener(eventName, listener);
            }
        }, 0);

        return expose;
    }

    throw new Error('Missing required el');
}

/**
 * @description Compare object instances
 * @param x Source object
 * @param y Object for compare
 * @returns `true` if objects are equal
 */
// eslint-disable-next-line complexity
export function objectEquals(x, y): boolean {
    if (x === null || x === undefined || y === null || y === undefined) {
        return x === y;
    }

    // after this just checking type of one would be enough
    if (x.constructor !== y.constructor) {
        return false;
    }

    // if they are functions or regexps, they should exactly refer to same one (because of closures)
    if ((x instanceof Function) || (x instanceof RegExp)) {
        return x === y;
    }

    if (x === y || x.valueOf() === y.valueOf()) {
        return true;
    }

    if (Array.isArray(x) && x.length !== y.length) {
        return false;
    }

    // if they are dates, they must had equal valueOf
    if (x instanceof Date) {
        return false;
    }

    // if they are strictly equal, they both need to be object at least
    if (!(x instanceof Object) || !(y instanceof Object)) {
        return false;
    }

    // recursive object equality check
    const p = Object.keys(x);

    return Object.keys(y).every((i) => p.indexOf(i) !== -1)
        && p.every((i) => objectEquals(x[i], y[i]));
}

/**
 * @description Generate an integer Array containing an arithmetic progression
 * @param start start from
 * @param stop end on
 * @param step step
 * @returns Array with an arithmetic progression
 */
export function range(start: number, stop: number | null = null, step?: number): Array<number> {
    if (stop === null) {
        stop = start || 0;
        start = 0;
    }

    if (!step) {
        step = stop < start ? -1 : 1;
    }

    const length = Math.max(Math.ceil((stop - start) / step), 0);
    const newRange = Array(length);

    for (let idx = 0; idx < length; idx += 1, start += step) {
        newRange[idx] = start;
    }

    return newRange;
}

const regexpBrace = /^(?:\{[\w\W]*\}|\[[\w\W]*\])$/;

/**
 * @description Converts value from data attribute into js type value
 * @param data value to convert
 * @returns converted value
 */
export function getData(data: string | null) {
    if (data === null) {
        return false;
    }

    if (data === 'true') {
        return true;
    }

    if (data === 'false') {
        return false;
    }

    if (data === 'null') {
        return null;
    }

    // Determine number
    if (!Number.isNaN(+data) && !Number.isNaN(parseFloat(data))) {
        return +data;
    }

    if (regexpBrace.test(data)) {
        return JSON.parse(data);
    }

    return data;
}

interface IMemorizeHasher<FunctionForHashing extends TAbstractFunction> {
    (...args: Parameters<FunctionForHashing>): string;
}

/**
 * @description Creates a function that memoize the result of `func`.
 * If resolver `hasher` is provided, it determines the cache key for storing
 * the result based on the arguments provided to the memoized function.
 * By default, the first argument provided to the memoized function is used as the map cache key.
 * The func is invoked with the this binding of the memoized function.
 * @param func The function to have its output memoized.
 * @param [hasher] The function to resolve the cache key.
 * @returns Returns the new memoized function.
 */
export function memoize<Func extends TAbstractFunction>(
    func: Func,
    hasher?: IMemorizeHasher<Func>
) {
    type TMemorizeCache = Record<string, ReturnType<Func>>;
    type TMemoizedFunction = Func & { cache: TMemorizeCache };

    /**
     * @description Memoize function wrapper
     * @param this context
     * @param key Memoize key
     * @returns Memoize cache value
     */
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    function memoizeInner(this: any, key: string) {
        const cache: TMemorizeCache = memoizeInner.cache;

        // eslint-disable-next-line prefer-rest-params
        const address = '' + (hasher ? hasher.apply(this, <Parameters<Func>> arguments) : key);

        if (typeof cache[address] === 'undefined') {
            // eslint-disable-next-line prefer-rest-params
            cache[address] = func.apply(this, <Parameters<Func>> arguments);
        }

        return cache[address];
    }

    memoizeInner.cache = {};

    return <TMemoizedFunction> memoizeInner;
}

/**
 * @description Add script tag on page
 * @param source Url of script
 * @param options Script loading options
 * @param options.globalScriptName Script name in the global scope
 * @param options.integrity Script integrity
 * @param options.scriptAttributes Script attributes
 * @returns Promise when script loading is done or rejected
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const loadScriptHandler = <ScriptType = any> (
    source: string,
    options?: {
        globalScriptName?: string;
        integrity?: string;
        scriptAttributes?: Record<string, string>;
    }
) => {
    return <Promise<ScriptType|null>> new Promise((resolve, reject) => {
        let script: HTMLScriptElement | undefined = document.createElement('script');
        const prior = document.getElementsByTagName('script')[0];

        if (!prior || !prior.parentNode) {
            throw Error('No document');
        }

        script.async = true;

        if (options?.integrity) {
            script.integrity = options?.integrity;
        }

        if (options?.scriptAttributes) {
            Object.keys(options.scriptAttributes).forEach((attrName) => {
                const attributeValue = options?.scriptAttributes?.[attrName] as string;

                script?.setAttribute(attrName, attributeValue);
            });
        }

        script.type = 'text/javascript';
        prior.parentNode.insertBefore(script, prior);

        script.onload = () => {
            if (script) {
                script.onload = null;
            }

            script = undefined;

            if (options?.globalScriptName) {
                if (window[options?.globalScriptName]) {
                    resolve(window[options?.globalScriptName]);
                } else {
                    reject();
                }
            } else {
                resolve(null);
            }
        };

        script.onabort = () => {
            reject();
        };

        script.onerror = () => {
            reject();
        };

        script.src = source;
    });
};

export const loadScript = memoize(loadScriptHandler);

/**
 * @description Get value in tree object `target` by path `path`
 * @param target Source object
 * @param path In `target` object
 * @param [defaults] Will be returned instead of result if value doesn't exist
 * @returns Value in `target` object by path `path`
 */
export function get(target, path: string, defaults?) {
    const parts = (path + '').split('.');
    let part;

    while (parts.length) {
        part = parts.shift();

        if (typeof target === 'object' && target !== null && part && part in target) {
            target = target[part];
        } else if (typeof target === 'string' && part) {
            target = target[+part];
            break;
        } else {
            target = defaults;
            break;
        }
    }

    return target;
}

/**
 * @description Checks if DOM element is focusable
 * @param element HTML element to check if it is focusable
 * @returns true if focusable, false if not
 */
export function isDOMElementFocusable(element: Element | HTMLElement): boolean {
    if (
        (element as HTMLElement).tabIndex > 0
        || ((element as HTMLElement).tabIndex === 0 && element.hasAttribute('tabIndex'))
        || element.hasAttribute('contenteditable')
    ) {
        return true;
    }

    if (element.hasAttribute('disabled') || element.hasAttribute('hidden')) {
        return false;
    }

    switch (element.nodeName) {
        case 'A':
            return !!(element as HTMLLinkElement).href && (element as HTMLLinkElement).rel !== 'ignore';
        case 'INPUT':
            return (element as HTMLInputElement).type !== 'hidden' && (element as HTMLInputElement).type !== 'file';
        case 'BUTTON':
        case 'SELECT':
        case 'TEXTAREA':
            return true;
        default:
            return false;
    }
}
